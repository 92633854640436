import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLinkAuth = _resolveComponent("RouterLinkAuth")!

  return (_openBlock(), _createBlock(_component_RouterLinkAuth, { class: "flex text-sm font-medium text-gray-500 hover:text-gray-700" }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
        class: _normalizeClass([
        'h-5 w-5 text-gray-400',
        { 'mr-3': _ctx.iconAlignment === _ctx.IconAlignment.Left },
        { 'order-last ml-3': _ctx.iconAlignment === _ctx.IconAlignment.Right },
      ]),
        "aria-hidden": "true"
      }, null, 8, ["class"])),
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }))
}