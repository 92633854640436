import { login } from "@/plugins/authentication";
import store from "@/store";
import { computed, watchEffect } from "vue";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const loading = computed(() => store.getters["authStore/loading"]);

const authenticationGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const verify = async () => {
    // If the user is authenticated, continue with the route
    const isAuthenticated = store.getters["authStore/isAuthenticated"];
    if (isAuthenticated) {
      return next();
    }
    next(false);

    // Otherwise, redirect to login screen
    await login(to.fullPath);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};

export { authenticationGuard };
