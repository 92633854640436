export const testIdAttribute = "data-testid";
export const testIds = {
  statusOk: "status-ok",
  statusError: "status-error",
  pageHeadingTitle: "page-heading-title",
  event: "event",
  eventFields: {
    name: "event-name",
  },
  events: "events",
  questionnaire: "questionnaire",
  questionnaireName: "questionnaireName",
  questions: "questions",
  question: "question",
  questionSection: "question-and-answers",
  questionName: "question-name",
  answer: "answer",
  answerName: "answer-name",
  qrCodeImage: "qr",
  downloadButton: "download",
  navigationItem: "navigation-tab",
  profileMenu: "profile-menu",
  profileMenuLogout: "profile-menu-logout",
  action: {
    view: "action-view",
    edit: "action-edit",
    create: "action-create",
    submit: "action-submit",
    confirm: "action-confirm",
    cancel: "action-cancel",
    delete: "action-delete",
    moveUp: "action-move-up",
    moveDown: "action-move-down",
    closeNotification: "close",
  },
  notification: "notification",
  addQuestion: "add-question",
  addAnswer: "add-answer",
  backToOverview: "action-back-to-overview",
  actionsMenu: "actions-menu",
  exhibitor: "exhibitor",
  exhibitors: "exhibitors",
  exhibitorName: "exhibitor-name",
  accounts: "accounts",
  importExhibitors: "importExhibitors",
  card: {
    title: "card-title",
    description: "card-description",
  },
  page: "main",
  loading: "loading",
  tab: "tab",
  formQuestion: "form-question",
  formQuestionFields: {
    name: "form-question-field-name",
    isExported: "form-question-field-exported",
  },
};
