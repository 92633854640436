
import { computed, defineComponent, PropType } from "vue";
import NavigationTabVertical from "@/components/common/navigation/NavigationTabVertical.vue";
import { TransitionRoot } from "@headlessui/vue";

export default defineComponent({
  components: {
    NavigationTabVertical,
    TransitionRoot,
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    expanded: {
      type: Boolean,
    },
    accent: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const expandedInternal = computed<boolean>({
      get: () => props.expanded,
      set: (value) => emit("update:expanded", value),
    });

    return {
      expandedInternal,
    };
  },
});
