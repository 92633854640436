
import { userCanGoTo } from "@/router/helpers/userCanGoTo";
import { defineComponent, PropType, ref, watch } from "vue";

import { RouteLocation, useRoute, useRouter } from "vue-router";
import { RouterNavigationOption } from "./RouterNavigationSelect.types";

interface NavigationOptionInternal {
  name: string;
  toRouteLocation: RouteLocation;
}

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<RouterNavigationOption[]>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const currentRoute = useRoute();

    const optionsInternal: NavigationOptionInternal[] = props.options
      .map((option) => ({
        name: option.name,
        toRouteLocation: router.resolve(option.to),
      }))
      .filter((option) => userCanGoTo(option.toRouteLocation));

    const selected = ref<string | undefined>(
      optionsInternal.filter(
        (option) => option.toRouteLocation.name === currentRoute.name
      )[0]?.name
    );

    watch(selected, (newValue: string | undefined) => {
      if (!newValue) return;

      const newOption: NavigationOptionInternal = optionsInternal.filter(
        (option) => option.name === newValue
      )[0];

      router.push(newOption.toRouteLocation);
    });

    return {
      selected,
      optionsInternal,
    };
  },
});
