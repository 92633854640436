import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-4 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.texts.actions.confirmDelete.text), 1),
    _createVNode(_component_Button, {
      "data-testid": "cancel-delete",
      type: _ctx.ButtonType.white,
      label: _ctx.texts.actions.cancel,
      class: "mr-4",
      onClick: _ctx.cancel,
      buttonElementType: _ctx.ButtonElementType.button
    }, null, 8, ["type", "label", "onClick", "buttonElementType"]),
    _createVNode(_component_Button, {
      "data-testid": "confirm-delete",
      loading: _ctx.loading,
      type: _ctx.ButtonType.danger,
      label: _ctx.texts.actions.confirmDelete.action,
      onClick: _ctx.confirm,
      buttonElementType: _ctx.ButtonElementType.button
    }, null, 8, ["loading", "type", "label", "onClick", "buttonElementType"])
  ], 64))
}