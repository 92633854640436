import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createBlock(_component_TextInput, {
    id: _ctx.id,
    name: _ctx.name,
    type: "datetime-local",
    modelValue: _ctx.modelValueInternal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValueInternal) = $event)),
    valid: _ctx.valid,
    disabled: _ctx.disabled,
    min: _ctx.minDateString,
    max: _ctx.maxDateString
  }, null, 8, ["id", "name", "modelValue", "valid", "disabled", "min", "max"]))
}