import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:flex md:items-start md:justify-between md:space-x-5" }
const _hoisted_2 = { class: "flex items-start space-x-5" }
const _hoisted_3 = { class: "flex items-center space-x-2" }
const _hoisted_4 = { class: "justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.size === _ctx.PageHeadingSize.lg ? 'mb-8' : 'mb-5'}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!!_ctx.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
              key: 0,
              class: "h-14 w-14 flex-shrink-0 text-black",
              "aria-hidden": "true"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", {
              class: _normalizeClass(`flex items-center ${
                _ctx.size === _ctx.PageHeadingSize.lg ? 'text-3xl' : 'text-2xl'
              } font-bold tracking-tight text-gray-900`),
              "data-testid": "page-heading-title"
            }, _toDisplayString(_ctx.title), 3),
            (_ctx.badgeLabel)
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 0,
                  label: _ctx.badgeLabel,
                  type: _ctx.badgeType
                }, null, 8, ["label", "type"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "actions")
      ])
    ]),
    (_ctx.$slots.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(`${
        _ctx.size === _ctx.PageHeadingSize.lg ? 'mt-4 text-base' : 'mt-2 text-sm'
      } text-gray-500`)
        }, [
          _renderSlot(_ctx.$slots, "description")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.introduction)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(`${
        _ctx.size === _ctx.PageHeadingSize.lg ? 'mt-4 text-base' : 'mt-2 text-sm'
      } text-gray-500`)
        }, [
          _renderSlot(_ctx.$slots, "introduction")
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}