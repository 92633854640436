import { reactive } from "vue";
import { texts } from "@/services/texts.service";
import { ModalType } from "@/components/common/modal/Modal.types";

const confirmationTexts = texts.common.dialogs.confirmation;

// How to reuse this type in Confirmation.vue for props, without compile errors?
interface ConfirmationOptions {
  title: string;
  description: string;
  okLabel: string;
  cancelLabel: string;
  visible: boolean;
  type: ModalType;
}

let resolver: (value: boolean) => void = () => {
  return;
};

export function useConfirmation() {
  const defaultOptions = {
    title: confirmationTexts.title,
    description: confirmationTexts.description,
    okLabel: confirmationTexts.ok,
    cancelLabel: confirmationTexts.cancel,
    type: ModalType.warning,
  };
  return reactive({
    ...defaultOptions,
    visible: false,
    /**
     * Show a confirmation modal, and wait for either confirm or cancel.
     * @returns true when the user confirmed
     */
    confirm(
      options: Partial<ConfirmationOptions> | null = null
    ): Promise<boolean> {
      Object.assign(this, defaultOptions, options);
      this.visible = true;
      return new Promise<boolean>((resolve) => (resolver = resolve));
    },
    ok() {
      resolver(true);
      this.visible = false;
    },
    cancel() {
      resolver(false);
      this.visible = false;
    },
    get props(): ConfirmationOptions {
      return {
        title: this.title,
        description: this.description,
        visible: this.visible,
        okLabel: this.okLabel,
        cancelLabel: this.cancelLabel,
        type: this.type,
      };
    },
    get on() {
      return {
        ok: () => this.ok(),
        cancel: () => this.cancel(),
      };
    },
  });
}
