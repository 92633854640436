import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-y-0 left-0 w-0.5 bg-darkgreen-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass([
      `whitespace-nowrap px-6 py-4 text-sm`,
      {
        'font-medium': _ctx.accent,
        'text-gray-500': !_ctx.accent && !_ctx.selected,
        'text-gray-900': _ctx.accent && !_ctx.selected,
        'text-darkgreen-500': _ctx.selected,
      },
    ])
  }, [
    (_ctx.stroke)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}