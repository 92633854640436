import { Component } from "vue";

interface ModalProps {
  visible: boolean;
  type?: ModalType;
  icon?: Component;
  title?: string;
  description?: string;
  centered?: boolean;
  block?: boolean;
}

enum ModalType {
  primary = "primary",
  warning = "warning",
  danger = "danger",
}

export { ModalProps, ModalType };
