import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "md:hidden" }
const _hoisted_2 = { class: "hidden md:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterNavigationSelect = _resolveComponent("RouterNavigationSelect")!
  const _component_NavigationTab = _resolveComponent("NavigationTab")!
  const _component_RouterLinkAuth = _resolveComponent("RouterLinkAuth")!
  const _component_NavigationTabContainer = _resolveComponent("NavigationTabContainer")!
  const _directive_test_id = _resolveDirective("test-id")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RouterNavigationSelect, { options: _ctx.tabs }, null, 8, ["options"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NavigationTabContainer, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_RouterLinkAuth, {
              key: tab.name,
              to: tab.to,
              custom: ""
            }, {
              default: _withCtx(({ href, navigate, isActive }) => [
                _withDirectives(_createVNode(_component_NavigationTab, {
                  name: tab.name,
                  icon: tab.icon,
                  href: href,
                  current: isActive,
                  onClick: navigate
                }, null, 8, ["name", "icon", "href", "current", "onClick"]), [
                  [_directive_test_id, _ctx.$testIds.tab]
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}