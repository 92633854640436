import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";

import About from "@/views/About.vue";
import Questionnaires from "@/views/Questionnaires.vue";
import QuestionnaireEditor from "@/views/questionnaire-editor/QuestionnaireEditor.vue";
import LeadData from "@/views/LeadData.vue";
import Exhibitors from "@/views/exhibitors/Exhibitors.vue";
import ExhibitorsImport from "@/views/exhibitors/ExhibitorsImport.vue";
import Exhibitor from "@/views/exhibitors/Exhibitor.vue";
import ExhibitorCreate from "@/views/exhibitors/ExhibitorCreate.vue";
import ExhibitorEdit from "@/views/exhibitors/ExhibitorEdit.vue";
import ExhibitorEvents from "@/views/exhibitors/ExhibitorEvents.vue";
import ExhibitorAccounts from "@/views/exhibitors/ExhibitorAccounts.vue";
import Events from "@/views/Events.vue";
import CreateTestEvent from "@/views/admin/CreateTestEvent.vue";
import LeadAppConfiguration from "@/views/LeadAppConfiguration.vue";
import NotFound from "@/views/NotFound.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import MailTemplates from "@/views/admin/MailTemplates.vue";

import { AdminRouteNames, OrganizerRouteNames, RouteNames } from "./routeNames";
import { authenticationGuard } from "@/router/guards/authenticationGuard";
import ExhibitorShell from "@/components/exhibitor-shell/ExhibitorShell.vue";
import OrganizerShell from "@/components/organizer-shell/OrganizerShell.vue";
import AdminShell from "@/views/admin/AdminShell.vue";
import { authorization, Permission } from "@/store/modules/permissions.store";
import { authorizationGuard } from "@/router/guards/authorizationGuard";
import { texts } from "@/services/texts.service";
import Organizers from "@/views/admin/Organizers.vue";
import Terms from "@/views/Terms.vue";
import Status from "@/views/Status.vue";

import OrganizerEvents from "@/views/organizer/OrganizerEvents.vue";
import OrganizerEvent from "@/views/organizer/OrganizerEvent.vue";
import OrganizerEventExhibitors from "@/views/organizer/event/OrganizerEventExhibitors.vue";
import OrganizerEventSettings from "@/views/organizer/event/OrganizerEventSettings.vue";

import ManageAccount from "@/views/admin/ManageAccount.vue";
import OrganizerEventQuestions from "@/views/organizer/event/OrganizerEventQuestions.vue";
import Settings from "@/views/admin/Settings.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: RouterView,
    redirect: function () {
      // Redirect to the right dashboard; clear Auth-0 query parameters.
      if (authorization.hasPermission(Permission.Administrate)) {
        return { name: AdminRouteNames.MANAGE_ORGANIZERS, query: undefined };
      } else if (authorization.hasPermission(Permission.Organize)) {
        return {
          name: OrganizerRouteNames.ORGANIZER_DASHBOARD,
          query: undefined,
        };
      } else if (authorization.hasPermission(Permission.Exhibit)) {
        return { name: RouteNames.EVENTS, query: undefined };
      } else {
        return { name: RouteNames.UNAUTHORIZED, query: undefined };
      }
    },
  },
  { path: "/status", component: Status, name: RouteNames.STATUS },
  {
    path: "/admin",
    component: AdminShell,
    meta: {
      permissions: [Permission.Administrate],
    },
    children: [
      {
        name: AdminRouteNames.MANAGE_ORGANIZERS,
        component: Organizers,
        path: "organizers",
      },
      {
        name: AdminRouteNames.CREATE_TEST_EVENT,
        path: "organizers/:tenantAlias/create-test-event",
        component: CreateTestEvent,
        props: (route) => ({
          tenantAlias: route.params.tenantAlias,
        }),
      },
      {
        name: AdminRouteNames.MANAGE_ACCOUNT,
        path: "account/:accountId",
        component: ManageAccount,
        props: true,
      },
      {
        name: AdminRouteNames.MAIL_TEMPLATES,
        component: MailTemplates,
        path: "mailtemplates",
      },
      {
        name: AdminRouteNames.SETTINGS,
        component: Settings,
        path: "settings",
      },
    ],
  },
  {
    path: "/terms",
    name: RouteNames.TERMS,
    component: Terms,
  },
  {
    path: "/exhibitor/events",
    name: RouteNames.EVENTS,
    components: {
      "full-screen": Events,
    },
    meta: {
      permissions: [Permission.Exhibit],
    },
    beforeEnter(to, from, next) {
      document.title = texts.exhibitor.title;
      next();
    },
  },
  {
    path: "/organizer",
    component: OrganizerShell,
    name: OrganizerRouteNames.ORGANIZER_DASHBOARD,
    meta: {
      permissions: [Permission.Organize],
    },
    redirect: { name: OrganizerRouteNames.ORGANIZER_EXHIBITORS },
    beforeEnter(to, from, next) {
      document.title = texts.organizer.title;
      next();
    },
    children: [
      {
        path: "events",
        component: RouterView,
        children: [
          {
            path: "",
            name: OrganizerRouteNames.ORGANIZER_EVENTS,
            component: OrganizerEvents,
            props: (route) => ({
              eventId: Number.parseInt(route.params.eventId as string),
            }),
          },
          {
            path: ":eventId",
            name: OrganizerRouteNames.ORGANIZER_EVENT,
            component: OrganizerEvent,
            redirect: { name: OrganizerRouteNames.ORGANIZER_EVENT_EXHIBITORS },
            props: (route) => ({
              eventId: Number.parseInt(route.params.eventId as string),
            }),
            children: [
              {
                path: "exhibitors",
                name: OrganizerRouteNames.ORGANIZER_EVENT_EXHIBITORS,
                component: OrganizerEventExhibitors,
                props: (route) => ({
                  eventId: Number.parseInt(route.params.eventId as string),
                }),
              },
              {
                path: "export",
                name: OrganizerRouteNames.ORGANIZER_EVENT_EXPORTS,
                component: OrganizerEventQuestions,
                props: (route) => ({
                  eventId: Number.parseInt(route.params.eventId as string),
                }),
              },
              {
                path: "settings",
                name: OrganizerRouteNames.ORGANIZER_EVENT_SETTINGS,
                component: OrganizerEventSettings,
                props: (route) => ({
                  eventId: Number.parseInt(route.params.eventId as string),
                }),
              },
            ],
          },
        ],
      },
      {
        path: "exhibitors",
        name: OrganizerRouteNames.ORGANIZER_EXHIBITORS,
        component: Exhibitors,
      },
      // TODO: figure out hwo to make it work as child of route above (the navbar doesnt mark the nav item right now..)
      {
        path: "exhibitors/:exhibitorId",
        name: OrganizerRouteNames.ORGANIZER_EXHIBITOR,
        component: Exhibitor,
        redirect: { name: OrganizerRouteNames.ORGANIZER_EXHIBITOR_EDIT },
        props: (route) => ({
          exhibitorId: Number.parseInt(route.params.exhibitorId as string),
        }),
        children: [
          {
            path: "edit",
            name: OrganizerRouteNames.ORGANIZER_EXHIBITOR_EDIT,
            component: ExhibitorEdit,
          },
          {
            path: "events",
            name: OrganizerRouteNames.ORGANIZER_EXHIBITOR_EVENTS,
            component: ExhibitorEvents,
          },
          {
            path: "accounts",
            name: OrganizerRouteNames.ORGANIZER_EXHIBITOR_ACCOUNTS,
            component: ExhibitorAccounts,
          },
        ],
      },
      // TODO: figure out how to make it work as child of route above (the navbar doesnt mark the nav item right now..)
      {
        path: "create",
        name: OrganizerRouteNames.ORGANIZER_EXHIBITOR_CREATE,
        component: ExhibitorCreate,
      },
      {
        path: "import",
        name: OrganizerRouteNames.ORGANIZER_EXHIBITORS_IMPORT,
        component: ExhibitorsImport,
      },
    ],
  },
  {
    path: "/exhibitor/:tenantAlias/event/:eventId",
    name: RouteNames.EVENT,
    component: ExhibitorShell,
    redirect: { name: RouteNames.EXHIBITOR_ABOUT },
    props: (route) => ({
      tenantAlias: route.params.tenantAlias,
      eventId: Number.parseInt(route.params.eventId as string),
    }),
    meta: {
      permissions: [Permission.Exhibit],
    },
    beforeEnter(to, from, next) {
      document.title = texts.exhibitor.title;
      next();
    },
    children: [
      {
        path: "about",
        name: RouteNames.EXHIBITOR_ABOUT,
        component: About,
      },
      {
        path: "leadData",
        name: RouteNames.LEAD_DATA,
        component: LeadData,
        props: (route) => ({
          tenantAlias: route.params.tenantAlias,
          eventId: Number.parseInt(route.params.eventId as string),
        }),
      },
      {
        path: "configuration",
        name: RouteNames.EXHIBITOR_LEAD_APP_CONFIGURATION,
        component: LeadAppConfiguration,
        props: (route) => ({
          tenantAlias: route.params.tenantAlias,
          eventId: Number.parseInt(route.params.eventId as string),
        }),
      },
      {
        path: "questionnaires",
        name: RouteNames.EXHIBITOR_QUESTIONNAIRES,
        component: Questionnaires,
        props: (route) => ({
          tenantAlias: route.params.tenantAlias,
          eventId: Number.parseInt(route.params.eventId as string),
        }),
      },
      {
        path: "questionnaires/:questionnaireId",
        name: RouteNames.QUESTIONNAIRE_EDITOR,
        component: QuestionnaireEditor,
        props: (route) => ({
          tenantAlias: route.params.tenantAlias,
          eventId: Number.parseInt(route.params.eventId as string),
          questionnaireId: Number.parseInt(
            route.params.questionnaireId as string
          ),
        }),
      },
      {
        path: "questionnaires/create",
        name: RouteNames.QUESTIONNAIRE_CREATE,
        component: QuestionnaireEditor,
        props: (route) => ({
          tenantAlias: route.params.tenantAlias,
          eventId: Number.parseInt(route.params.eventId as string),
        }),
      },
    ],
  },
  {
    path: "/unauthorized",
    name: RouteNames.UNAUTHORIZED,
    components: { "full-screen": Unauthorized },
  },
  {
    path: "/:pathMatch(.*)",
    name: RouteNames.NOT_FOUND,
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authenticationGuard);
router.beforeEach(authorizationGuard);
router.beforeEach((to, from, next) => {
  if (
    to.name !== RouteNames.TERMS &&
    authorization.hasPermission(Permission.Exhibit) &&
    !authorization.hasAcceptedTerms()
  ) {
    return next({ name: RouteNames.TERMS, query: undefined });
  } else {
    return next();
  }
});

export { routes };

export default router;
