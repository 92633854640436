import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RouterLinkAuth = _resolveComponent("RouterLinkAuth")!

  return (_openBlock(), _createBlock(_component_RouterLinkAuth, {
    to: _ctx.to,
    custom: ""
  }, {
    default: _withCtx(({ navigate }) => [
      _createVNode(_component_Button, _mergeProps({
        label: _ctx.label,
        type: _ctx.type,
        onClick: navigate
      }, _ctx.$attrs), null, 16, ["label", "type", "onClick"])
    ]),
    _: 1
  }, 8, ["to"]))
}