import { reactive } from "vue";
import { Permission } from "@/lib/client";
export { Permission };

export const authorization = reactive({
  permissions: [] as Permission[],
  termsAcceptedOn: null as number | null,
  hasAcceptedTerms() {
    return !!this.termsAcceptedOn;
  },
  hasPermissions(permissions: Permission[]) {
    return permissions.every((permission) =>
      authorization.permissions.includes(permission)
    );
  },
  hasPermission(permission: Permission) {
    return authorization.hasPermissions([permission]);
  },
});
