import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-261d80ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid", "href"]
const _hoisted_2 = ["data-testid", "type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.href && !_ctx.disabled)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        "data-testid": _ctx.testId,
        download: "",
        href: _ctx.href,
        class: _normalizeClass(_ctx.classes)
      }, _toDisplayString(_ctx.label), 11, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        "data-testid": _ctx.testId,
        type: _ctx.buttonElementType,
        disabled: _ctx.buttonDisabled,
        class: _normalizeClass(_ctx.classes)
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.spinnerClasses)
            }, null, 2))
          : _createCommentVNode("", true),
        (!_ctx.loading && _ctx.leadingIcon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.leadingIcon), {
              key: 1,
              class: _normalizeClass(['-ml-0.5 mr-2', ..._ctx.iconSizeClasses]),
              "aria-hidden": "true"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.label) + " ", 1),
        (_ctx.centerIcon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.centerIcon), {
              key: 2,
              class: _normalizeClass(['-ml-1 -mr-1', ..._ctx.iconSizeClasses]),
              "aria-hidden": "true"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.trailingIcon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.trailingIcon), {
              key: 3,
              class: _normalizeClass(['ml-3 -mr-1', ..._ctx.iconSizeClasses]),
              "aria-hidden": "true"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
}