<template>
  <!-- This file is generated by https://pdf.online/convert-pdf-to-html-->
  <div>
    <h1
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
        font-weight: bold;
        font-size: 18pt;
      "
    >
      General terms and conditions
    </h1>
    <p
      style="
        padding-top: 13pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      These are the General Terms and Conditions for Summit Registration &amp;
      Services B.V., established at Maliesingel 41 in (3581BK) Utrecht and
      registered with the Chamber of Commerce under number 30142110, hereinafter
      referred to as "<b>Summit</b
      ><span class="s5"
        >". Summit delivers software solutions for organisers of exhibitions,
        congresses and other events and, in this context, also rents out
        hardware for, among other things, visitor registration. Consult </span
      ><a
        href="http://www.summit.nl/"
        target="_blank"
        style="
          color: #0462c1;
          font-family: Calibri, sans-serif;
          font-style: normal;
          font-weight: normal;
          text-decoration: underline;
          font-size: 11pt;
        "
        >http://www.summit.nl/ </a
      >for more information.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-top: 2pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      Article 1. Definitions
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Capitalised terms in these General Terms and Conditions shall (in both
      singular and plural) have the meanings set out below:
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <ol id="l1">
      <ol id="l2">
        <li data-list-text="1.1.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            General Terms and Conditions<span class="p"
              >: the present terms and conditions including annexes</span
            >.
          </h2>
        </li>
        <li data-list-text="1.2.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client<span class="p"
              >: the natural or legal person with whom Summit has entered into
              the Agreement</span
            >.
          </h2>
        </li>
        <li data-list-text="1.3.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Services<span class="p">
              means all services provided by Summit to Client under the
              Agreement, which may in any case include (i) the provision of
              Software(-as-a-Service), (ii) the maintenance of such Software and
              (iii) the leasing of Hardware</span
            >.
          </h2>
        </li>
        <li data-list-text="1.4.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Hardware means
            <span class="p"
              >all (moveable) items that Summit rents to Client under the
              Agreement, which may in any case include: the hardware device
              SummitHost uses to register visitors, scan codes and print
              badges</span
            >.
          </h2>
        </li>
        <li data-list-text="1.5.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Intellectual Property Rights<span class="p"
              >: all intellectual property rights and related rights, including
              in any case copyrights, database rights, rights to domain names,
              trade name rights, rights to know-how, trademark rights, design
              rights, neighbouring rights and patent rights</span
            >.
          </h2>
        </li>
        <li data-list-text="1.6.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Office hours<span class="p"
              >: the time from 9am to 5pm from Monday to Sunday, excluding
              official Dutch holidays</span
            >.
          </h2>
        </li>
        <li data-list-text="1.7.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Materials:
            <span class="p"
              >all works, such as websites and (web) applications, house styles,
              logos, folders, brochures, leaflets, lettering, advertisements,
              marketing and/or communication plans, concepts, illustrations,
              texts, sketches, documentation, advice, reports and other products
              of the mind, as well as preparatory material thereof and files or
              data carriers (whether or not encoded) on which the materials are
              located</span
            >.
          </h2>
        </li>
        <li data-list-text="1.8.">
          <h2
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Additional work<span class="p"
              >: additional work or services beyond the scope of the
              Agreement</span
            >.
          </h2>
        </li>
        <li data-list-text="1.9.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Agreement<span class="p">
              means the offer (accepted by Client) pursuant to which Summit
              provides its Services to Client, including these General Terms and
              Conditions and any other documents and/or agreements mentioned in
              the offer, such as the Processor Agreement</span
            >.
          </h2>
        </li>
        <li data-list-text="1.10.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Party(ies)<span class="p">
              means the party(ies) to the Agreement, Summit and Client jointly
              or separately</span
            >.
          </h2>
        </li>
        <li data-list-text="1.11.">
          <h2
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Software<span class="p">
              means any software provided by Summit under the Agreement</span
            >.
          </h2>
        </li>
        <li data-list-text="1.12.">
          <h2 style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Emergency Maintenance<span class="p"
              >: unforeseen maintenance reasonably required in connection with
              the availability, usability and/or security of the Services</span
            >.
          </h2>
        </li>
        <li data-list-text="1.13.">
          <h2 style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Processor Agreement<span class="p">
              means the processor agreement in Annex 1 of the General Terms and
              Conditions which forms part</span
            >of the Agreement.
          </h2>
        </li>
        <li data-list-text="1.14.">
          <h2 style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Website:<a href="http://www.summit.nl/" target="_blank">http:</a
            >//www.summit.nl/
          </h2>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Article 2. Establishment
    </h2>
    <ol id="l3">
      <ol id="l4">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            All quotations and other offers from Summit are without obligation.
            Summit is under no obligation to accept an acceptance after the
            expiry of any stated period of validity in an offer or quotation,
            but if Summit does so, the offer or quotation shall still be deemed
            accepted.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Client's acceptance deviates (whether or not on minor points)
            from the offer, Summit shall not be bound by it. In that case, the
            Agreement shall not be concluded in accordance with this deviating
            acceptance, unless Summit explicitly indicates otherwise.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Client does not explicitly indicate its agreement with Summit's
            offer, but nevertheless agrees, or gives that impression, that
            Summit is performing work that falls within the description of the
            Services offered, then the offer is considered accepted.
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Any general (purchase) conditions of the Client are expressly
            rejected. Additional terms, agreements or provisions provided by the
            Client shall only apply if expressly agreed in writing between the
            Parties.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            class="s3"
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In case of
            <span class="s4"
              >conflict between different documents or their annexes, the
              following order of precedence shall apply</span
            >:
          </p>
          <ol id="l5">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 48pt;
                  text-indent: -14pt;
                  line-height: 13pt;
                  text-align: left;
                "
              >
                additional written and signed agreements;
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 48pt;
                  text-indent: -14pt;
                  line-height: 13pt;
                  text-align: left;
                "
              >
                approved quotation or offer;
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style="padding-left: 48pt; text-indent: -14pt; text-align: left"
              >
                the Processors' Agreement;
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style="padding-left: 48pt; text-indent: -14pt; text-align: left"
              >
                the General Terms and Conditions.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 3. Execution of the Agreement
    </h2>
    <ol id="l6">
      <ol id="l7">
        <li data-list-text="3.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            After the Agreement has been concluded, Summit shall make every
            effort to fulfil the Agreement to the best of its ability with due
            care and skill. Deadlines announced by Summit are indicative and do
            not apply as deadlines. If it is explicitly indicated that a
            deadline is fatal, Summit shall not be in default until it has been
            given notice of default and has also failed to comply within the
            reasonable period.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall provide Summit with all support necessary and desirable
            to enable proper and timely delivery of the Services. In any event,
            Client shall:
          </p>
          <ol id="l8">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                provide all information which Summit indicates is necessary, or
                which Client should reasonably understand is necessary for the
                provision of the Services;
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                provide Summit with access to all places, services and accounts
                under its control if and to the extent necessary for the
                provision of the Services.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="3.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit shall take into account reasonable requests made by Client in
            the performance of the Agreement, but shall not be obliged to comply
            with such request. Summit shall have the right to charge additional
            costs for fulfilling such a request.
          </p>
        </li>
        <li data-list-text="3.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit has the right to engage third parties in the performance of
            the Agreement. Any associated costs shall be borne by the Client
            only if agreed in advance.
          </p>
        </li>
        <li data-list-text="3.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Summit employees perform work at the offices of Client or at
            another location designated by Client in the performance of the
            Agreement, Client shall provide all support and facilities free of
            charge for the performance of the work.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Article 4. Additional work
    </h2>
    <ol id="l9">
      <ol id="l10">
        <li data-list-text="4.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Client requests additional work or Services beyond the scope of
            the Agreement, the Parties will consult about it and Summit may
            prepare a supplementary quotation. Summit will only perform the
            Additional Work after acceptance of the quotation by Client.
          </p>
        </li>
        <li data-list-text="4.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Additional work which Summit can demonstrate is reasonably necessary
            for the provision of the Services, or which reasonably follows
            Client's instructions, does not require Summit's consent. Such work
            shall be performed on an after-the-fact basis at Summit's hourly
            rate in effect at the time the work is performed.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 5. Completion and acceptance
    </h2>
    <ol id="l11">
      <ol id="l12">
        <li data-list-text="5.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Summit provides customised Services to Client, Summit will
            deliver the customised Services when, in its professional opinion,
            they meet the description in the Agreement or are suitable for use.
            Client will evaluate and approve or disapprove the deliverable
            within seven days of delivery in accordance with the delivery
            criteria specified in the description in the Agreement or further
            agreed upon. If the Principal does not reject the completion within
            this period, the completion shall be deemed to have been accepted.
          </p>
        </li>
        <li data-list-text="5.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the Services are delivered in phases, the Customer must give its
            approval or disapproval of the part of the Services of that phase
            after completion of each phase in the manner provided for in the
            previous paragraph. Customer cannot base an approval or disapproval
            in a later phase on aspects approved in an earlier phase.
          </p>
        </li>
        <li data-list-text="5.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Client disapproves the delivered result in whole or in part,
            Summit shall make every effort to remove the reason for disapproval
            as soon as possible. Summit may do this by revising the result or
            providing a reasoned explanation as to why the reason is not valid.
            Client then has fourteen days to approve or disapprove the revision
            or motivation. In case of disapproval, the agreement is terminated
            and the costs already incurred are charged by Summit.
          </p>
        </li>
        <li data-list-text="5.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Client may only reject the Services on the grounds of
            substantial deviations from the functional specification in the
            Agreement.
          </p>
        </li>
        <li data-list-text="5.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If objections concern only minor aspects, the Services shall be
            deemed accepted subject to the proviso that these objections are
            still resolved by Summit within a reasonable period of time.
          </p>
        </li>
        <li data-list-text="5.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            For errors discovered after acceptance, there is no liability for
            Summit unless Summit knew or should have known about them at the
            time of delivery.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 6. Adjustments and improvements
    </h2>
    <ol id="l13">
      <ol id="l14">
        <li data-list-text="6.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit may modify the Services based on its professional opinion,
            for example to fix errors, add new functionalities or improve
            performance. Summit may consult with Client about such adjustments
            in advance, but the final decision on whether to implement the
            adjustment is made by Summit.
          </p>
        </li>
        <li data-list-text="6.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If, in Summit's judgment, adjustments result in a material change in
            the functionality of the Services, Summit shall endeavour to
            announce the adjustments to Client in advance by e-mail.
          </p>
        </li>
        <li data-list-text="6.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit will make every effort to fix any errors in the Services, but
            is partly dependent on its suppliers in doing so. Summit has the
            right not to install certain updates or upgrades from suppliers if,
            in its judgment, this does not improve the operation of the
            Services.
          </p>
        </li>
        <li data-list-text="6.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the implementation of adjustments and improvements may lead to a
            limitation of the availability of the Services, this will be done
            outside Office Hours as far as possible
          </p>
        </li>
      </ol>
    </ol>
    <p
      style="
        padding-top: 1pt;
        padding-left: 41pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      performed. Emergency maintenance can be performed at any time and will not
      be announced in advance.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 7. Support
    </h2>
    <ol id="l15">
      <ol id="l16">
        <li data-list-text="7.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit makes every effort to support Client with respect to
            questions regarding the use and management of the Services, as well
            as technical issues related to the Services.
          </p>
        </li>
        <li data-list-text="7.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The support as described in the previous paragraph is offered via a
            helpdesk that can be reached by e-mail or telephone during Office
            Hours.
          </p>
        </li>
        <li data-list-text="7.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit aims to handle helpdesk requests within a reasonable
            timeframe. The time taken to resolve reports may vary.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Article 8. Availability and backups
    </h2>
    <ol id="l17">
      <ol id="l18">
        <li data-list-text="8.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit shall use its best efforts to provide and make available the
            Services to the best of its ability. Client can only claim specific
            service levels, such as a certain availability or response time, if
            this has been agreed in writing. For example, in a service level
            agreement.
          </p>
        </li>
        <li data-list-text="8.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            If, in Summit's judgment, the operation of Summit's or third
            parties' computer systems or network is endangered, for example in
            the event of a (d)dos attack or malware activity, Summit shall have
            the right to take all measures it reasonably deems necessary to
            avert the danger and limit or prevent damage. This may result in
            limited availability.
          </p>
        </li>
        <li data-list-text="8.3.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit will periodically make backup copies (backups) of certain
            Client data on Summit or third party systems, and make such data
            available to Client upon request for a reasonable fee. These backup
            copies may be destroyed by Summit at any time after termination of
            the Agreement. It is the responsibility of Client to request backup
            copies in a timely manner upon termination of the Agreement. Unless
            the parties make further arrangements regarding the making of
            back-up copies, Summit shall have complete discretion in
            determining, inter alia: the times when back-up copies are made, the
            frequency with which back-up copies are made, the retention period
            of the back-up copies.
          </p>
        </li>
        <li data-list-text="8.4.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Unless otherwise agreed in writing, any backup copies will be
            created through an automated process and such backup copies will be
            randomly tested to ensure that they are working properly. Only when
            the parties agree in writing that all or certain backup copies will
            be manually tested by Summit, Summit can guarantee that the manually
            tested backup copies are working properly and can be restored.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        line-height: 13pt;
        text-align: justify;
      "
    >
      Article 9. Usage rules
    </h2>
    <ol id="l19">
      <ol id="l20">
        <li data-list-text="9.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Client is prohibited from using the Services in a manner that
            violates these Terms or applicable laws and regulations. In
            addition, Client is expressly prohibited from using the Services in
            a manner that may cause inconvenience or damage to Summit or third
            parties.
          </p>
        </li>
        <li data-list-text="9.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Summit determines that Client is violating these terms and
            conditions or the law, or receives a complaint about it, Summit may
            intervene to end the violation. Summit will then block access to the
            relevant information.
          </p>
        </li>
        <li data-list-text="9.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If, in Summit's opinion, a nuisance, damage or other danger arises
            for the functioning of Summit's or third parties' computer systems
            or network and/or the provision of services via the Internet, in
            particular due to the excessive sending of e-mail or other data,
            leaks of personal data or activities of viruses, trojans and similar
            software, Summit is entitled to take all measures it reasonably
            considers necessary to avert or prevent this danger. Such measures
            include, but are not limited to, suspension of the Services and
            termination of the Agreement.
          </p>
        </li>
        <li data-list-text="9.4.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: justify;
            "
          >
            Summit shall at all times be entitled to report detected criminal
            offences and shall cooperate with authorised orders. In addition,
            Summit is entitled to hand over identifying data of Client to a
            third party who complains that Client is infringing its rights or
            these terms and conditions, provided that:
          </p>
          <ol id="l21">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                it is sufficiently plausible that the information, taken in
                isolation, is unlawful or harmful towards the third party;
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                the third party reasonably has a real interest in obtaining the
                data;
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                it is plausible that, in the specific case, no less intrusive
                option is reasonably available to retrieve the data.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="9.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client is obliged to follow all reasonable directions given by
            Summit regarding the use of the Services.
          </p>
        </li>
        <li data-list-text="9.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit may recover damages resulting from violations of the rules in
            this article from Client. Client shall indemnify Summit against all
            third-party claims for damages resulting from a breach of the rules
            in this Article.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 10. Ticket sales
    </h2>
    <ol id="l22">
      <ol id="l23">
        <li data-list-text="10.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Client uses the Services to promote an event for which it offers
            (paid) tickets, Summit is not a party to the (purchase) agreement
            between Client and the person (or legal entity) purchasing the
            offered tickets. Summit acts only as an intermediary in the
            formation of such an agreement.
          </p>
        </li>
        <li data-list-text="10.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            When offering tickets, Client is responsible for compliance with
            applicable laws, including and not limited to consumer and tax laws
            applicable to Client and the persons (or legal entities) purchasing
            the tickets.
          </p>
        </li>
        <li data-list-text="10.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit does not provide payment services. When offering tickets for
            which a fee is payable through the Services, payment is handled by a
            third-party payment service provider. Summit is not responsible for
            errors from delays in payment.
          </p>
        </li>
        <li data-list-text="10.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            When offering tickets through the Services, Client is responsible
            for applying and paying the correct amount of VAT and other taxes
            imposed by the government or applicable laws for Client or the
            persons (or entities) purchasing the tickets.
          </p>
        </li>
        <li data-list-text="10.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If a ticket for an event is purchased (or tickets are purchased)
            through the Services and the person (or legal entity) purchasing the
            tickets recovers any (part of the) amount paid, Client shall
            reimburse Summit for such amount and all related related costs.
            Summit will send Client an invoice for such refund.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 11. Security and privacy
    </h2>
    <ol id="l24">
      <ol id="l25">
        <li data-list-text="11.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit will endeavour to secure the Services against misuse and
            unauthorised access to Client's data.
          </p>
        </li>
        <li data-list-text="11.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In the performance of the Agreement, Summit may process personal
            data on behalf of Client, whereby Client is deemed to be the
            processing controller and Summit is deemed to be the processor. The
            Processor Agreement in Annex 1 of these General Terms and Conditions
            applies to Summit's processing of personal data for Client.
            Mentioned terms shall have the meanings set out in the applicable
            privacy legislation.
          </p>
        </li>
        <li data-list-text="11.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client guarantees that any provision of (personal) data to Summit is
            lawful and that the processing of such data in accordance with the
            Agreement does not violate any applicable privacy laws and
            regulations.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Article 12. Intellectual Property Rights
    </h2>
    <ol id="l26">
      <ol id="l27">
        <li data-list-text="12.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            All Intellectual Property Rights in the Services and any Materials
            provided by Summit under the Agreement are vested exclusively in
            Summit or its licensors.
          </p>
        </li>
        <li data-list-text="12.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client receives from Summit the non-exclusive, non-transferable and
            non-sublicensable rights of use in respect of the Services and any
            Materials to the extent they arise under the Agreement or are
            otherwise granted in writing. The rights of use are for the duration
            of the Agreement.
          </p>
        </li>
        <li data-list-text="12.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Client is not entitled to make changes to the Software and is
            not entitled to a copy of the source files of the Software.
          </p>
        </li>
        <li data-list-text="12.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit may take (technical) measures to protect the Services. If
            Summit has taken such security measures, the Client shall not be
            permitted to circumvent or remove such security.
          </p>
        </li>
        <li data-list-text="12.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Customer is not permitted to remove or modify any indication of
            Intellectual Property Rights from the Software. It is also not
            permitted to remove indications of confidentiality from the
            Software.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 13. Confidentiality
    </h2>
    <ol id="l28">
      <ol id="l29">
        <li data-list-text="13.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Parties will keep confidential any information they provide to each
            other before, during or after the execution of the Agreement or
            process in the Services, if this information is marked confidential
            or if it is explicitly indicated in advance that it is confidential.
            The Parties shall also impose this obligation on their employees as
            well as third parties engaged by them for the performance of the
            Agreement.
          </p>
        </li>
        <li data-list-text="13.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The parties will treat commercial arrangements, such as agreed
            prices, as confidential in any case.
          </p>
        </li>
        <li data-list-text="13.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The obligation of confidentiality shall continue even after
            termination of the Agreement for whatever reason, for as long as the
            providing party can reasonably claim the confidentiality of the
            information.
          </p>
        </li>
        <li data-list-text="13.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            With regard to confidential information received, the parties will
            take reasonable measures for the safe custody or storage of the
            confidential information. With regard to confidential information
            received, the parties will apply at least the same level of security
            as the parties apply to their own confidential information.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Article 14. Prices and terms of payment
    </h2>
    <ol id="l30">
      <ol id="l31">
        <li data-list-text="14.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Client shall pay Summit a fee for the Service(s) as agreed or stated
            in the offer. Unless expressly stated otherwise with an amount, all
            prices quoted by Summit are exclusive of sales tax (VAT) and other
            levies imposed by governmental authorities.
          </p>
        </li>
        <li data-list-text="14.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            All prices in offers, price list(s) and/or other means of
            communication of Summit are subject to programming and typing
            errors. If a price is based on information provided by Client and
            this information turns out to be incorrect, Summit has the right to
            adjust the prices accordingly, even after the Agreement has already
            been concluded.
          </p>
        </li>
        <li data-list-text="14.3.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit is entitled to adjust its applied and/or agreed prices each
            time the Agreement is renewed and/or once per calendar year, based
            on inflation, wage indexations based on a collective bargaining
            agreement, an increase in costs or changed market conditions. If a
            supplier of Summit increases its prices, Summit shall always be
            entitled to immediately pass this increase on to Client.
          </p>
        </li>
        <li data-list-text="14.4.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit shall invoice for all amounts due and is thereby entitled to
            invoice in advance and electronically. If Client objects to (the
            amount of) an invoice, this does not suspend the payment obligation.
          </p>
        </li>
        <li data-list-text="14.5.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: left;
            "
          >
            If Additional Work has been agreed, the amounts due will be invoiced
            after the Additional Work has been carried out, unless otherwise
            agreed.
          </p>
        </li>
        <li data-list-text="14.6.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            A payment term of 30 days applies to all invoices, unless the
            invoice states a different payment term or a different term is
            agreed in writing.
          </p>
        </li>
        <li data-list-text="14.7.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            If Client has not paid in full after the payment period, Summit
            shall still give Client the opportunity to pay the invoice amount
            within fourteen (14) days. If Client has also failed to pay in full
            after this period, Client is automatically in default without notice
            of default being required. In such event, Summit shall have the
            right to suspend delivery of the Services in whole or in part.
            Summit shall not be liable for any damages incurred by Client as a
            result.
          </p>
        </li>
        <li data-list-text="14.8.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            In the event of late payment, in addition to the amount owed and the
            commercial interest accrued thereon, Client shall be liable for full
            compensation of both extrajudicial and judicial collection costs,
            including the costs of lawyers, lawyers, bailiffs and collection
            agencies.
          </p>
        </li>
        <li data-list-text="14.9.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            All claims of Summit against Client shall become immediately due and
            payable if Client's bankruptcy is filed for, Client is in a state of
            bankruptcy, Client applies for or is granted suspension of payments,
            Client's business is terminated, or if Client's business is
            liquidated.
          </p>
        </li>
        <li data-list-text="14.10.">
          <p
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            All prices quoted by Summit are in euros and exclusive of VAT and
            other levies which
          </p>
        </li>
      </ol>
    </ol>
    <p style="padding-left: 41pt; text-indent: 0pt; text-align: left">
      imposed by the government.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 15. Liability
    </h2>
    <ol id="l32">
      <ol id="l33">
        <li data-list-text="15.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit's liability for damages resulting from any breach of the
            Agreement, tort, breach of warranty or otherwise, shall be limited
            per event (whereby a series of consecutive events counts as one
            event) to compensation for direct damages with a maximum equal to
            the amount (excluding VAT) paid by the Client to Summit under the
            Agreement during 6 months prior to the occurrence of the damage.
          </p>
        </li>
        <li data-list-text="15.2.">
          <p
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Direct damage means exclusively all damage consisting of:
          </p>
          <ol id="l34">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                damage directly caused to tangible property ("property damage");
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                reasonable and demonstrable costs incurred by the Client to
                induce Summit to (re)perform the Agreement properly, unless the
                defective performance cannot be attributed to Summit;
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                reasonable costs to determine the cause and extent of the direct
                damage;
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                reasonable and demonstrable costs incurred by the Customer to
                prevent or limit the direct damage, insofar as the Customer
                demonstrates that these costs led to a limitation of the direct
                damage;
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                reasonable and demonstrable costs of having the Agreement
                performed by a third party, if Summit does not (again) properly
                perform within the reasonable period set out in the reminder
                after a reminder from Client.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="15.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit shall in no event be liable for compensation for indirect or
            consequential damages, such as damages for lost sales or profits,
            damages for delay, damages for loss of data and damages for lost
            savings.
          </p>
        </li>
        <li data-list-text="15.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The foregoing limitation of liability does not apply to the extent
            that the damage is the result of intentional or deliberate
            recklessness of Summit's management, or to the extent that Summit's
            liability cannot otherwise be limited under applicable law.
          </p>
        </li>
        <li data-list-text="15.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit's liability for attributable failure to perform the Agreement
            shall arise only if the Client immediately and properly gives Summit
            notice of default in writing, setting a reasonable time to remedy
            the failure, and Summit remains attributable in the performance of
            its obligations even after such time
          </p>
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            obligations remains in default. The notice of default shall contain
            as detailed a description of the default as possible to enable
            Summit to respond adequately.
          </p>
        </li>
        <li data-list-text="15.6.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            A condition for the creation of any right to compensation is that
            Client reports the damage to Summit in writing no later than 30 days
            after its discovery.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 16. Force majeure
    </h2>
    <ol id="l35">
      <ol id="l36">
        <li data-list-text="16.1.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            Summit cannot be held to fulfil any obligation under the Agreement
            if performance is prevented due to force majeure. Summit is not
            liable for any damages resulting from force majeure.
          </p>
        </li>
        <li data-list-text="16.2.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            Force majeure shall in any case include power failures, Internet
            failures, failures in the telecommunications infrastructure, network
            attacks (including (d)dos attacks), attacks by malware or other
            malicious software, internal riots, natural disasters, terror,
            mobilisation, war, import and export impediments, strikes, supply
            stagnation, fire, floods and the case in which Summit is unable to
            fulfil its obligations by its suppliers, regardless of the reason.
          </p>
        </li>
        <li data-list-text="16.3.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            If a force majeure situation has lasted longer than 90 days, both
            Parties have the right to terminate the Agreement immediately in
            writing. The Services provided by Summit in that case before the
            force majeure situation occurred and during the force majeure
            situation shall be charged pro rata.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 17. Duration and termination
    </h2>
    <ol id="l37">
      <ol id="l38">
        <li data-list-text="17.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Agreement is entered into for the period specified in Summit's
            quotation or offer.
          </p>
        </li>
        <li data-list-text="17.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the duration of the Agreement is not mentioned in the offer or
            quotation, the Agreement is deemed to have been entered into for a
            period of 12 months, unless the Agreement ends by a predetermined
            task.
          </p>
        </li>
        <li data-list-text="17.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the Agreement is a continuing performance agreement, it may be
            terminated by either Party towards the end of its term, subject to 3
            months' notice. In the absence of such notice, the Agreement shall
            be tacitly renewed each time for the initial term of the Agreement.
          </p>
        </li>
        <li data-list-text="17.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit may immediately suspend or terminate the Agreement in
            writing, without notice of default being required, if Client is in
            default of any material obligation under the Agreement.
          </p>
        </li>
        <li data-list-text="17.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit may immediately suspend or terminate the Agreement in
            writing, without notice of default being required, if Client's
            bankruptcy is filed for, Client is in a state of bankruptcy, Client
            applies for or is granted suspension of payments, Client's business
            is terminated, or if Client's business is liquidated.
          </p>
        </li>
        <li data-list-text="17.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Summit suspends performance of the Agreement, Summit retains its
            claims under the Agreement and applicable laws and regulations.
          </p>
        </li>
        <li data-list-text="17.7.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In the event that the Agreement is terminated, Summit's claims
            against Client are immediately due and payable (regardless of the
            reason for termination).
          </p>
        </li>
        <li data-list-text="17.8.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the Agreement is dissolved, the Customer shall remain liable for
            the amounts already invoiced and no obligations to undo them shall
            arise.
          </p>
        </li>
        <li data-list-text="17.9.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client may only dissolve the part of the Agreement not yet performed
            by Summit.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 18. Amendment of general terms and conditions
    </h2>
    <ol id="l39">
      <ol id="l40">
        <li data-list-text="18.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit has the right to amend or supplement these General Terms and
            Conditions. Summit will announce the amendment or supplement to
            Client by e-mail or via the Website at least 30 days before it takes
            effect.
          </p>
        </li>
        <li data-list-text="18.2.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: justify;
            "
          >
            <a name="bookmark0"
              >If Client does not wish to accept the amendments or additions,
              Client may raise a reasoned objection within 14 days of
              publication, after which Summit will reconsider the amendment or
              addition. If Summit decides to stand by the amendment or addition,
              Client may terminate the Agreement in writing by the date the
              amendment becomes</a
            >effective.
          </p>
        </li>
        <li data-list-text="18.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            <a name="bookmark1"
              >Both Parties shall cooperate fully in the event of amendments or
              additions to these General Terms and Conditions that are necessary
              due to new or changed legislation. Such amendments or additions
              will be made in consultation between the Parties, without the
              Customer having the option to terminate the Agreement</a
            >.
          </p>
        </li>
        <li data-list-text="18.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            <a href="#bookmark0" class="s5">The </a> procedure described
            <a href="#bookmark0" class="s5">in Articles </a
            ><a href="#bookmark1" class="s5">18.2. and </a>18.3. does not apply
            to amendments of minor importance. Such amendments may be made by
            Summit without notice.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 19. Exit
    </h2>
    <ol id="l41">
      <ol id="l42">
        <li data-list-text="19.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            After the end of the Agreement, Summit shall retain all data and
            information of Client for a period of 30 days. If Client has
            fulfilled all its payment obligations under the Agreement, Summit
            will endeavour to provide a copy of such data and information in a
            standard file format used by Summit.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 20. Other provisions
    </h2>
    <ol id="l43">
      <ol id="l44">
        <li data-list-text="20.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Agreement is exclusively governed by Dutch law.
          </p>
        </li>
        <li data-list-text="20.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            To the extent not otherwise required by the rules of mandatory law,
            all disputes between the Parties in connection with the Agreement
            will be submitted to the competent Dutch court for the district
            where Summit is located.
          </p>
        </li>
        <li data-list-text="20.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit is entitled to transfer its rights and obligations under the
            Agreement to a third party acquiring Summit or its business.
          </p>
        </li>
        <li data-list-text="20.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If any provision of the Agreement is found to be null and void, this
            shall not affect the validity of the Agreement as a whole. In that
            case, the parties will determine (a) new provision(s) to replace it,
            which will give shape to the intention of the original provision as
            much as legally possible.
          </p>
        </li>
        <li data-list-text="20.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall promptly notify Summit of any change in name, (postal)
            address, e-mail address, telephone number and other information and
            data relevant to the performance of the Agreement.
          </p>
        </li>
        <li data-list-text="20.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The logs and communications stored by Summit are deemed to be
            correct unless Client provides evidence to the contrary in this
            regard.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Module A | Rental Hardware
    </h1>
    <h2
      style="
        padding-top: 14pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Article 1. Rental Hardware
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      The parties may agree that the Client rents the Hardware from Summit. If
      the Agreement relates (in part) to the rental of the Hardware, it shall be
      subject to the terms and conditions as described in this Module.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Article 2. Hardware
    </h2>
    <ol id="l45">
      <ol id="l46">
        <li data-list-text="2.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            To this end, Summit grants Client for the duration and under the
            terms of the Agreement the right to use the Hardware as set out in
            the Agreement.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Client is responsible for providing power supply at
          </p>
          <p
            style="
              padding-left: 41pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            for the (proper) operation of the Hardware ("electricity").
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Client is responsible for providing a connection facility to the
          </p>
          <p style="padding-left: 41pt; text-indent: 0pt; text-align: left">
            Hardware to be connected to the internet and/or a local area network
            ("network connection").
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Only Summit is entitled to manage the Hardware and to carry out
            maintenance or any other form of maintenance or modification of the
            Hardware.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In the event the Client makes changes to the Hardware without
            Summit's prior express consent, the Client shall be required to
            reimburse Summit for costs incurred by Summit for the purpose of
            rectifying errors, problems or otherwise.
          </p>
        </li>
        <li data-list-text="2.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Without Summit's express consent, Client shall not be entitled to
            rent, sublet or otherwise make the Hardware available to third
            parties.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Article 3. Completion and installation
    </h2>
    <ol id="l47">
      <ol id="l48">
        <li data-list-text="3.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            In the absence of a delivery date expressly agreed by the Parties,
            Summit shall make the Hardware available within a reasonable period
            of time.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit will deliver the Hardware when, in its professional opinion,
            it meets the specifications and/or is suitable for the agreed use.
          </p>
        </li>
        <li data-list-text="3.3.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Unless otherwise agreed, the Customer shall install and connect the
            Hardware itself at a location to be determined by the Customer.
          </p>
        </li>
        <li data-list-text="3.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If agreed, Summit shall install and connect the Hardware at the
            location specified in the Agreement. Summit is entitled to refuse
            the installation of the Hardware at a particular location if, in its
            professional opinion, the installation is not possible and/or the
            proper functioning of the Hardware cannot be guaranteed.
          </p>
        </li>
        <li data-list-text="3.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit has the right to engage third parties for the purpose of
            installing the Hardware as referred to in the previous paragraph.
          </p>
        </li>
        <li data-list-text="3.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall provide Summit or the third party(ies) engaged by
            Summit with access to the designated location and the necessary
            cooperation for the installation of the Hardware.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 4. Operation and guarantees
    </h2>
    <ol id="l49">
      <ol id="l50">
        <li data-list-text="4.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Customer accepts that the Hardware contains only the functionality
            and other features as Customer finds them in the Hardware at the
            time of delivery ("as is"), therefore with all visible and invisible
            faults and defects, unless otherwise agreed in writing.
          </p>
        </li>
        <li data-list-text="4.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit shall make every effort to keep the Hardware available for
            Client and to remedy any breakdowns and technical problems as soon
            as possible. Client may only claim specific service levels, such as
            a certain availability or response time, if agreed in writing.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Article 5. Risk and insurance
    </h2>
    <ol id="l51">
      <ol id="l52">
        <li data-list-text="5.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Destruction, loss, wear and tear, theft or damage of the Hardware
            shall not affect the Customer's payment obligations. Responsibility
            for any repairs or replacement may be arranged by the Parties in a
            service level agreement designated as such.
          </p>
        </li>
        <li data-list-text="5.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Customer shall ensure that the Hardware is insured for its
            replacement value during the term of the Agreement against, inter
            alia: destruction, loss, theft or damage to the Hardware. The costs
            associated with this insurance shall be borne entirely by the
            Customer.
          </p>
        </li>
        <li data-list-text="5.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall ensure that, under the aforementioned insurance policy,
            Summit is included as a third-party beneficiary or co-insured. If
            the aforementioned is not reasonably possible, Client shall assign
            its claim against the relevant insurer to Summit.
          </p>
        </li>
        <li data-list-text="5.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Upon Summit's request, Client will provide copies of the relevant
            insurance policy and/or proof of full and timely premium payment.
          </p>
        </li>
        <li data-list-text="5.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit shall in no event be liable for damages arising as a result
            of the failure of the Hardware to function (correctly), unless there
            is intent or gross negligence on the part of Summit's management.
          </p>
        </li>
        <li data-list-text="5.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client indemnifies Summit against all claims by third parties
            (including customers of Client), for compensation for damages, costs
            or interest, related to the Hardware, Agreement and/or the Services.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 6. Ownership of the Hardware
    </h2>
    <ol id="l53">
      <ol id="l54">
        <li data-list-text="6.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Hardware is and shall remain the property of Summit. Nothing in
            the Agreement is intended to transfer ownership of the Hardware to
            Client.
          </p>
        </li>
        <li data-list-text="6.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit shall ensure that the Hardware is at all times recognisable
            as the property of Summit (e.g. through the use of labels). These
            markings and/or labels may not be removed by the Client.
          </p>
        </li>
        <li data-list-text="6.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If third parties claim or seize the Hardware, Client shall alert
            such third parties to Summit's ownership and inform Summit as soon
            as possible (both in writing and by telephone) of the claim. In this
            case, Client shall enable Summit to collect the Hardware from Client
            without delay.
          </p>
        </li>
        <li data-list-text="6.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The aforementioned (information) duty also applies if Customer
            suspects or should reasonably suspect that third parties will claim
            or seize the Hardware. Such a suspicion exists in any case if
            Customer can no longer satisfy its creditors (including the Tax
            Authorities) or knows that it will no longer be able to satisfy its
            creditors in the near future.
          </p>
        </li>
        <li data-list-text="6.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the Hardware is seized by creditors of Customer or due to a
            dispute involving Customer, this does not release Customer from its
            payment obligations.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 7. Returns
    </h2>
    <ol id="l55">
      <ol id="l56">
        <li data-list-text="7.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the Agreement is terminated or dissolved, Client shall return the
            Hardware delivered by Summit to Summit as soon as possible in
            accordance with Summit's instructions and within five (5) working
            days.
          </p>
        </li>
        <li data-list-text="7.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The costs of returning the Hardware for whatever reasons shall be
            borne by Customer.
          </p>
        </li>
        <li data-list-text="7.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall ensure proper packaging of the Hardware and shipment by
            means of insured transport.
          </p>
        </li>
        <li data-list-text="7.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If upon receipt of the Hardware by Summit it is found that the
            Hardware is in impaired condition, Summit shall be entitled to
            charge the cost of repair to Client.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      Article 8. Exclusion
    </h2>
    <ol id="l57">
      <ol id="l58">
        <li data-list-text="8.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            To the extent permitted by law, the Parties agree that Articles
            7:203 to 7:211 of the Civil Code are excluded.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Module B | Sales Hardware
    </h1>
    <h2
      style="
        padding-top: 13pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Article 1. Sales Hardware
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      The parties may agree that the Client purchases Hardware from Summit. If
      the Agreement (also) relates to the sale of Hardware, it shall be subject
      to the terms and conditions as described in this Module.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 2. Delivery of Hardware
    </h2>
    <ol id="l59">
      <ol id="l60">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Unless otherwise agreed, Hardware shall be delivered by Summit ex
            works. Risk of loss or damage to Hardware shall pass to Client at
            the time of legal and/or actual delivery.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall check delivered Hardware for any damages or other
            defects as soon as possible, but in any case within 7 calendar days.
            If Client finds any damages or other defects, Client shall record
            evidence of the damages or other defects and inform Summit
            immediately. A report of damages or other defects does not suspend
            the payment obligation of Client.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If the Client does not notify Summit of any damage or other defects
            within the aforementioned period, the Hardware shall be deemed to
            have been received in good condition.
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The Hardware purchased by Client from Summit is covered by a
            manufacturer's warranty. Information on what constitutes a
            manufacturer's warranty can be found on the manufacturer's website
            or accompanying documentation.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit will fulfil its legal obligation in the context of conformity
            of delivered Hardware, but does not grant Client any (additional)
            warranty on Hardware delivered by it.
          </p>
        </li>
        <li data-list-text="2.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The (statutory) warranty does not apply:
          </p>
          <ol id="l61">
            <li data-list-text="(1)">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                if the faults result from improper use or from causes other than
                defective material or workmanship;
              </p>
            </li>
            <li data-list-text="(2)">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  line-height: 13pt;
                  text-align: justify;
                "
              >
                if given instructions, for the use of the Hardware, have not
                been complied with;
              </p>
            </li>
            <li data-list-text="(3)">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                for the condition of the Hardware arising as a result of
                improper storage, or climatic or other influences.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2.7.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            The return of Hardware is only possible with Summit's prior consent
            and subject to Summit's procedures and instructions. If the Client
            wishes to return Hardware to Summit, the Client shall return the
            Hardware to Summit as far as possible in its original state of
            delivery. If there are any costs associated with returning Hardware,
            they shall be borne by the Client.
          </p>
        </li>
        <li data-list-text="2.8.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            If Summit is required to make repairs or replacement deliveries,
            Summit will always be given a reasonable time to make such repairs
            or replacement deliveries.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Article 3. Retention of title
    </h2>
    <ol id="l62">
      <ol id="l63">
        <li data-list-text="3.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            All Hardware delivered by Summit to Client shall remain the property
            of Summit until Client has properly fulfilled all its payment
            obligations to Summit.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            All Hardware delivered by Summit, which is subject to the retention
            of title from the previous paragraph of this article, may not be
            resold, used as a means of payment, pledged or otherwise encumbered
            by Client.
          </p>
        </li>
        <li data-list-text="3.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client shall do all that may reasonably be expected of it to secure
            Summit's property rights.
          </p>
        </li>
        <li data-list-text="3.4.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: justify;
            "
          >
            If a third party wishes to seize or have seized Hardware delivered
            under retention of title, or wishes to establish or assert rights to
            it, Client shall notify Summit immediately.
          </p>
        </li>
        <li data-list-text="3.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Client grants in advance express and unconditional permission to
            Summit and third parties to be appointed by Summit to enter the
            places, where Summit's properties are located, and to repossess the
            Hardware if Client fails to meet its payment obligation.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Annex 1. Processor agreement
    </h1>
    <p
      style="
        padding-top: 14pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      The Processor Agreement in this Schedule 1 and the agreements contained
      therein relate to Summit's processing of Client's personal data.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 1. DEFINITIONS
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      The words included in this Processor Agreement (such as "personal data",
      "process" and "data subject") shall, in any conjugation, have the meaning
      as referred to in the applicable (privacy) laws and regulations, including
      the AVG. In addition, the words below in this Processor Agreement, always
      indicated with a capital letter, both in singular and plural, have the
      meaning as described in this article.
    </p>
    <ol id="l64">
      <ol id="l65">
        <li data-list-text="1.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            AVG: Regulation (EU) 2016/679, also known as the General Data
            Protection Regulation.
          </p>
        </li>
        <li data-list-text="1.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Data breach: a breach of security leading accidentally or unlawfully
            to the destruction, loss, alteration or unauthorised disclosure of,
            or unauthorised access to, data transmitted, stored or otherwise
            processed.
          </p>
        </li>
        <li data-list-text="1.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Service(s): the services provided by Processor to the Controller
            under the Agreement.
          </p>
        </li>
        <li data-list-text="1.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Respondent: Principal.
          </p>
        </li>
        <li data-list-text="1.5.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor: Summit.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        line-height: 13pt;
        text-align: left;
      "
    >
      ARTICLE 2. PURPOSES OF PROCESSING
    </h2>
    <ol id="l66">
      <ol id="l67">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor undertakes to process personal data on behalf of the
            Responsible Party under the terms of this Processor Agreement.
            Processing will only take place in the context of the Agreement and
            proper performance of the Services agreed therein for the benefit of
            the Responsible Party, and those purposes reasonably related thereto
            or determined by further agreement.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The personal data (to be) processed by Processor under the
            Agreement, and the categories of data subjects from whom they
            originate, are set out in Annex
          </p>
          <p style="padding-left: 34pt; text-indent: 0pt; text-align: justify">
            2. Processor shall not process the personal data for any purpose
            other than as determined by the Responsible Party. The Controller
            shall inform Processor of the processing purposes insofar as they
            are not already mentioned in this Processor Agreement.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Any (property) rights to the personal data that Processor processes
            for the Responsible Party shall remain with the Responsible Party
            and/or the relevant data subjects.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 3. OBLIGATIONS OF PROCESSOR
    </h2>
    <ol id="l68">
      <ol id="l69">
        <li data-list-text="3.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            With regard to the processing mentioned in Article 2, Processor will
            make every effort to comply with the laws and regulations applicable
            to Processor in the area of personal data protection. Processor
            shall inform the Controller, at the latter's first request, of the
            measures it has taken regarding its obligations under this Processor
            Agreement.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The obligations of Processor arising from this Processor Agreement
            also apply to those who process personal data under the authority of
            Processor, including but not limited to employees.
          </p>
        </li>
        <li data-list-text="3.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor shall provide the Responsible Party with the necessary and
            reasonably possible cooperation when a data protection impact
            assessment (also referred to as a 'Privacy Impact Assessment') is
            required in the context of the processing. Also, at the request of
            the Responsible Party, Processor will reasonably cooperate in
            handling a request from a data subject as referred to
          </p>
        </li>
      </ol>
    </ol>
    <p
      style="
        padding-top: 1pt;
        padding-left: 34pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      in Article 8. Any costs to be incurred by Processor in this context shall
      be reasonably borne by the Controller.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 4. TRANSFER OF PERSONAL DATA
    </h2>
    <ol id="l70">
      <ol id="l71">
        <li data-list-text="4.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Respondent authorises Processor, in the context of the performance
            of the Master or Processor Agreement, to have the personal data
            processed by third parties (also referred to as subcontractors or
            sub-processors). This consent includes in any case the permission to
            Processor to have the personal data processed by an appropriate
            third party that will host the Processor's Service. At the request
            of the Controller, Processor shall inform the Controller of the
            third parties engaged by it.
          </p>
        </li>
        <li data-list-text="4.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor processes personal data only within countries in the
            European Economic Area (EEA), or with third parties located in
            countries with an adequate level of protection as recognised by the
            European Commission. At the request of the Controller, Processor
            will disclose which country or countries are concerned.
          </p>
        </li>
        <li data-list-text="4.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If Processor intends to engage new third parties in the context of
            processing personal data under this Processor Agreement, Processor
            will inform the Responsible Party on its own initiative. The
            Controller will then have two weeks to object to the engagement of
            the new third party. This objection must be made in writing and
            supported by arguments.
          </p>
        </li>
        <li data-list-text="4.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If the Controller objects as referred to above, the Parties will
            seek a reasonable solution in proper consultation. Should it occur
            that the Parties cannot jointly reach a solution, Processor is
            entitled to engage the new third parties as yet. In this case, the
            Controller is entitled to terminate the Main and Processing
            Agreement by the date on which the relevant new third party is
            engaged by Processor.
          </p>
        </li>
        <li data-list-text="4.5.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If the Controller does not object within the two-week period
            referred to in Article 4.3, then Processor is entitled to engage the
            relevant new third party.
          </p>
        </li>
        <li data-list-text="4.6.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If Processor engages a third party to process personal data,
            Processor shall endeavour to impose substantially the same
            obligations on that third party as the obligations imposed on
            Processor under this Processor Agreement to the extent relevant.
          </p>
        </li>
        <li data-list-text="4.7.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor is responsible for the third parties it has engaged on its
            own initiative and, in case of errors by these third parties, is
            itself liable to the Controller for damages as if Processor itself
            had committed the error(s).
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 5. DIVISION OF RESPONSIBILITY
    </h2>
    <ol id="l72">
      <ol id="l73">
        <li data-list-text="5.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Under the explicit (ultimate) responsibility of the Responsible
            Party and within the purposes described in Article 2, the Processor
            is solely responsible for the processing of personal data through
            the Service it provides under the Agreement. For other processing of
            personal data, including in any case but not limited to the
            collection of the personal data by the Responsible Party and/or
            third parties, processing for purposes not notified to Processor by
            the Responsible Party, processing by third parties and/or for other
            purposes, Processor is explicitly not responsible.
          </p>
        </li>
        <li data-list-text="5.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Responsibility for personal data processed using a Service provided
            by Processor lies with the Controller. It is for the Responsible
            Party to determine whether it may have the personal data processed
            by Processor under the agreed conditions. The Responsible Party
            guarantees that the content, use and commissioning of the processing
            of the personal data as referred to in the Master and Processor
            Agreement are not unlawful and do not infringe any rights of third
            parties. The Responsible Party indemnifies Processor against all
            claims of
          </p>
        </li>
      </ol>
    </ol>
    <p
      style="
        padding-top: 1pt;
        padding-left: 34pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      third parties arising from the failure of the Respondent to comply with
      the aforementioned guarantee.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 6. SECURITY
    </h2>
    <ol id="l74">
      <ol id="l75">
        <li data-list-text="6.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor shall endeavour to take appropriate technical and
            organisational measures in relation to the processing of personal
            data to be carried out in order to protect the personal data against
            loss or against any form of unlawful processing (such as
            unauthorised access, impairment, modification or disclosure of the
            personal data).
          </p>
        </li>
        <li data-list-text="6.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The parties acknowledge that it is impossible to implement security
            measures that are effective under all circumstances. Processor shall
            endeavour to the best of its ability to ensure that the security
            measures meet a level that is appropriate, given the state of the
            art, the sensitivity of the personal data and the costs associated
            with implementing the security measures.
          </p>
        </li>
        <li data-list-text="6.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor will describe in Annex 2 which (groups of) employees have
            access to the personal data and which processing operations are
            permitted by the employees concerned.
          </p>
        </li>
        <li data-list-text="6.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Respondent decides exactly which personal data will be processed by
            Processor and will only make personal data available to Processor
            for processing if Respondent has satisfied itself that the security
            measures required by Respondent are in place.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 7. NOTICE LIGHT
    </h2>
    <ol id="l76">
      <ol id="l77">
        <li data-list-text="7.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If Processor has identified a Data Breach, then Processor shall
            inform the Responsible Party about this without delay, but in any
            case within 48 hours, as a result of which the Responsible Party
            shall assess whether or not to inform the data subject(s) and/or the
            relevant regulator(s).
          </p>
        </li>
        <li data-list-text="7.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If Processor has detected a Data Breach, Processor shall take all
            reasonable measures to prevent or limit (further) unlawful
            processing.
          </p>
        </li>
        <li data-list-text="7.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The notification by Processor to the Responsible Party shall include
            the fact that a Data Breach has occurred. At the request of the
            Responsible Party, Processor shall provide the procedure that
            Processor has in place regarding the handling of a Data Breach as
            well as the details that Processor will provide.
          </p>
        </li>
        <li data-list-text="7.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            At the request of the Controller and if necessary, Processor shall
            reasonably cooperate in informing the data subject(s) and/or the
            relevant regulator(s).
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 8. HANDLING OF REQUESTS FROM DATA SUBJECTS
    </h2>
    <ol id="l78">
      <ol id="l79">
        <li data-list-text="8.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            In the event that a data subject makes a request to Processor to
            inspect, correct, supplement, amend or block their personal data,
            Processor shall forward the request to the Controller, and the
            Controller shall further process the request. Processor may notify
            the data subject accordingly.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 9. SECRECY AND CONFIDENTIALITY
    </h2>
    <ol id="l80">
      <ol id="l81">
        <li data-list-text="9.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            All personal data processed by Processor on behalf of the
            Responsible Party are subject to a duty of confidentiality towards
            third parties.
          </p>
        </li>
        <li data-list-text="9.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            This duty of confidentiality does not apply: (i) insofar as the
            Respondent has given express consent to provide the personal data to
            third parties,
          </p>
          <p style="padding-left: 34pt; text-indent: 0pt; text-align: justify">
            (ii) if the provision of the personal data to third parties is
            logically necessary given the nature of the assignment provided and
            the performance of the Master and Processor Agreement, (iii) if
            personal data is provided to third parties in their capacity as a
            subcontractor/sub-processor, or (iv) if there is a legal obligation
            to provide the personal data to a third party.
          </p>
        </li>
        <li data-list-text="9.3.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 34pt;
              text-indent: -28pt;
              text-align: justify;
            "
          >
            If Processor is required by a legal obligation to disclose personal
            data to a third party, it will inform the Respondent in advance and
            allow the Respondent reasonable time to pursue legal remedies to
            counteract the disclosure of personal data, unless prohibited under
            the relevant legislation.
          </p>
        </li>
        <li data-list-text="9.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If Processor uses the services of third parties, Processor shall
            ensure that these third parties also agree on a duty of
            confidentiality with Processor.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 10. AUDIT
    </h2>
    <ol id="l82">
      <ol id="l83">
        <li data-list-text="10.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Respondent has the right to have audits performed by an independent
            ICT expert to verify compliance with all points in this Processor
            Agreement.
          </p>
        </li>
        <li data-list-text="10.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            This audit may take place once every two years. At least two weeks
            prior to the intended audit, the Controller will inform Processor.
          </p>
        </li>
        <li data-list-text="10.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The independent ICT expert who will perform the audit must conform
            to the security procedures as in force at Processor. Furthermore,
            adequate confidentiality must be agreed with this ICT expert.
          </p>
        </li>
        <li data-list-text="10.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Processor shall cooperate with the audit and provide all information
            reasonably relevant to the audit, including supporting data such as
            system logs, and employees as timely as possible.
          </p>
        </li>
        <li data-list-text="10.5.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The findings as a result of the audit carried out will be assessed
            by the Parties in mutual consultation and, as a result, may or may
            not be implemented by either or both Parties jointly.
          </p>
        </li>
        <li data-list-text="10.6.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            All costs related to an audit will be borne by Respondent.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 11. AMENDMENT
    </h2>
    <ol id="l84">
      <ol id="l85">
        <li data-list-text="11.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            If a change in the personal data to be processed or a risk analysis
            of the processing of personal data gives cause to do so, the Parties
            shall, at the first request of the Responsible Party, enter into
            consultations about adapting the arrangements made within this
            Processor Agreement.
          </p>
        </li>
        <li data-list-text="11.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            This Processor Agreement may only be amended in writing and with the
            agreement of both Parties. If the Parties have agreed on new
            arrangements, the Parties will add these new arrangements to this
            Processor Agreement.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 12. DURATION AND TERMINATION
    </h2>
    <ol id="l86">
      <ol id="l87">
        <li data-list-text="12.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            This Processor Agreement shall come into effect when the Parties
            sign the Agreement and on the date of the last signature.
          </p>
        </li>
        <li data-list-text="12.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            The duration of the Processor Agreement is equal to the duration of
            the Agreement. The Processor Agreement cannot be terminated
            separately from the Agreement. If the Agreement ends for any reason,
            then this Processor Agreement will also automatically end.
          </p>
        </li>
        <li data-list-text="12.3.">
          <p
            style="
              padding-left: 34pt;
              text-indent: -28pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Upon termination of the Processor Agreement for any reason,
            Processor shall, upon request:
          </p>
          <ol id="l88">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                make available to the Responsible Party, in original or copy
                form, the personal data, which the Processor has processed on
                behalf of the Responsible Party and which is still in the
                Processor's possession, in the file format in which it was
                stored with the Processor; or
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                destroy the personal data, which Processor has processed on
                behalf of the Responsible Party and which are still in the
                Processor's possession.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="12.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            As far as reasonably possible, the Parties will make every effort to
            agree on a reasonable fee for the work in question prior to the work
            mentioned in Article 12.3. If the Parties have not agreed on a fee,
            Processor is entitled to charge a market-based fee to the
            Respondent.
          </p>
        </li>
        <li data-list-text="12.5.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 34pt;
              text-indent: -28pt;
              text-align: justify;
            "
          >
            If Respondent does not submit a request to Processor to carry out
            any of the activities mentioned in Article 12.3, then Processor is
            entitled to delete the personal data in question from its systems as
            of one month after the end of the Processor Agreement.
          </p>
        </li>
        <li data-list-text="12.6.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Obligations which by their nature are intended to continue after the
            termination of this Processor Agreement shall continue to apply
            after the termination of this Processor Agreement. These provisions
            include those arising from the provisions on confidentiality and
            applicable law.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTICLE 13. OTHER
    </h2>
    <ol id="l89">
      <ol id="l90">
        <li data-list-text="13.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            This Processor Agreement forms an integral part of and is
            supplemented by the Agreement. This means, inter alia, that the
            limitation of liability as agreed in the Agreement and the related
            general terms and conditions of Processor shall also apply to this
            Processor Agreement.
          </p>
        </li>
        <li data-list-text="13.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Logs and measurements made by Processor shall constitute compelling
            evidence, subject to evidence to the contrary to be provided by the
            Responsible Party.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Annex 2. Specification of personal data
    </h1>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      The (categories of) personal data to be processed
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      The following (categories of) personal data are processed by Processor on
      behalf of the Controller.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <table
      style="border-collapse: collapse; margin-left: 6.074pt"
      cellspacing="0"
    >
      <tr style="height: 27pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Function(group)
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            (Category) Personal data
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Type of processing
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Contact details
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Name, address and city
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Capture, preserve and
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              line-height: 14pt;
              text-align: left;
            "
          >
            use for analysis and follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Contact details
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Phone number, e-mail address
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Capture, retain and use for analysis and
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Profile details
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            For example: position, prior education
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Capture, retain and use for analysis and
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Interest
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            For example: session choice, training interest
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Capture, retain and use for analysis and
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Other
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 14pt;
              text-align: left;
            "
          >
            identifying data
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Gender, date of birth
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Capture, preserve and
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              line-height: 14pt;
              text-align: left;
            "
          >
            use for analysis and follow-up
          </p>
        </td>
      </tr>
    </table>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      For all data reported above, for each registration of an activity, meeting
      or event, the Respondent determines whether these data are requested and,
      if requested, whether or not they need to be answered optionally by the
      user.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      No special personal data (such as personal data relating to religion,
      sexual orientation, health or finances) are processed.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Personal data shall not be retained by Processor for longer than necessary
      for the performance of the Main and Processor Agreement or to comply with
      a legal obligation incumbent upon it. The following retention periods
      apply to Personal Data processed for the purpose of the proper operation
      of the Service (logging, back-up facilities, etc.):
    </p>
    <ul id="l91">
      <li data-list-text="">
        <p style="padding-left: 41pt; text-indent: -18pt; text-align: left">
          1 year, unless the Parties agree otherwise.
        </p>
        <p style="text-indent: 0pt; text-align: left"><br /></p>
        <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          Categories involved
        </h2>
        <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          The aforementioned personal data are processed from the following
          categories of data subjects:
        </p>
        <ul id="l92">
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              employees of Respondent using the Service;
            </p>
          </li>
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              visitors to an event organised by Respondent;
            </p>
          </li>
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              Persons interested in or registered for Respondent's event.
            </p>
          </li>
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              Persons (study-goers) interested in pursuing a course with
              Respondent
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Job roles/function groups and their processing
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      The following table lists the job roles and/or job groups of Processor's
      and Controller's employees who have access to certain personal data. It
      also states which processing operations they may perform in respect of the
      personal data.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <table
      style="border-collapse: collapse; margin-left: 6.074pt"
      cellspacing="0"
    >
      <tr style="height: 27pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          bgcolor="#A6A6A6"
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Function(group)
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          bgcolor="#A6A6A6"
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            (Category)
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            Personal data
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          bgcolor="#A6A6A6"
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Type of processing
          </p>
        </td>
      </tr>
      <tr style="height: 81pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 63pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Project Management department
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            All personal data, as listed in Annex A
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            PM (Project Management) employees of Processor have access to the
            personal data for the purpose of advising Respondent's employees on
            substantive questions and assisting with data analysis on behalf of
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            Respondent.
          </p>
        </td>
      </tr>
      <tr style="height: 108pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            IT department
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            All personal data, as listed in Annex A
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            Processor's IT staff for the purpose of software development,
            updating, testing and admin function all focused on continuity and
            optimisation of the systems. They are also available as technical
            helpdesk and responsible for the establishment and management of any
            links.
          </p>
        </td>
      </tr>
      <tr style="height: 162pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 8pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Authorised staff Responsible
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            All personal data, as listed in Annex A
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            Naturally, employees of the Respondent itself also have access to
            the personal data. Users of the Service have access to the
            dashboard. Here, all data for which they are authorised are accessed
            for them. Users are always linked to the Controller and cannot
            access the dashboard of other principals of Processor. Processor's
            administrators designated by the Responsible Party have the
            permissions to determine which data can be accessed via the
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: justify;
            "
          >
            dashboard are accessed.
          </p>
        </td>
      </tr>
    </table>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        line-height: 13pt;
        text-align: justify;
      "
    >
      Security measures taken
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Processor will take appropriate security measures and send the Controller
      a summary of these, upon request.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      In case you detect security risks, please contact
    </p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Processor (Summit) at 030-2307940.
    </p>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      Sub-processors
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Processor has permission from Controller to use third parties in
      processing personal data. Processor engages the following third
      party/parties:
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <ul id="l93">
      <li data-list-text="-">
        <p
          style="
            padding-left: 41pt;
            text-indent: -18pt;
            line-height: 14pt;
            text-align: justify;
          "
        >
          Organisation name: Leaseweb.
        </p>
      </li>
      <li data-list-text="-">
        <p
          style="
            padding-left: 41pt;
            text-indent: -18pt;
            line-height: 14pt;
            text-align: justify;
          "
        >
          Short service description: hosting provider for Processor's database
          servers.
        </p>
      </li>
      <li data-list-text="-">
        <p style="padding-left: 41pt; text-indent: -18pt; text-align: justify">
          Extent of processing Personal Data: Leaseweb is only responsible for
          hosting Processor's servers and is not otherwise involved in any
          further way in the processing of personal data.
        </p>
      </li>
      <li data-list-text="-">
        <p
          style="
            padding-left: 41pt;
            text-indent: -18pt;
            line-height: 14pt;
            text-align: justify;
          "
        >
          Place/Country of data processing: Amsterdam, the Netherlands.
        </p>
      </li>
    </ul>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Contact details
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Respondent shall, before the conclusion of the Agreement or as soon as
      possible after the conclusion of the Agreement, appoint an internal
      contact person whom Processor can contact in the context of
      privacy-related matters and this Processor Agreement. The contact details
      of this contact person (the name, position, email address and telephone
      number) will be provided in writing to Processor as soon as possible.
    </p>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

h1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 18pt;
}

.s1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 18pt;
}

.p,
p {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

h2 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

a {
  color: #0462c1;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

.s3 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

.s4 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s5 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s6 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
  counter-reset: c2 1;
}

#l2 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l2 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}

li {
  display: block;
}

#l3 {
  padding-left: 0pt;
  counter-reset: d1 2;
}

#l3 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l3 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}

#l4 {
  padding-left: 0pt;
  counter-reset: d2 1;
}

#l4 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d1, decimal) "." counter(d2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l4 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}

#l5 {
  padding-left: 0pt;
  counter-reset: d3 1;
}

#l5 > li > *:first-child:before {
  counter-increment: d3;
  content: counter(d3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l5 > li:first-child > *:first-child:before {
  counter-increment: d3 0;
}

li {
  display: block;
}

#l6 {
  padding-left: 0pt;
  counter-reset: e1 3;
}

#l6 > li > *:first-child:before {
  counter-increment: e1;
  content: counter(e1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l6 > li:first-child > *:first-child:before {
  counter-increment: e1 0;
}

#l7 {
  padding-left: 0pt;
  counter-reset: e2 1;
}

#l7 > li > *:first-child:before {
  counter-increment: e2;
  content: counter(e1, decimal) "." counter(e2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l7 > li:first-child > *:first-child:before {
  counter-increment: e2 0;
}

#l8 {
  padding-left: 0pt;
  counter-reset: e3 1;
}

#l8 > li > *:first-child:before {
  counter-increment: e3;
  content: counter(e3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l8 > li:first-child > *:first-child:before {
  counter-increment: e3 0;
}

li {
  display: block;
}

#l9 {
  padding-left: 0pt;
  counter-reset: f1 4;
}

#l9 > li > *:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l9 > li:first-child > *:first-child:before {
  counter-increment: f1 0;
}

#l10 {
  padding-left: 0pt;
  counter-reset: f2 1;
}

#l10 > li > *:first-child:before {
  counter-increment: f2;
  content: counter(f1, decimal) "." counter(f2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l10 > li:first-child > *:first-child:before {
  counter-increment: f2 0;
}

li {
  display: block;
}

#l11 {
  padding-left: 0pt;
  counter-reset: g1 5;
}

#l11 > li > *:first-child:before {
  counter-increment: g1;
  content: counter(g1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l11 > li:first-child > *:first-child:before {
  counter-increment: g1 0;
}

#l12 {
  padding-left: 0pt;
  counter-reset: g2 1;
}

#l12 > li > *:first-child:before {
  counter-increment: g2;
  content: counter(g1, decimal) "." counter(g2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l12 > li:first-child > *:first-child:before {
  counter-increment: g2 0;
}

li {
  display: block;
}

#l13 {
  padding-left: 0pt;
  counter-reset: h1 6;
}

#l13 > li > *:first-child:before {
  counter-increment: h1;
  content: counter(h1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l13 > li:first-child > *:first-child:before {
  counter-increment: h1 0;
}

#l14 {
  padding-left: 0pt;
  counter-reset: h2 1;
}

#l14 > li > *:first-child:before {
  counter-increment: h2;
  content: counter(h1, decimal) "." counter(h2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l14 > li:first-child > *:first-child:before {
  counter-increment: h2 0;
}

li {
  display: block;
}

#l15 {
  padding-left: 0pt;
  counter-reset: i1 7;
}

#l15 > li > *:first-child:before {
  counter-increment: i1;
  content: counter(i1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l15 > li:first-child > *:first-child:before {
  counter-increment: i1 0;
}

#l16 {
  padding-left: 0pt;
  counter-reset: i2 1;
}

#l16 > li > *:first-child:before {
  counter-increment: i2;
  content: counter(i1, decimal) "." counter(i2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l16 > li:first-child > *:first-child:before {
  counter-increment: i2 0;
}

li {
  display: block;
}

#l17 {
  padding-left: 0pt;
  counter-reset: j1 8;
}

#l17 > li > *:first-child:before {
  counter-increment: j1;
  content: counter(j1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l17 > li:first-child > *:first-child:before {
  counter-increment: j1 0;
}

#l18 {
  padding-left: 0pt;
  counter-reset: j2 1;
}

#l18 > li > *:first-child:before {
  counter-increment: j2;
  content: counter(j1, decimal) "." counter(j2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l18 > li:first-child > *:first-child:before {
  counter-increment: j2 0;
}

li {
  display: block;
}

#l19 {
  padding-left: 0pt;
  counter-reset: k1 9;
}

#l19 > li > *:first-child:before {
  counter-increment: k1;
  content: counter(k1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l19 > li:first-child > *:first-child:before {
  counter-increment: k1 0;
}

#l20 {
  padding-left: 0pt;
  counter-reset: k2 1;
}

#l20 > li > *:first-child:before {
  counter-increment: k2;
  content: counter(k1, decimal) "." counter(k2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l20 > li:first-child > *:first-child:before {
  counter-increment: k2 0;
}

#l21 {
  padding-left: 0pt;
  counter-reset: k3 1;
}

#l21 > li > *:first-child:before {
  counter-increment: k3;
  content: counter(k3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l21 > li:first-child > *:first-child:before {
  counter-increment: k3 0;
}

li {
  display: block;
}

#l22 {
  padding-left: 0pt;
  counter-reset: l1 10;
}

#l22 > li > *:first-child:before {
  counter-increment: l1;
  content: counter(l1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l22 > li:first-child > *:first-child:before {
  counter-increment: l1 0;
}

#l23 {
  padding-left: 0pt;
  counter-reset: l2 1;
}

#l23 > li > *:first-child:before {
  counter-increment: l2;
  content: counter(l1, decimal) "." counter(l2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l23 > li:first-child > *:first-child:before {
  counter-increment: l2 0;
}

li {
  display: block;
}

#l24 {
  padding-left: 0pt;
  counter-reset: m1 11;
}

#l24 > li > *:first-child:before {
  counter-increment: m1;
  content: counter(m1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l24 > li:first-child > *:first-child:before {
  counter-increment: m1 0;
}

#l25 {
  padding-left: 0pt;
  counter-reset: m2 1;
}

#l25 > li > *:first-child:before {
  counter-increment: m2;
  content: counter(m1, decimal) "." counter(m2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l25 > li:first-child > *:first-child:before {
  counter-increment: m2 0;
}

li {
  display: block;
}

#l26 {
  padding-left: 0pt;
  counter-reset: n1 12;
}

#l26 > li > *:first-child:before {
  counter-increment: n1;
  content: counter(n1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l26 > li:first-child > *:first-child:before {
  counter-increment: n1 0;
}

#l27 {
  padding-left: 0pt;
  counter-reset: n2 1;
}

#l27 > li > *:first-child:before {
  counter-increment: n2;
  content: counter(n1, decimal) "." counter(n2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l27 > li:first-child > *:first-child:before {
  counter-increment: n2 0;
}

li {
  display: block;
}

#l28 {
  padding-left: 0pt;
  counter-reset: o1 13;
}

#l28 > li > *:first-child:before {
  counter-increment: o1;
  content: counter(o1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l28 > li:first-child > *:first-child:before {
  counter-increment: o1 0;
}

#l29 {
  padding-left: 0pt;
  counter-reset: o2 1;
}

#l29 > li > *:first-child:before {
  counter-increment: o2;
  content: counter(o1, decimal) "." counter(o2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l29 > li:first-child > *:first-child:before {
  counter-increment: o2 0;
}

li {
  display: block;
}

#l30 {
  padding-left: 0pt;
  counter-reset: p1 14;
}

#l30 > li > *:first-child:before {
  counter-increment: p1;
  content: counter(p1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l30 > li:first-child > *:first-child:before {
  counter-increment: p1 0;
}

#l31 {
  padding-left: 0pt;
  counter-reset: p2 1;
}

#l31 > li > *:first-child:before {
  counter-increment: p2;
  content: counter(p1, decimal) "." counter(p2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l31 > li:first-child > *:first-child:before {
  counter-increment: p2 0;
}

li {
  display: block;
}

#l32 {
  padding-left: 0pt;
  counter-reset: q1 15;
}

#l32 > li > *:first-child:before {
  counter-increment: q1;
  content: counter(q1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l32 > li:first-child > *:first-child:before {
  counter-increment: q1 0;
}

#l33 {
  padding-left: 0pt;
  counter-reset: q2 1;
}

#l33 > li > *:first-child:before {
  counter-increment: q2;
  content: counter(q1, decimal) "." counter(q2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l33 > li:first-child > *:first-child:before {
  counter-increment: q2 0;
}

#l34 {
  padding-left: 0pt;
  counter-reset: q3 1;
}

#l34 > li > *:first-child:before {
  counter-increment: q3;
  content: counter(q3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l34 > li:first-child > *:first-child:before {
  counter-increment: q3 0;
}

li {
  display: block;
}

#l35 {
  padding-left: 0pt;
  counter-reset: r1 16;
}

#l35 > li > *:first-child:before {
  counter-increment: r1;
  content: counter(r1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l35 > li:first-child > *:first-child:before {
  counter-increment: r1 0;
}

#l36 {
  padding-left: 0pt;
  counter-reset: r2 1;
}

#l36 > li > *:first-child:before {
  counter-increment: r2;
  content: counter(r1, decimal) "." counter(r2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l36 > li:first-child > *:first-child:before {
  counter-increment: r2 0;
}

li {
  display: block;
}

#l37 {
  padding-left: 0pt;
  counter-reset: s1 17;
}

#l37 > li > *:first-child:before {
  counter-increment: s1;
  content: counter(s1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l37 > li:first-child > *:first-child:before {
  counter-increment: s1 0;
}

#l38 {
  padding-left: 0pt;
  counter-reset: s2 1;
}

#l38 > li > *:first-child:before {
  counter-increment: s2;
  content: counter(s1, decimal) "." counter(s2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l38 > li:first-child > *:first-child:before {
  counter-increment: s2 0;
}

li {
  display: block;
}

#l39 {
  padding-left: 0pt;
  counter-reset: t1 18;
}

#l39 > li > *:first-child:before {
  counter-increment: t1;
  content: counter(t1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l39 > li:first-child > *:first-child:before {
  counter-increment: t1 0;
}

#l40 {
  padding-left: 0pt;
  counter-reset: t2 1;
}

#l40 > li > *:first-child:before {
  counter-increment: t2;
  content: counter(t1, decimal) "." counter(t2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l40 > li:first-child > *:first-child:before {
  counter-increment: t2 0;
}

li {
  display: block;
}

#l41 {
  padding-left: 0pt;
  counter-reset: u1 19;
}

#l41 > li > *:first-child:before {
  counter-increment: u1;
  content: counter(u1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l41 > li:first-child > *:first-child:before {
  counter-increment: u1 0;
}

#l42 {
  padding-left: 0pt;
  counter-reset: u2 1;
}

#l42 > li > *:first-child:before {
  counter-increment: u2;
  content: counter(u1, decimal) "." counter(u2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l42 > li:first-child > *:first-child:before {
  counter-increment: u2 0;
}

li {
  display: block;
}

#l43 {
  padding-left: 0pt;
  counter-reset: v1 20;
}

#l43 > li > *:first-child:before {
  counter-increment: v1;
  content: counter(v1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l43 > li:first-child > *:first-child:before {
  counter-increment: v1 0;
}

#l44 {
  padding-left: 0pt;
  counter-reset: v2 1;
}

#l44 > li > *:first-child:before {
  counter-increment: v2;
  content: counter(v1, decimal) "." counter(v2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l44 > li:first-child > *:first-child:before {
  counter-increment: v2 0;
}

li {
  display: block;
}

#l45 {
  padding-left: 0pt;
  counter-reset: w1 2;
}

#l45 > li > *:first-child:before {
  counter-increment: w1;
  content: counter(w1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l45 > li:first-child > *:first-child:before {
  counter-increment: w1 0;
}

#l46 {
  padding-left: 0pt;
  counter-reset: w2 1;
}

#l46 > li > *:first-child:before {
  counter-increment: w2;
  content: counter(w1, decimal) "." counter(w2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l46 > li:first-child > *:first-child:before {
  counter-increment: w2 0;
}

li {
  display: block;
}

#l47 {
  padding-left: 0pt;
  counter-reset: x1 3;
}

#l47 > li > *:first-child:before {
  counter-increment: x1;
  content: counter(x1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l47 > li:first-child > *:first-child:before {
  counter-increment: x1 0;
}

#l48 {
  padding-left: 0pt;
  counter-reset: x2 1;
}

#l48 > li > *:first-child:before {
  counter-increment: x2;
  content: counter(x1, decimal) "." counter(x2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l48 > li:first-child > *:first-child:before {
  counter-increment: x2 0;
}

li {
  display: block;
}

#l49 {
  padding-left: 0pt;
  counter-reset: y1 4;
}

#l49 > li > *:first-child:before {
  counter-increment: y1;
  content: counter(y1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l49 > li:first-child > *:first-child:before {
  counter-increment: y1 0;
}

#l50 {
  padding-left: 0pt;
  counter-reset: y2 1;
}

#l50 > li > *:first-child:before {
  counter-increment: y2;
  content: counter(y1, decimal) "." counter(y2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l50 > li:first-child > *:first-child:before {
  counter-increment: y2 0;
}

li {
  display: block;
}

#l51 {
  padding-left: 0pt;
  counter-reset: z1 5;
}

#l51 > li > *:first-child:before {
  counter-increment: z1;
  content: counter(z1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l51 > li:first-child > *:first-child:before {
  counter-increment: z1 0;
}

#l52 {
  padding-left: 0pt;
  counter-reset: z2 1;
}

#l52 > li > *:first-child:before {
  counter-increment: z2;
  content: counter(z1, decimal) "." counter(z2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l52 > li:first-child > *:first-child:before {
  counter-increment: z2 0;
}

li {
  display: block;
}

#l53 {
  padding-left: 0pt;
  counter-reset: c1 6;
}

#l53 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l53 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l54 {
  padding-left: 0pt;
  counter-reset: c2 1;
}

#l54 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l54 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}

li {
  display: block;
}

#l55 {
  padding-left: 0pt;
  counter-reset: d1 7;
}

#l55 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l55 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}

#l56 {
  padding-left: 0pt;
  counter-reset: d2 1;
}

#l56 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d1, decimal) "." counter(d2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l56 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}

li {
  display: block;
}

#l57 {
  padding-left: 0pt;
  counter-reset: e1 8;
}

#l57 > li > *:first-child:before {
  counter-increment: e1;
  content: counter(e1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l57 > li:first-child > *:first-child:before {
  counter-increment: e1 0;
}

#l58 {
  padding-left: 0pt;
  counter-reset: e2 1;
}

#l58 > li > *:first-child:before {
  counter-increment: e2;
  content: counter(e1, decimal) "." counter(e2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l58 > li:first-child > *:first-child:before {
  counter-increment: e2 0;
}

li {
  display: block;
}

#l59 {
  padding-left: 0pt;
  counter-reset: f1 2;
}

#l59 > li > *:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l59 > li:first-child > *:first-child:before {
  counter-increment: f1 0;
}

#l60 {
  padding-left: 0pt;
  counter-reset: f2 1;
}

#l60 > li > *:first-child:before {
  counter-increment: f2;
  content: counter(f1, decimal) "." counter(f2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l60 > li:first-child > *:first-child:before {
  counter-increment: f2 0;
}

#l61 {
  padding-left: 0pt;
  counter-reset: f3 1;
}

#l61 > li > *:first-child:before {
  counter-increment: f3;
  content: "(" counter(f3, decimal) ") ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l61 > li:first-child > *:first-child:before {
  counter-increment: f3 0;
}

li {
  display: block;
}

#l62 {
  padding-left: 0pt;
  counter-reset: g1 3;
}

#l62 > li > *:first-child:before {
  counter-increment: g1;
  content: counter(g1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l62 > li:first-child > *:first-child:before {
  counter-increment: g1 0;
}

#l63 {
  padding-left: 0pt;
  counter-reset: g2 1;
}

#l63 > li > *:first-child:before {
  counter-increment: g2;
  content: counter(g1, decimal) "." counter(g2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l63 > li:first-child > *:first-child:before {
  counter-increment: g2 0;
}

li {
  display: block;
}

#l64 {
  padding-left: 0pt;
  counter-reset: h1 1;
}

#l64 > li > *:first-child:before {
  counter-increment: h1;
  content: counter(h1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l64 > li:first-child > *:first-child:before {
  counter-increment: h1 0;
}

#l65 {
  padding-left: 0pt;
  counter-reset: h2 1;
}

#l65 > li > *:first-child:before {
  counter-increment: h2;
  content: counter(h1, decimal) "." counter(h2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l65 > li:first-child > *:first-child:before {
  counter-increment: h2 0;
}

li {
  display: block;
}

#l66 {
  padding-left: 0pt;
  counter-reset: i1 2;
}

#l66 > li > *:first-child:before {
  counter-increment: i1;
  content: counter(i1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l66 > li:first-child > *:first-child:before {
  counter-increment: i1 0;
}

#l67 {
  padding-left: 0pt;
  counter-reset: i2 1;
}

#l67 > li > *:first-child:before {
  counter-increment: i2;
  content: counter(i1, decimal) "." counter(i2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l67 > li:first-child > *:first-child:before {
  counter-increment: i2 0;
}

li {
  display: block;
}

#l68 {
  padding-left: 0pt;
  counter-reset: j1 3;
}

#l68 > li > *:first-child:before {
  counter-increment: j1;
  content: counter(j1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l68 > li:first-child > *:first-child:before {
  counter-increment: j1 0;
}

#l69 {
  padding-left: 0pt;
  counter-reset: j2 1;
}

#l69 > li > *:first-child:before {
  counter-increment: j2;
  content: counter(j1, decimal) "." counter(j2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l69 > li:first-child > *:first-child:before {
  counter-increment: j2 0;
}

li {
  display: block;
}

#l70 {
  padding-left: 0pt;
  counter-reset: k1 4;
}

#l70 > li > *:first-child:before {
  counter-increment: k1;
  content: counter(k1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l70 > li:first-child > *:first-child:before {
  counter-increment: k1 0;
}

#l71 {
  padding-left: 0pt;
  counter-reset: k2 1;
}

#l71 > li > *:first-child:before {
  counter-increment: k2;
  content: counter(k1, decimal) "." counter(k2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l71 > li:first-child > *:first-child:before {
  counter-increment: k2 0;
}

li {
  display: block;
}

#l72 {
  padding-left: 0pt;
  counter-reset: l1 5;
}

#l72 > li > *:first-child:before {
  counter-increment: l1;
  content: counter(l1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l72 > li:first-child > *:first-child:before {
  counter-increment: l1 0;
}

#l73 {
  padding-left: 0pt;
  counter-reset: l2 1;
}

#l73 > li > *:first-child:before {
  counter-increment: l2;
  content: counter(l1, decimal) "." counter(l2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l73 > li:first-child > *:first-child:before {
  counter-increment: l2 0;
}

li {
  display: block;
}

#l74 {
  padding-left: 0pt;
  counter-reset: m1 6;
}

#l74 > li > *:first-child:before {
  counter-increment: m1;
  content: counter(m1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l74 > li:first-child > *:first-child:before {
  counter-increment: m1 0;
}

#l75 {
  padding-left: 0pt;
  counter-reset: m2 1;
}

#l75 > li > *:first-child:before {
  counter-increment: m2;
  content: counter(m1, decimal) "." counter(m2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l75 > li:first-child > *:first-child:before {
  counter-increment: m2 0;
}

li {
  display: block;
}

#l76 {
  padding-left: 0pt;
  counter-reset: n1 7;
}

#l76 > li > *:first-child:before {
  counter-increment: n1;
  content: counter(n1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l76 > li:first-child > *:first-child:before {
  counter-increment: n1 0;
}

#l77 {
  padding-left: 0pt;
  counter-reset: n2 1;
}

#l77 > li > *:first-child:before {
  counter-increment: n2;
  content: counter(n1, decimal) "." counter(n2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l77 > li:first-child > *:first-child:before {
  counter-increment: n2 0;
}

li {
  display: block;
}

#l78 {
  padding-left: 0pt;
  counter-reset: o1 8;
}

#l78 > li > *:first-child:before {
  counter-increment: o1;
  content: counter(o1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l78 > li:first-child > *:first-child:before {
  counter-increment: o1 0;
}

#l79 {
  padding-left: 0pt;
  counter-reset: o2 1;
}

#l79 > li > *:first-child:before {
  counter-increment: o2;
  content: counter(o1, decimal) "." counter(o2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l79 > li:first-child > *:first-child:before {
  counter-increment: o2 0;
}

li {
  display: block;
}

#l80 {
  padding-left: 0pt;
  counter-reset: p1 9;
}

#l80 > li > *:first-child:before {
  counter-increment: p1;
  content: counter(p1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l80 > li:first-child > *:first-child:before {
  counter-increment: p1 0;
}

#l81 {
  padding-left: 0pt;
  counter-reset: p2 1;
}

#l81 > li > *:first-child:before {
  counter-increment: p2;
  content: counter(p1, decimal) "." counter(p2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l81 > li:first-child > *:first-child:before {
  counter-increment: p2 0;
}

li {
  display: block;
}

#l82 {
  padding-left: 0pt;
  counter-reset: q1 10;
}

#l82 > li > *:first-child:before {
  counter-increment: q1;
  content: counter(q1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l82 > li:first-child > *:first-child:before {
  counter-increment: q1 0;
}

#l83 {
  padding-left: 0pt;
  counter-reset: q2 1;
}

#l83 > li > *:first-child:before {
  counter-increment: q2;
  content: counter(q1, decimal) "." counter(q2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l83 > li:first-child > *:first-child:before {
  counter-increment: q2 0;
}

li {
  display: block;
}

#l84 {
  padding-left: 0pt;
  counter-reset: r1 11;
}

#l84 > li > *:first-child:before {
  counter-increment: r1;
  content: counter(r1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l84 > li:first-child > *:first-child:before {
  counter-increment: r1 0;
}

#l85 {
  padding-left: 0pt;
  counter-reset: r2 1;
}

#l85 > li > *:first-child:before {
  counter-increment: r2;
  content: counter(r1, decimal) "." counter(r2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l85 > li:first-child > *:first-child:before {
  counter-increment: r2 0;
}

li {
  display: block;
}

#l86 {
  padding-left: 0pt;
  counter-reset: s1 12;
}

#l86 > li > *:first-child:before {
  counter-increment: s1;
  content: counter(s1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l86 > li:first-child > *:first-child:before {
  counter-increment: s1 0;
}

#l87 {
  padding-left: 0pt;
  counter-reset: s2 1;
}

#l87 > li > *:first-child:before {
  counter-increment: s2;
  content: counter(s1, decimal) "." counter(s2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l87 > li:first-child > *:first-child:before {
  counter-increment: s2 0;
}

#l88 {
  padding-left: 0pt;
  counter-reset: s3 1;
}

#l88 > li > *:first-child:before {
  counter-increment: s3;
  content: counter(s3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l88 > li:first-child > *:first-child:before {
  counter-increment: s3 0;
}

li {
  display: block;
}

#l89 {
  padding-left: 0pt;
  counter-reset: t1 13;
}

#l89 > li > *:first-child:before {
  counter-increment: t1;
  content: counter(t1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l89 > li:first-child > *:first-child:before {
  counter-increment: t1 0;
}

#l90 {
  padding-left: 0pt;
  counter-reset: t2 1;
}

#l90 > li > *:first-child:before {
  counter-increment: t2;
  content: counter(t1, decimal) "." counter(t2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l90 > li:first-child > *:first-child:before {
  counter-increment: t2 0;
}

li {
  display: block;
}

#l91 {
  padding-left: 0pt;
}

#l91 > li > *:first-child:before {
  content: " ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l92 {
  padding-left: 0pt;
}

#l92 > li > *:first-child:before {
  content: "- ";
  color: black;
  font-family: "Calibri Light", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

li {
  display: block;
}

#l93 {
  padding-left: 0pt;
}

#l93 > li > *:first-child:before {
  content: "- ";
  color: black;
  font-family: Cambria, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

table,
tbody {
  width: 100%;
  vertical-align: top;
  overflow: visible;
}
</style>
