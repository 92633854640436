
import { defineComponent, PropType } from "vue";
import Error from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";

export default defineComponent({
  props: {
    loading: Boolean as PropType<boolean>,
    error: Boolean as PropType<boolean>,
  },
  components: {
    Loader,
    Error,
  },
});
