
import { defineComponent } from "vue";
import { texts } from "@/services/texts.service";

export default defineComponent({
  name: "Unauthorized",
  setup() {
    return { texts };
  },
});
