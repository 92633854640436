
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableColumn",
  props: {
    accent: Boolean,
    stroke: Boolean,
    selected: Boolean,
  },
});
