import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "-m-1 overflow-x-auto" }
const _hoisted_3 = { class: "inline-block min-w-full p-1 align-middle" }
const _hoisted_4 = { class: "overflow-hidden border-b border-gray-200 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }
const _hoisted_5 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_6 = { class: "bg-gray-50" }
const _hoisted_7 = { class: "divide-y divide-gray-200 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("thead", _hoisted_6, [
              _renderSlot(_ctx.$slots, "header")
            ]),
            _createElementVNode("tbody", _hoisted_7, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ])
    ])
  ]))
}