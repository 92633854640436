import { computed, reactive } from "vue";
import { IEvent } from "@/lib/client";
import { client } from "@/services/client.service";
import logger from "@/plugins/logger";

export const eventStore = reactive({
  events: [] as IEvent[],
  eventsLoading: false,
  eventsError: false,
  eventId: undefined as number | undefined,
  tenantAlias: undefined as string | undefined,
  event: computed((): IEvent | undefined => {
    return eventStore.events.find(
      (event) =>
        event.tenantAlias == eventStore.tenantAlias &&
        event.id === eventStore.eventId
    );
  }),
  eventNotFound: computed((): boolean => {
    return (
      !eventStore.eventsLoading &&
      !eventStore.eventsError &&
      !eventStore.event &&
      eventStore.eventId !== undefined
    );
  }),
  setEventId(tenantAlias: string, eventId: number) {
    eventStore.tenantAlias = tenantAlias;
    eventStore.eventId = eventId;
  },
  loadEvents() {
    eventStore.eventsLoading = true;
    client
      .getEvents()
      .then((events) => (eventStore.events = events))
      .catch((error) => {
        logger.error(error);
        eventStore.eventsError = true;
      })
      .finally(() => (eventStore.eventsLoading = false));
  },
});
