
import { defineComponent, PropType } from "vue";
import { computed } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { TextInputButtonProps, TextInputType } from "./TextInput.types";

export default defineComponent({
  props: {
    id: {
      type: String as PropType<string>,
    },
    name: {
      type: String as PropType<string>,
    },
    modelValue: {
      type: String as PropType<string>,
    },
    type: {
      type: String as PropType<TextInputType>,
      default: TextInputType.TEXT,
    },
    valid: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
    },
    button: {
      type: Object as PropType<TextInputButtonProps>,
    },
    min: {
      type: [Number, String] as PropType<number | string>,
    },
    max: {
      type: [Number, String] as PropType<number | string>,
    },
    placeholder: String as PropType<string>,
    icon: Function,
  },
  components: {
    ExclamationCircleIcon,
  },
  setup(props, { emit }) {
    const modelValueInternal = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value || undefined),
    });

    const displayInvalid = computed(() => props.valid === false);
    const inputClass = computed(() => [
      "block w-full sm:text-sm",
      {
        "border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100":
          props.disabled && !displayInvalid.value,
        "border-red-300 text-gray-500 placeholder-gray-300 bg-gray-100":
          props.disabled && displayInvalid.value,
        "border-gray-300 text-black placeholder-gray-500 focus:ring-darkgreen-500 focus:border-darkgreen-500":
          !props.disabled && !displayInvalid.value,
        "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
          !props.disabled && displayInvalid.value,
        "pl-10": props.icon,
        "rounded-none rounded-l-md": props.button,
        "rounded-md": !props.button,
      },
    ]);

    const onFocus = props.readonly
      ? (event: FocusEvent) => (event.target as HTMLInputElement).select()
      : () => undefined;

    const onButtonClick = () => emit("button:click", modelValueInternal.value);

    return {
      modelValueInternal,
      inputClass,
      onFocus,
      onButtonClick,
    };
  },
});
