export enum DropdownOrigin {
  TopLeft = "top-left",
  TopRight = "top-right",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
}

export interface DropdownProps {
  label: string;
  origin: DropdownOrigin;
  divider?: boolean;
  loading?: boolean;
}
