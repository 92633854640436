import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center", { grow: _ctx.fullWidth }])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          "data-testid": "spinner",
          class: _normalizeClass([
        'spinner-color-primary h-5 w-5 animate-spin rounded-full border-4',
        {
          'order-first mr-3': _ctx.origin === _ctx.DropdownOrigin.TopRight,
          'order-last ml-3': _ctx.origin === _ctx.DropdownOrigin.TopLeft,
        },
      ])
        }, null, 2))
      : _createCommentVNode("", true),
    _createVNode(_component_Menu, {
      as: "div",
      class: _normalizeClass(['relative inline-block text-left', { grow: _ctx.fullWidth }])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_MenuButton, {
            "data-testid": "dropdown-label",
            class: "inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-darkgreen-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              _createVNode(_component_ChevronDownIcon, {
                class: _normalizeClass([
              '-mr-1 h-5 w-5',
              {
                'ml-2': !_ctx.fullWidth,
                'ml-auto': _ctx.fullWidth,
              },
            ]),
                "aria-hidden": "true"
              }, null, 8, ["class"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-100",
          "enter-from-class": "transform opacity-0 scale-95",
          "enter-to-class": "transform opacity-100 scale-100",
          "leave-active-class": "transition ease-in duration-75",
          "leave-from-class": "transform opacity-100 scale-100",
          "leave-to-class": "transform opacity-0 scale-95"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MenuItems, {
              class: _normalizeClass([
            'absolute z-10 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-72',
            {
              'left-0 mt-2 origin-top-left': _ctx.origin === _ctx.DropdownOrigin.TopLeft,
            },
            {
              'right-0 mt-2 origin-top-right':
                _ctx.origin === _ctx.DropdownOrigin.TopRight,
            },
            {
              'left-0 bottom-full mb-2 origin-bottom-left':
                _ctx.origin === _ctx.DropdownOrigin.BottomLeft,
            },
            {
              'right-0 bottom-full mb-2 origin-bottom-right':
                _ctx.origin === _ctx.DropdownOrigin.BottomRight,
            },
            { 'divide-y divide-gray-100': _ctx.divider },
          ])
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["class"])
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["class"])
  ], 2))
}