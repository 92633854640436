
import { computed, defineComponent, PropType } from "vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import * as dateTime from "@/utils/dateTime";

export default defineComponent({
  props: {
    id: {
      type: String as PropType<string>,
    },
    name: {
      type: String as PropType<string>,
    },
    modelValue: {
      type: Date as PropType<Date>,
    },
    valid: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
    },
    min: {
      type: Date as PropType<Date>,
    },
    max: {
      type: Date as PropType<Date>,
    },
  },
  components: {
    TextInput,
  },
  setup(props, { emit }) {
    const modelValueInternal = computed<string>({
      get: () => {
        const val = props.modelValue;
        return val ? dateTime.toDtoString(val) : "";
      },
      set: (value) => {
        const date = dateTime.toDate(value);
        emit("update:modelValue", !isNaN(date.getTime()) ? date : undefined);
      },
    });

    if (props.max && props.min && props.max?.getTime() < props.min?.getTime()) {
      throw Error("max value can't be smaller than min value");
    }

    const minDateString = computed(() =>
      props.min ? dateTime.toDtoString(props.min) : undefined
    );
    const maxDateString = computed(() =>
      props.max ? dateTime.toDtoString(props.max) : undefined
    );

    return {
      modelValueInternal,
      minDateString,
      maxDateString,
    };
  },
});
