
import { computed, defineComponent, PropType } from "vue";
import {
  ButtonElementType,
  ButtonSize,
  ButtonType,
} from "@/components/common/button/Button.types";

export default defineComponent({
  name: "Button",
  props: {
    size: {
      type: String as PropType<ButtonSize>,
      required: false,
      default: ButtonSize.md,
    },
    type: {
      type: String as PropType<ButtonType>,
      required: false,
      default: ButtonType.primary,
    },
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    buttonElementType: {
      type: String as PropType<ButtonElementType>,
    },
    leadingIcon: {
      type: Function,
    },
    centerIcon: {
      type: Function,
    },
    trailingIcon: {
      type: Function,
    },
    testId: {
      type: String,
    },
  },
  setup(props) {
    const buttonDisabled = computed<boolean>(
      () => props.disabled || props.loading
    );

    const spinnerClasses = computed(() => [
      "animate-spin h-5 w-5 mr-3 border-4 rounded-full",
      {
        "spinner-color-primary": props.type === ButtonType.primary,
        "spinner-color-secondary": props.type === ButtonType.secondary,
        "spinner-color-white": props.type === ButtonType.white,
        "spinner-color-danger": props.type === ButtonType.danger,
        "spinner-color-warning": props.type === ButtonType.warning,
        "spinner-color-black": props.type === ButtonType.black,
      },
    ]);

    const iconSizeClasses = computed(() => [
      { "h-4 w-4": props.size === ButtonSize.xs },
      { "h-4 w-4": props.size === ButtonSize.sm },
      { "h-5 w-5": props.size === ButtonSize.md },
      { "h-5 w-5": props.size === ButtonSize.lg },
      { "h-6 w-6": props.size === ButtonSize.xl },
    ]);

    const classes = computed(() => [
      "inline-flex items-center justify-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
      {
        "text-xs px-2.5 py-1.5 rounded": props.size === ButtonSize.xs,
        "text-sm px-3 py-2 rounded-md leading-4": props.size === ButtonSize.sm,
        "text-sm px-4 py-2 rounded-md": props.size === ButtonSize.md,
        "text-base px-4 py-2 rounded-md": props.size === ButtonSize.lg,
        "text-lg px-6 py-3 rounded-md": props.size === ButtonSize.xl,
      },
      {
        "text-white bg-darkgreen-500 shadow-sm border-transparent":
          props.type === ButtonType.primary,
        "text-darkgreen-500 bg-white shadow-sm border-gray-300":
          props.type === ButtonType.secondary,
        "text-gray-700 bg-white shadow-sm border-gray-300":
          props.type === ButtonType.white,
        "text-white bg-red-600 shadow-sm border-transparent":
          props.type === ButtonType.danger,
        "text-yellow-700 bg-yellow-100 shadow-sm border-transparent":
          props.type === ButtonType.warning,
        "text-gray-100 bg-black shadow-sm border-transparent":
          props.type === ButtonType.black,
      },
      !buttonDisabled.value && {
        "hover:bg-darkgreen-600 focus:ring-darkgreen-500 ":
          props.type === ButtonType.primary,
        "hover:bg-darkgreen-100 focus:ring-darkgreen-500 ":
          props.type === ButtonType.secondary,
        "hover:bg-gray-50 focus:ring-darkgreen-500":
          props.type === ButtonType.white,
        "hover:bg-red-700 focus:ring-red-500": props.type === ButtonType.danger,
        "hover:bg-yellow-200 focus:ring-darkgreen-500":
          props.type === ButtonType.warning,
        "hover:bg-gray-900 focus:ring-darkgreen-500":
          props.type === ButtonType.black,
      },
      {
        "opacity-50 cursor-not-allowed": buttonDisabled.value,
      },
    ]);

    return {
      classes,
      spinnerClasses,
      buttonDisabled,
      iconSizeClasses,
    };
  },
});
