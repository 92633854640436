
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { computed, defineComponent, PropType } from "vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import ModalContent from "@/components/common/modal/ModalContent.vue";

export default defineComponent({
  name: "modal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ModalContent,
  },
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
    type: {
      type: String as PropType<ModalType>,
      default: ModalType.primary,
    },
    icon: {
      type: Function,
    },
    disabled: Boolean,
    title: String,
    description: String,
    centered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const visibleInternal = computed({
      get: () => props.visible,
      set: (value) => !props.disabled && emit("update:visible", value),
    });

    const iconOuterClass = computed(() => [
      "mx-auto flex items-center justify-center rounded-full h-12 w-12",
      {
        "bg-red-100": props.type === ModalType.danger,
        "bg-yellow-100": props.type === ModalType.warning,
      },
    ]);
    const iconInnerClass = computed(() => [
      {
        "text-freshgreen-600": props.type === ModalType.primary,
        "h-8 w-8 text-red-600": props.type === ModalType.danger,
        "h-8 w-8 text-yellow-600": props.type === ModalType.warning,
      },
    ]);

    return {
      visibleInternal,
      iconOuterClass,
      iconInnerClass,
      ModalType,
    };
  },
});
