import {
  Answer,
  IQuestion,
  IQuestionnaire,
  Question,
  QuestionType,
  Questionnaire,
} from "@/lib/client";

export class StagedQuestionnaire {
  id?: number | undefined;
  name: string;
  questions: StagedQuestion[];

  constructor(questionnaire: Questionnaire) {
    this.id = questionnaire.id;
    this.name = questionnaire.name;
    this.questions = questionnaire.questions?.map(
      (question) => new StagedQuestion(question)
    );
  }

  toQuestionnaire(): Questionnaire {
    const data: IQuestionnaire = {
      id: this.id,
      name: this.name,
      questions: this.questions
        ?.filter((stagedQuestion) => !stagedQuestion.toBeDeleted)
        .map((retainedStagedQuestion) => retainedStagedQuestion.toQuestion()),
    };

    return new Questionnaire(data);
  }
}

export class StagedQuestion {
  id?: number | undefined;
  name: string;
  answers: StagedAnswer[];
  type!: QuestionType;

  toBeDeleted: boolean;

  constructor(question: Question) {
    this.id = question.id;
    this.name = question.name;
    this.answers = question.answers?.map((answer) => new StagedAnswer(answer));
    this.type = question.type;
    this.toBeDeleted = false;
  }

  toQuestion(): Question {
    const data: IQuestion = {
      id: this.id,
      type: this.type,
      name: this.name,
      answers: this.answers
        ?.filter((stagedAnswer) => !stagedAnswer.toBeDeleted)
        .map((retainedStagedAnswer) => new Answer(retainedStagedAnswer)),
    };

    return new Question(data);
  }
}

export class StagedAnswer {
  id?: number | undefined;
  name: string;
  toBeDeleted: boolean;

  constructor(answer: Answer) {
    this.id = answer.id;
    this.name = answer.name;
    this.toBeDeleted = false;
  }
}
