
import { defineComponent, PropType } from "vue";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import RouterNavigationSelect from "@/components/router/router-navigation-select/RouterNavigationSelect.vue";
import { RouterNavigationTab } from "./RouterNavigationTabs.types";

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<RouterNavigationTab[]>,
      required: true,
    },
  },
  name: "EventNavigationTabs",
  components: {
    RouterNavigationSelect,
    NavigationTabContainer,
    NavigationTab,
    RouterLinkAuth,
  },
});
