import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryNavigationItem = _resolveComponent("PrimaryNavigationItem")!
  const _component_PrimaryNavigationExpander = _resolveComponent("PrimaryNavigationExpander")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_PrimaryNavigationExpander, {
        key: 0,
        expanded: _ctx.expanded,
        "onUpdate:expanded": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expanded) = $event)),
        icon: _ctx.icon,
        name: _ctx.name,
        accent: _ctx.matchedRoute
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
            return (_openBlock(), _createBlock(_component_PrimaryNavigationItem, {
              key: index,
              to: route.to,
              name: route.name,
              indent: "",
              light: ""
            }, null, 8, ["to", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["expanded", "icon", "name", "accent"]))
    : _createCommentVNode("", true)
}