export enum FileType {
  XLSX = "XLSX",
  PNG = "PNG",
  JPEG = "JPEG",
}
export enum FileSize {
  MB1 = "1MB",
  MB10 = "10MB",
}

export const FileType_To_Accept = new Map([
  [
    FileType.XLSX,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  [FileType.PNG, "image/png"],
  [FileType.JPEG, "image/jpeg"],
]);

export const FileSize_To_Bytes = new Map([
  [FileSize.MB1, 1 * 1024 * 1024],
  [FileSize.MB10, 10 * 1024 * 1024],
]);
