import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationTabVertical = _resolveComponent("NavigationTabVertical")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavigationTabVertical, {
      name: _ctx.name,
      icon: _ctx.icon,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandedInternal = !_ctx.expandedInternal)),
      expandedArrow: _ctx.expandedInternal,
      accent: _ctx.accent,
      dark: ""
    }, null, 8, ["name", "icon", "expandedArrow", "accent"]),
    _createVNode(_component_TransitionRoot, {
      class: "space-y-1",
      enter: "transition ease-in-out duration-100 transform",
      "enter-from": "opacity-0 -translate-y-2",
      "enter-to": "opacity-100 translate-y-0",
      leave: "transition ease-in-out duration-75 transform",
      "leave-from": "opacity-100 translate-y-0",
      "leave-to": "opacity-0 -translate-y-2",
      show: _ctx.expandedInternal
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["show"])
  ], 64))
}