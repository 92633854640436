import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-5 sm:flex sm:items-baseline sm:justify-between" }
const _hoisted_2 = { class: "sm:w-0 sm:flex-1" }
const _hoisted_3 = {
  "data-testid": "title",
  class: "text-lg font-medium leading-6 text-gray-900"
}
const _hoisted_4 = {
  key: 0,
  "data-testid": "description",
  class: "mt-2 max-w-4xl text-sm text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionDivider = _resolveComponent("SectionDivider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.divider)
      ? (_openBlock(), _createBlock(_component_SectionDivider, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}