import { format } from "date-fns";
import { Culture } from "@/enums";

const toDtoString = (date: Date, timezone = false) => {
  return format(date, `yyyy-MM-dd'T'HH:mm${timezone ? "xxx" : ""}`);
};

const toFormattedString = (date: Date) => {
  return format(date, "dd-MM-yyyy, HH:mm");
};

const toDate = (formatted: string) => {
  // returns 'Invalid Date' if string cannot be parsed
  // This can be catched by 'isNaN(result)'
  return new Date(Date.parse(formatted));
};

const compare = (a?: Date, b?: Date, asc = true) => {
  const compare = asc ? -1 : 1;

  if (a === b) return 0;
  else if (a === undefined) return compare;
  else if (b === undefined) return -compare;
  else return -compare * (a.getTime() - b.getTime());
};

/**
 * Formats a date-range in the most concise way possible, according to the current culture.
 * E.g. "May 1-2, 2020" or "May 30 - June 1, 2020"
 * @param startDate
 * @param endDate
 * @param culture
 */
const formatEventDateRange = (
  startDate: Date,
  endDate: Date,
  culture: Culture
) => {
  if (!startDate) {
    return "";
  }

  const dateTimeFormat = new Intl.DateTimeFormat(culture, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  if (startDate && !endDate) {
    return dateTimeFormat.format(startDate);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dateRangeString = dateTimeFormat.formatRange(startDate, endDate);

  function addSpacesAroundDash(str: string) {
    // Instead of '1-2', show '1 - 2' (with special dash character!)
    // @TODO: lookbehind regex not supported by Safari, see https://caniuse.com/js-regexp-lookbehind
    return str;
    // return str.replace(/(?<=\d)–(?=\d)/, " – ");
  }

  return addSpacesAroundDash(dateRangeString);
};

export {
  toDtoString,
  toFormattedString,
  toDate,
  compare,
  formatEventDateRange,
};
