
import { defineComponent, PropType } from "vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { IconAlignment } from "./RouterLinkAuthWithIcon.types";

export default defineComponent({
  name: "RouterLinkWithIcon",
  components: {
    RouterLinkAuth,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Function,
      required: true,
    },
    iconAlignment: {
      type: String as PropType<IconAlignment>,
      required: false,
      default: IconAlignment.Left,
    },
  },
  setup() {
    return { IconAlignment };
  },
});
