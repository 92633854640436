
import { defineComponent, PropType } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

import { ChevronDownIcon } from "@heroicons/vue/solid";
import { DropdownOrigin } from "./Dropdown.types";

export default defineComponent({
  name: "Dropdown",
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    origin: {
      type: String as PropType<DropdownOrigin>,
      required: false,
      default: DropdownOrigin.TopLeft,
    },
    divider: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  components: {
    Menu,
    MenuButton,
    MenuItems,
    ChevronDownIcon,
  },
  setup() {
    return {
      DropdownOrigin,
    };
  },
});
