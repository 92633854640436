import {
  ApplicationInsights,
  IExceptionTelemetry,
  SeverityLevel,
  Snippet,
} from "@microsoft/applicationinsights-web";
import { App } from "vue";
import { Router } from "vue-router";
import { Store } from "vuex";
import appConfig from "@/appConfig";

const connectionString = appConfig.insights.connectionString;

const insightsConfig: Snippet = { config: { connectionString } };
const development = process.env.NODE_ENV === "development";

export interface ILogger {
  error: (error: Error, properties?: Record<string, unknown>) => void;
  warn: (error: Error, properties?: Record<string, unknown>) => void;
  log: (message: string, properties?: Record<string, unknown>) => void;
}

export class InsightsLogger implements ILogger {
  appInsights: ApplicationInsights;
  constructor(insightsConfig: Snippet, private development: boolean = false) {
    this.appInsights = new ApplicationInsights(insightsConfig);
    this.appInsights.loadAppInsights();
  }

  error(error: Error, properties?: Record<string, unknown>): void {
    const exception: IExceptionTelemetry = {
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties,
    };
    if (this.development) {
      console.error(error);
    } else {
      this.appInsights.trackException(exception);
      console.error(error);
    }
  }

  warn(error: Error, properties?: Record<string, unknown>): void {
    const exception: IExceptionTelemetry = {
      exception: error,
      severityLevel: SeverityLevel.Warning,
      properties,
    };
    if (this.development) {
      console.warn(error);
    } else {
      this.appInsights.trackException(exception);
    }
  }

  log(message: string, properties?: Record<string, unknown>): void {
    const exception: IExceptionTelemetry = {
      severityLevel: SeverityLevel.Information,
      properties: {
        message,
        ...properties,
      },
    };
    if (this.development) {
      console.log(message);
    } else {
      this.appInsights.trackException(exception);
    }
  }
}

const logger = new InsightsLogger(insightsConfig, development);
export default logger;

export class InsightsLoggerPlugin {
  constructor(private router: Router, private store: Store<unknown>) {}

  install(app: App): void {
    app.config.errorHandler = (err, instance, info) => {
      logger.error(err as Error, {
        info,
        state: this.store.state,
        route: this.router.currentRoute.value.fullPath,
      });
    };

    app.config.warnHandler = (msg, instance, info) => {
      logger.warn(new Error(msg), {
        info,
        state: this.store.state,
        route: this.router.currentRoute.value.fullPath,
      });
    };

    // Errors in Promises are not catched by handler and need to be handled separately
    window.addEventListener("unhandledrejection", (event) => {
      event.promise.catch((err) => {
        logger.error(err as Error, {
          info: event.reason,
          state: this.store.state,
          route: this.router.currentRoute.value.fullPath,
        });
      });
    });

    app.provide("logger", logger);
  }
}
