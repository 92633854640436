import axios from "axios";
import appConfig from "@/appConfig";

const apiBaseURL = appConfig.api.baseUrl;

export default class HttpClient {
  static instance = axios.create({
    baseURL: apiBaseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static setCulture(culture: string): void {
    // see https://github.com/axios/axios/issues/4193
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    HttpClient.instance.defaults.headers["Accept-Language"] = culture;
  }

  static setAuth(token: string | null): void {
    if (token != null) {
      // see https://github.com/axios/axios/issues/4193
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      HttpClient.instance.defaults.headers[
        "Authorization" as string
      ] = `Bearer ${token}`;
    } else {
      // see https://github.com/axios/axios/issues/4193
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete HttpClient.instance.defaults.headers["Authorization"];
    }
  }
}
