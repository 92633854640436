import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createBlock(_component_MenuItem, { disabled: _ctx.disabled }, {
    default: _withCtx(({ active }) => [
      _createElementVNode("a", {
        "data-testid": "dropdown-item",
        class: _normalizeClass([
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        _ctx.icon
          ? 'group flex items-center px-4 py-2 text-sm'
          : 'block px-4 py-2 text-sm',
        _ctx.disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer',
      ])
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
              key: 0,
              class: "mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500",
              "aria-hidden": "true"
            }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
      ], 2)
    ]),
    _: 1
  }, 8, ["disabled"]))
}