
import { defineComponent, PropType, ref, watch } from "vue";
import PrimaryNavigationItem from "./PrimaryNavigationItem.vue";
import PrimaryNavigationExpander from "./PrimaryNavigationExpander.vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import { userCanGoTo } from "@/router/helpers/userCanGoTo";
import { PrimaryNavigationTreeRoute } from "./PrimaryNavigationTree.types";

export default defineComponent({
  components: {
    PrimaryNavigationItem,
    PrimaryNavigationExpander,
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    routes: {
      type: Array as PropType<Array<PrimaryNavigationTreeRoute>>,
      default: [] as Array<PrimaryNavigationTreeRoute>,
    },
    rootRoute: {
      type: Object as PropType<RouteLocationNormalized>,
      required: true,
    },
  },
  setup(props) {
    const expanded = ref(false);

    const router = useRouter();
    const visible = props.routes.every((route) =>
      userCanGoTo(router.resolve(route.to))
    );

    const route = useRoute();
    const rootRouteIsMatched = ref<boolean>(false);
    watch(
      route,
      (newValue) => {
        const isTreeRoute = newValue.matched.some(
          (matched) => props.rootRoute.name === matched.name
        );

        if (isTreeRoute) {
          expanded.value = true;
          rootRouteIsMatched.value = true;
        } else {
          expanded.value = false;
          rootRouteIsMatched.value = false;
        }
      },
      { immediate: true }
    );

    return { expanded, matchedRoute: rootRouteIsMatched, visible };
  },
});
