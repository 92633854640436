import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/style.css";

import appConfig from "@/appConfig";
import { InsightsLoggerPlugin } from "@/plugins/logger";
import { setupAccessTokenAuth, setupAuth } from "@/plugins/authentication";
import notifications from "@kyvg/vue3-notification";
import { testIdDirective, TestingPlugin } from "@/plugins/testing";

const loggerPlugin = new InsightsLoggerPlugin(router, store);

async function init() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const testing = window.Cypress || window.localStorage.getItem("cypress");

  const auth = testing
    ? await setupAccessTokenAuth()
    : await setupAuth({
        clientId: appConfig.auth0.clientId,
        domain: appConfig.auth0.domain,
        audience: appConfig.auth0.audience,
        redirectUri: window.location.origin,
        onRedirectCallback: () => {
          // Redirect handled by router
        },
      });

  createApp(App)
    .directive("test-id", testIdDirective)
    .use(new TestingPlugin())
    .use(store)
    .use(router)
    .use(loggerPlugin)
    .use(notifications)
    .use(auth)
    .mount("#app");
}

init();
