import { notify } from "@kyvg/vue3-notification";

export const notifySuccess = (message: { title: string; text: string }) =>
  notify({
    title: message.title,
    text: message.text,
    type: "success",
  });
export const notifyError = (message: { title: string; text: string }) =>
  notify({
    title: message.title,
    text: message.text,
    type: "error",
  });
