
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import Button from "@/components/common/button/Button.vue";
import { ButtonType } from "@/components/common/button/Button.types";

export default defineComponent({
  props: {
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<ButtonType>,
    },
  },
  components: {
    RouterLinkAuth,
    Button,
  },
});
