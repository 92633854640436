
import { Culture } from "@/enums";
import { RootState } from "@/store";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Dropdown from "@/components/common/dropdown/Dropdown.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import dictionary from "@/dictionary";

export default defineComponent({
  components: {
    Dropdown,
    DropdownItem,
  },
  setup() {
    const store = useStore<RootState>();
    const router = useRouter();
    const active = computed<Culture>(
      () => store.getters["cultureStore/active"]
    );

    const available = computed<Culture[]>(
      () => store.getters["cultureStore/available"]
    );

    const setActive = (newActiveCulture: string) => {
      store.dispatch("cultureStore/setActive", newActiveCulture).then(() => {
        router.go(0);
      });
    };

    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    return { active, available, setActive, texts, DropdownOrigin };
  },
});
