import HttpClient from "@/utils/httpClient";
import { User } from "@auth0/auth0-spa-js";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { RootState } from "..";

interface AuthStoreState {
  loading: boolean;
  error: unknown;
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

const state: AuthStoreState = {
  loading: true,
  error: null,
  isAuthenticated: false,
  user: null,
  token: null,
};

interface AuthStoreGetters extends GetterTree<AuthStoreState, RootState> {
  loading: (state: AuthStoreState) => boolean;
  error: (state: AuthStoreState) => unknown;
  isAuthenticated: (state: AuthStoreState) => boolean;
  user: (state: AuthStoreState) => User | null;
  token: (state: AuthStoreState) => string | null;
}

const getters: AuthStoreGetters = {
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
};

interface ILoginResult {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

interface AuthStoreMutations extends MutationTree<AuthStoreState> {
  setLoading(state: AuthStoreState, loading: boolean): void;
  setError(state: AuthStoreState, error: unknown): void;
  setLoginResult(state: AuthStoreState, result: ILoginResult): void;
}

const mutations: AuthStoreMutations = {
  setLoading(state, loading: boolean) {
    state.loading = loading;
  },
  setError(state, error: unknown): void {
    state.error = error;
  },
  setLoginResult(state, result: ILoginResult) {
    state.isAuthenticated = result.isAuthenticated;
    state.user = result.user;
    state.token = result.token;
  },
};

interface AuthStoreActions extends ActionTree<AuthStoreState, RootState> {
  login(
    context: ActionContext<AuthStoreState, RootState>,
    result: ILoginResult
  ): void;
}

const actions: AuthStoreActions = {
  async login(
    context: ActionContext<AuthStoreState, RootState>,
    result: ILoginResult
  ) {
    context.commit("setLoginResult", result);
    HttpClient.setAuth(state.token);
  },
};

interface AuthStore extends Module<AuthStoreState, RootState> {
  namespaced: true;
  state?: AuthStoreState;
  getters: AuthStoreGetters;
  mutations: AuthStoreMutations;
  actions: AuthStoreActions;
}

const authModule: AuthStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { AuthStore, AuthStoreState, ILoginResult };
export default authModule;
