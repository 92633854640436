
import { Component, defineComponent, PropType } from "vue";
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { PageHeadingSize } from "@/components/common/page-heading/PageHeading.types";

export interface PageHeadingProps {
  title: string;
  icon?: Component;
  badgeLabel?: string;
  badgeType?: BadgeType;
}

export default defineComponent({
  name: "PageHeading",
  components: {
    Badge,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    badgeLabel: {
      type: String as PropType<string>,
    },
    badgeType: {
      type: String as PropType<BadgeType>,
    },
    size: {
      type: String as PropType<PageHeadingSize>,
      default: PageHeadingSize.md,
    },
  },

  setup() {
    return { PageHeadingSize };
  },
});
