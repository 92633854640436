
import { defineComponent, PropType } from "vue";

import DateTime from "./DateTime.vue";
import { useField } from "vee-validate";

export default defineComponent({
  props: {
    formFieldId: {
      type: String as PropType<string>,
      required: true,
    },
    label: String as PropType<string>,
    disabled: Boolean as PropType<boolean>,
    fullWidth: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    min: {
      type: Date as PropType<Date>,
    },
    max: {
      type: Date as PropType<Date>,
    },
  },
  components: {
    DateTime,
  },
  setup(props) {
    const { errorMessage, value } = useField<Date | undefined>(
      props.formFieldId
    );
    return {
      errorMessage,
      value,
    };
  },
});
