const downloadFileBySimulatingClickOnTemporaryAnchorTag = (
  fileContent: Blob,
  filename: string
): void => {
  // create fake anchor that points to fileContent
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(fileContent);
  link.download = filename;

  // add anchor to document
  document.body.append(link);

  // click the element so browser will download it
  link.click();

  // cleanup
  link.remove();
};

export { downloadFileBySimulatingClickOnTemporaryAnchorTag };
