import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { "data-testid": "navigation-tab-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    class: _normalizeClass([
      _ctx.current
        ? 'border-freshgreen-500 text-darkgreen-600'
        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
      'group inline-flex items-center border-b-2 px-1 pb-4 text-sm font-medium',
    ]),
    "data-testid": "navigation-tab"
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
          key: 0,
          class: _normalizeClass([
        _ctx.current
          ? 'text-darkgreen-500'
          : 'text-gray-400 group-hover:text-gray-500',
        '-ml-0.5 mr-2 h-5 w-5',
      ]),
          "aria-hidden": "true"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
    (_ctx.count)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([
        _ctx.current
          ? 'bg-darkgreen-100 text-darkgreen-600'
          : 'bg-gray-100 text-gray-900',
        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
      ])
        }, _toDisplayString(_ctx.count), 3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}