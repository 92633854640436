import createAuth0Client, { Auth0Client, User } from "@auth0/auth0-spa-js";
import { App, computed, Plugin } from "vue";

import store from "@/store";
import { ILoginResult } from "@/store/modules/auth.store";
import appConfig from "@/appConfig";
import { authorization } from "@/store/modules/permissions.store";
import { parseJwt } from "@/utils/jwt";

let client: Auth0Client;

const loading = computed({
  get: () => store.getters["authStore/loading"],
  set: (isLoading) => store.commit("authStore/setLoading", isLoading),
});

interface IAuthPlugin {
  logout: () => void;
}

interface IAppState {
  targetUrl: string;
}

const plugin: IAuthPlugin = {
  logout: () =>
    client.logout({
      returnTo: appConfig.auth0.logoutUrl,
    }),
};

const login = (auth0CallbackUrl: string): Promise<void> => {
  const appState: IAppState = { targetUrl: auth0CallbackUrl };
  return client.loginWithRedirect({ appState });
};

interface AuthOptions {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  onRedirectCallback(appState?: IAppState): void;
}

const namespace = "http://schemas.summit.nl/identity/claims";

const setupAuth = async (options: AuthOptions): Promise<Plugin> => {
  client = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    redirect_uri: options.redirectUri,
    audience: options.audience,
  });

  try {
    // If the user is returning to the app after authentication
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      // handle the redirect and retrieve tokens
      await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback();
    }

    // Initialize our internal authentication state
    const loginResult: ILoginResult = {
      isAuthenticated: false,
      user: null,
      token: null,
    };

    // todo: Fix bug on firefox that returns false for isAuthenticated the first time (causing appState to get lost).
    loginResult.isAuthenticated = await client.isAuthenticated();

    if (loginResult.isAuthenticated) {
      loginResult.user = (await client.getUser()) || null;
      loginResult.token = await client.getTokenSilently();

      const claims = await client.getIdTokenClaims();

      if (claims) {
        authorization.permissions = claims[`${namespace}/permissions`];
        authorization.termsAcceptedOn = claims[`${namespace}/termsAcceptedOn`];
      }
    }

    // The await statement below is there because we need to wait for the login action to be finished!
    await store.dispatch("authStore/login", loginResult);
  } catch (e) {
    store.commit("authStore/setError", e);
  } finally {
    loading.value = false;
  }

  return {
    install(app: App): void {
      app.provide("Auth", plugin);
    },
  };
};

const setupAccessTokenAuth = async (): Promise<Plugin> => {
  const accessToken = window.localStorage.getItem("access_token");
  const idToken = window.localStorage.getItem("id_token");

  if (accessToken && idToken) {
    const accessTokenData = parseJwt(accessToken);
    const idTokenData = parseJwt(idToken);

    const loginResult: ILoginResult = {
      token: accessToken,
      isAuthenticated: true,
      user: {
        name: idTokenData.name,
        email: idTokenData.email,
        picture: idTokenData.picture,
      },
    };
    authorization.permissions = accessTokenData[`${namespace}/permissions`];
    authorization.termsAcceptedOn = idTokenData[`${namespace}/termsAcceptedOn`];

    await store.dispatch("authStore/login", loginResult);
  }
  loading.value = false;

  return {
    install(app: App): void {
      app.provide("Auth", plugin);
    },
  };
};

export { setupAuth, setupAccessTokenAuth, login, IAuthPlugin, User, IAppState };
