import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationTabVertical = _resolveComponent("NavigationTabVertical")!
  const _component_RouterLinkAuth = _resolveComponent("RouterLinkAuth")!

  return (_openBlock(), _createBlock(_component_RouterLinkAuth, {
    to: _ctx.to,
    custom: ""
  }, {
    default: _withCtx(({ href, navigate, isActive }) => [
      _createVNode(_component_NavigationTabVertical, {
        name: _ctx.name,
        icon: _ctx.icon,
        href: href,
        current: isActive,
        accent: isActive,
        onClick: navigate,
        indent: _ctx.indent,
        light: _ctx.light,
        dark: ""
      }, null, 8, ["name", "icon", "href", "current", "accent", "onClick", "indent", "light"])
    ]),
    _: 1
  }, 8, ["to"]))
}