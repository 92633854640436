export enum RouteNames {
  STATUS = "status",
  EXHIBITOR_ABOUT = "about",
  EXHIBITOR_QUESTIONNAIRES = "questionnaires",
  EXHIBITOR_LEAD_APP_CONFIGURATION = "lead-app-configuration",
  EVENTS = "events",
  EVENT = "event",
  LEAD_DATA = "lead-data",
  NOT_FOUND = "not-found",
  UNAUTHORIZED = "unauthorized",
  QUESTIONNAIRE_EDITOR = "QUESTIONNAIRE_EDITOR",
  QUESTIONNAIRE_CREATE = "QUESTIONNAIRE_CREATE",
  TERMS = "TERMS",
}

export enum AdminRouteNames {
  MANAGE_ORGANIZERS = "admin-manage-organizers",
  MANAGE_ACCOUNT = "admin-manage-account",
  CREATE_TEST_EVENT = "admin-create-test-event",
  MAIL_TEMPLATES = "admin-mail-templates",

  SETTINGS = "admin-settings",
}

export enum OrganizerRouteNames {
  ORGANIZER_DASHBOARD = "organizer-dashboard",
  ORGANIZER_EVENTS = "organizer-events",
  ORGANIZER_EVENT = "organizer-event",
  ORGANIZER_EVENT_EXHIBITORS = "organizer-event-exhibitors",
  ORGANIZER_EVENT_SETTINGS = "organizer-event-settings",
  ORGANIZER_EVENT_EXPORTS = "organizer-event-exports",
  ORGANIZER_EXHIBITORS = "organizer-exhibitors",
  ORGANIZER_EXHIBITOR = "organizer-exhibitor",
  ORGANIZER_EXHIBITOR_EDIT = "organizer-exhibitor-edit",
  ORGANIZER_EXHIBITOR_CREATE = "organizer-exhibitors-create",
  ORGANIZER_EXHIBITORS_IMPORT = "organizer-exhibitors-import",
  ORGANIZER_EXHIBITOR_EVENTS = "organizer-exhibitor-events",
  ORGANIZER_EXHIBITOR_ACCOUNTS = "organizer-exhibitor-accounts",
}
