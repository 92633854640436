import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_router_link, _mergeProps({
        key: 0,
        to: _ctx.to
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ href: _ctx.href, navigate: _ctx.navigate, isActive: _ctx.isActive })))
        ]),
        _: 3
      }, 16, ["to"]))
    : _createCommentVNode("", true)
}