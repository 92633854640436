
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    value: [String, Object],
    modelValue: {
      type: [Array, Boolean] as PropType<unknown[] | boolean>,
      required: true,
    },
    valid: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const checked = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const displayInvalid = computed(() => props.valid === false);
    const checkboxClasses = computed(() => [
      "h-4 w-4 rounded",
      {
        "border-gray-300 text-gray-500 bg-gray-100":
          props.disabled && !displayInvalid.value,
        "border-red-300 text-gray-500 bg-gray-100":
          props.disabled && displayInvalid.value,
        "focus:ring-darkgreen-500 text-darkgreen-500":
          !props.disabled && !displayInvalid.value,
        "border-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500":
          !props.disabled && displayInvalid.value,
      },
    ]);

    return {
      checked,
      checkboxClasses,
    };
  },
});
