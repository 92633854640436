import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Error = _resolveComponent("Error")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Loader, {
        key: 0,
        "data-testid": "loading",
        class: "mt-4"
      }))
    : (_ctx.error)
      ? (_openBlock(), _createBlock(_component_Error, {
          key: 1,
          "data-testid": "error"
        }))
      : (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 2,
          "data-testid": "main"
        }, _ctx.$attrs), [
          _renderSlot(_ctx.$slots, "default")
        ], 16))
}