import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "checkbox",
    class: _normalizeClass(_ctx.checkboxClasses),
    disabled: _ctx.disabled,
    value: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event))
  }, null, 10, _hoisted_1)), [
    [_vModelCheckbox, _ctx.checked]
  ])
}