import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center md:py-24" }
const _hoisted_2 = { class: "mt-1 text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight" }
const _hoisted_3 = { class: "text-l mx-auto mt-5 max-w-xl text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.texts.notFound.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.texts.notFound.description), 1)
  ]))
}