import { App, Directive, DirectiveBinding } from "vue";
import { testIdAttribute, testIds } from "@/utils/testing";

export const testIdDirective: Directive = {
  beforeMount(el: Element, binding: DirectiveBinding) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!window.Cypress) {
      return;
    }

    const testId = binding.value as unknown as string;
    el.setAttribute(testIdAttribute, testId);
  },
};

export class TestingPlugin {
  install(app: App) {
    app.config.globalProperties.$testIds = testIds;
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $testIds: typeof testIds;
  }
}
