
import { defineComponent, PropType } from "vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import NavigationTabVertical from "@/components/common/navigation/NavigationTabVertical.vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  components: {
    RouterLinkAuth,
    NavigationTabVertical,
  },
  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      required: true,
    },
    name: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    indent: {
      type: Boolean as PropType<boolean>,
    },
    light: {
      type: Boolean as PropType<boolean>,
    },
  },
});
