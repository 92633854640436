const env = process.env;

function getRequired(key: string) {
  if (typeof env[key] === "undefined") {
    throw new Error(`Environment variable ${key} is required`);
  }
  return env[key];
}

function getRequiredBoolean(key: string) {
  const value = getRequired(key).toLowerCase();
  const trueValues = ["1", "true"];
  const falseValues = ["0", "false", ""];
  const allowedValues = [...trueValues, ...falseValues];
  if (!allowedValues.includes(value)) {
    throw new Error(
      `Environment variable ${key} must be one of ${allowedValues.join(", ")}`
    );
  }
  return value === "1" || value === "true";
}

export default {
  environment: getRequired("NODE_ENV"),
  betaFeaturesEnabled: getRequiredBoolean("VUE_APP_BETA_FEATURES_ENABLED"),
  api: {
    baseUrl: getRequired("VUE_APP_API_BASE_URL"),
  },
  insights: {
    connectionString: getRequired("VUE_APP_INSIGHTS_CONNECTION_STRING"),
  },
  auth0: {
    clientId: getRequired("VUE_APP_AUTH0_CLIENT_KEY"),
    domain: getRequired("VUE_APP_AUTH0_DOMAIN"),
    logoutUrl: getRequired("VUE_APP_AUTH0_LOGOUT_URL"),
    audience: getRequired("VUE_APP_AUTH0_AUDIENCE"),
  },
};
