import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "border-b border-gray-200",
  "data-testid": "navigation-tabs"
}
const _hoisted_2 = {
  class: "-mb-px flex space-x-8",
  "aria-label": "Tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}