import { RouteLocation } from "vue-router";
import { authorization } from "@/store/modules/permissions.store";

const userCanGoTo = (location: RouteLocation): boolean => {
  const requiredPermissionsRaw = location.meta["permissions"] || [];
  if (!Array.isArray(requiredPermissionsRaw)) {
    throw `Route permissions for ${String(location.name)} are not an array`;
  }

  return authorization.hasPermissions(requiredPermissionsRaw);
};

export { userCanGoTo };
