import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    origin: _ctx.DropdownOrigin.TopRight,
    label: _ctx.texts.enums.culturePicker[_ctx.active]
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available, (item) => {
        return (_openBlock(), _createBlock(_component_DropdownItem, {
          key: item,
          label: _ctx.texts.enums.culturePicker[item],
          onClick: ($event: any) => (_ctx.setActive(item))
        }, null, 8, ["label", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["origin", "label"]))
}