import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 gap-1 sm:grid-cols-3 sm:items-start sm:gap-x-4" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "max-w-lg space-y-4" }
const _hoisted_4 = { class: "flex h-5 items-center" }
const _hoisted_5 = { class: "ml-3 text-sm" }
const _hoisted_6 = ["for"]
const _hoisted_7 = {
  key: 1,
  class: "text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.formFieldId,
          class: "block text-sm font-medium text-gray-700"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        { 'col-span-2': _ctx.fullWidth && _ctx.label, 'col-span-3': _ctx.fullWidth && !_ctx.label },
        'mt-4 sm:mt-0',
      ])
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (checkbox) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "relative flex items-start",
            key: checkbox.value
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Checkbox, {
                name: _ctx.formFieldId,
                id: checkbox.id,
                value: checkbox.value,
                modelValue: _ctx.checkedValues,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedValues) = $event)),
                valid: !_ctx.errorMessage
              }, null, 8, ["name", "id", "value", "modelValue", "valid"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (checkbox.label)
                ? (_openBlock(), _createElementBlock("label", {
                    key: 0,
                    for: checkbox.id,
                    class: "cursor-pointer font-medium text-gray-700"
                  }, _toDisplayString(checkbox.label), 9, _hoisted_6))
                : _createCommentVNode("", true),
              (checkbox.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(checkbox.description), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ], 2),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
        'text-sm text-red-500',
        { 'sm:col-start-2': _ctx.label },
        { 'col-span-2': _ctx.fullWidth && _ctx.label, 'col-span-3': _ctx.fullWidth && !_ctx.label },
      ]),
          "data-testid": "error-message"
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ]))
}