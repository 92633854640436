import { Component } from "vue";

interface ButtonProps {
  label: string;
  type: ButtonType;
  size: ButtonSize;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  buttonElementType?: ButtonElementType;
  leadingIcon?: Component;
  centerIcon?: Component;
  trailingIcon?: Component;
}

enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}
enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  white = "white",
  danger = "danger",
  warning = "warning",
  black = "black",
}

enum ButtonElementType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

export { ButtonProps, ButtonType, ButtonSize, ButtonElementType };
