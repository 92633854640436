
import { defineComponent } from "vue";
import Button from "@/components/common/button/Button.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { ButtonType, ButtonElementType } from "../button/Button.types";

// evading wrongly inferred types in unit-tests by explicitely typing 'emits'
const emits: string[] = ["cancel", "confirm"];
export default defineComponent({
  name: "SlideOverDeleteConfirm",
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Button,
  },
  emits,
  setup(props, { emit }) {
    const store = useStore<RootState>();
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    const cancel = () => {
      emit("cancel");
    };

    const confirm = () => {
      emit("confirm");
    };

    return { texts, ButtonType, cancel, confirm, ButtonElementType };
  },
});
