<template>
  <!-- This file is generated by https://pdf.online/convert-pdf-to-html-->
  <div>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: left; font-weight:bold;font-size:18pt">
      Algemene Voorwaarden 
    </h1>
    <p
      style="
        padding-top: 13pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Dit zijn de Algemene Voorwaarden voor Summit Registration &amp; Services
      B.V., gevestigd aan het adres Maliesingel 41 te (3581BK) Utrecht en
      ingeschreven bij de Kamer van Koophandel onder nummer 30142110, hierna te
      noemen “<b>Summit</b
      ><span class="s5"
        >”. Summit levert softwareoplossingen voor organisatoren van beurzen,
        congressen en andere evenementen en verhuurt in dit kader ook hardware
        voor onder meer bezoekersregistratie. Raadpleeg </span
      ><a
        href="http://www.summit.nl/"
         target="_blank"
        style="
          color: #0462c1;
          font-family: Calibri, sans-serif;
          font-style: normal;
          font-weight: normal;
          text-decoration: underline;
          font-size: 11pt;
        "
        >http://www.summit.nl/
      </a>
      voor meer informatie.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-top: 2pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      Artikel 1. Definities
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      De met hoofdletter geschreven begrippen in deze Algemene Voorwaarden
      hebben (zowel in enkelvoud als in meervoud) de onderstaande betekenis:
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <ol id="l1">
      <ol id="l2">
        <li data-list-text="1.1.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Algemene Voorwaarden<span class="p"
              >: de onderhavige voorwaarden inclusief bijlagen.</span
            >
          </h2>
        </li>
        <li data-list-text="1.2.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever<span class="p"
              >: de natuurlijke persoon of rechtspersoon waarmee Summit de
              Overeenkomst heeft gesloten.</span
            >
          </h2>
        </li>
        <li data-list-text="1.3.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Diensten<span class="p"
              >: alle diensten die Summit op grond van de Overeenkomst aan
              Opdrachtgever levert, waaronder in ieder geval begrepen kan zijn:
              (i) het leveren van Software(-as-a-Service), (ii) het onderhouden
              van die Software en (iii) het verhuren van Hardware.</span
            >
          </h2>
        </li>
        <li data-list-text="1.4.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Hardware:
            <span class="p"
              >alle (roerende) zaken die Summit op grond van de Overeenkomst
              verhuurt aan Opdrachtgever, waaronder in ieder geval begrepen kan
              zijn: het hardware-apparaat SummitHost waarmee de bezoekers kunnen
              worden aangemeld, de codes kunnen worden gescand en badges kunnen
              worden geprint.</span
            >
          </h2>
        </li>
        <li data-list-text="1.5.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Intellectuele Eigendomsrechten<span class="p"
              >: alle rechten van intellectuele eigendom en daarmee verwante
              rechten, waaronder in ieder geval auteursrechten, databankrechten,
              rechten op domeinnamen, handelsnaamrechten, rechten op knowhow,
              merkenrechten, modelrechten, naburige rechten en
              octrooirechten.</span
            >
          </h2>
        </li>
        <li data-list-text="1.6.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Kantooruren<span class="p"
              >: de tijd van 9:00 tot 17:00 uur van maandag tot en met zondag,
              met uitzondering van officiële Nederlandse feestdagen.</span
            >
          </h2>
        </li>
        <li data-list-text="1.7.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Materialen:
            <span class="p"
              >alle werken, zoals websites en (web)applicaties, huisstijlen,
              logo’s, folders, brochures, leaflets, belettering, advertenties,
              marketing- en/of communicatieplannen, concepten, afbeeldingen,
              teksten, schetsen, documentatie, adviezen, rapporten en andere
              voortbrengselen van de geest, alsmede voorbereidend materiaal
              daarvan en (al dan niet gecodeerde) bestanden of gegevensdragers
              waarop de materialen zich bevinden.</span
            >
          </h2>
        </li>
        <li data-list-text="1.8.">
          <h2
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Meerwerk<span class="p"
              >: aanvullende werkzaamheden of diensten die buiten de
              Overeenkomst vallen.</span
            >
          </h2>
        </li>
        <li data-list-text="1.9.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Overeenkomst<span class="p"
              >: het (door Opdrachtgever aanvaarde) aanbod op grond waarvan
              Summit zijn Diensten aan Opdrachtgever levert, met inbegrip van
              deze Algemene Voorwaarden en eventuele andere documenten en/of
              afspraken genoemd in het aanbod, zoals de
              Verwerkersovereenkomst.</span
            >
          </h2>
        </li>
        <li data-list-text="1.10.">
          <h2
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Partij(en)<span class="p"
              >: de partij(en) bij de Overeenkomst, Summit en Opdrachtgever
              gezamenlijk of afzonderlijk.</span
            >
          </h2>
        </li>
        <li data-list-text="1.11.">
          <h2
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Software<span class="p"
              >: alle programmatuur die Summit op grond van de Overeenkomst
              beschikbaar stelt.</span
            >
          </h2>
        </li>
        <li data-list-text="1.12.">
          <h2 style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Spoedonderhoud<span class="p"
              >: onvoorzien onderhoud dat redelijkerwijs nodig is in verband met
              de beschikbaarheid, bruikbaarheid en/of veiligheid van de
              Diensten.</span
            >
          </h2>
        </li>
        <li data-list-text="1.13.">
          <h2 style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Verwerkersovereenkomst<span class="p"
              >: de verwerkersovereenkomst in Bijlage 1 van de Algemene
              Voorwaarden die onderdeel uitmaakt van de Overeenkomst.</span
            >
          </h2>
        </li>
        <li data-list-text="1.14.">
          <h2 style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Website<a href="http://www.summit.nl/" class="s5" target="_blank"
              >: </a
            ><a href="http://www.summit.nl/" target="_blank"
              >http://www.summit.nl/</a
            >
          </h2>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Artikel 2. Totstandkoming
    </h2>
    <ol id="l3">
      <ol id="l4">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Alle offertes en andere aanbiedingen van Summit zijn vrijblijvend.
            Summit is niet verplicht om een aanvaarding na het verstrijken van
            een eventueel genoemde geldigheidsduur in een offerte of aanbod aan
            te nemen, maar indien Summit daartoe overgaat, geldt de offerte of
            het aanbod alsnog als aanvaard.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Als de aanvaarding door Opdrachtgever (al dan niet op ondergeschikte
            punten) afwijkt van het aanbod, is Summit daaraan niet gebonden. De
            Overeenkomst komt in dat geval niet overeenkomstig deze afwijkende
            aanvaarding tot stand, tenzij Summit expliciet anders aangeeft.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Opdrachtgever niet expliciet aangeeft akkoord te gaan met het
            aanbod van Summit, maar er desondanks mee instemt, of die indruk
            wekt, dat Summit werkzaamheden verricht die binnen de omschrijving
            van de aangeboden Diensten vallen, dan wordt het aanbod als aanvaard
            beschouwd.
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Eventuele algemene (inkoop)voorwaarden van Opdrachtgever worden
            uitdrukkelijk van hand gewezen. Aanvullende door Opdrachtgever
            verstrekte voorwaarden, afspraken of bepalingen zijn enkel van
            toepassing indien deze uitdrukkelijk schriftelijk tussen Partijen
            overeengekomen zijn.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            class="s3"
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In
            <span class="s4"
              >geval van strijdigheid van verschillende documenten of de
              bijlagen daarvan, geldt de volgende rangorde:</span
            >
          </p>
          <ol id="l5">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 48pt;
                  text-indent: -14pt;
                  line-height: 13pt;
                  text-align: left;
                "
              >
                aanvullende schriftelijke en ondertekende afspraken;
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 48pt;
                  text-indent: -14pt;
                  line-height: 13pt;
                  text-align: left;
                "
              >
                geaccordeerde offerte of aanbod;
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style="padding-left: 48pt; text-indent: -14pt; text-align: left"
              >
                de Verwerkersovereenkomst;
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style="padding-left: 48pt; text-indent: -14pt; text-align: left"
              >
                de Algemene Voorwaarden.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 3. Uitvoering van de Overeenkomst
    </h2>
    <ol id="l6">
      <ol id="l7">
        <li data-list-text="3.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Nadat de Overeenkomst tot stand is gekomen, spant Summit zich ervoor
            in om de Overeenkomst naar beste kunnen na te komen onder toepassing
            van voldoende zorgvuldigheid en vakmanschap. Door Summit
            bekendgemaakte termijnen zijn indicatief en gelden niet als fatale
            termijnen. Indien expliciet wordt aangegeven dat een termijn fataal
            is, dan is Summit niet in verzuim totdat hij in gebreke is gesteld
            en ook binnen de redelijke termijn niet alsnog is nagekomen.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever zal aan Summit alle ondersteuning bieden die nodig en
            wenselijk is om een juiste en tijdige levering van de Diensten
            mogelijk te maken. In ieder geval zal Opdrachtgever:
          </p>
          <ol id="l8">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                alle informatie verstrekken waarvan Summit aangeeft dat deze
                noodzakelijk zijn, of waarvan Opdrachtgever redelijkerwijs moet
                begrijpen dat deze noodzakelijk zijn voor de levering van de
                Diensten;
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                aan Summit toegang verlenen tot alle plaatsen, diensten en
                accounts onder zijn beheer indien en voor zover dit nodig is
                voor de levering van de Diensten.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="3.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit zal rekening houden met redelijke verzoeken van Opdrachtgever
            bij de uitvoering van de Overeenkomst, maar is niet verplicht gehoor
            te geven aan een dergelijk verzoek. Summit heeft het recht
            meerkosten te rekenen voor het uitvoeren van een dergelijk verzoek.
          </p>
        </li>
        <li data-list-text="3.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit heeft het recht om derden in te schakelen bij de uitvoering
            van de Overeenkomst. Eventuele hiermee verband houdende kosten komen
            alleen voor rekening van Opdrachtgever indien dat vooraf is
            overeengekomen.
          </p>
        </li>
        <li data-list-text="3.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien medewerkers van Summit bij de uitvoering van de Overeenkomst
            werkzaamheden verrichten ten kantore van Opdrachtgever of op een
            andere door Opdrachtgever aangewezen locatie, zal Opdrachtgever
            kosteloos alle ondersteuning en faciliteiten bieden ten behoeve van
            de uitoefening van de werkzaamheden.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Artikel 4. Meerwerk
    </h2>
    <ol id="l9">
      <ol id="l10">
        <li data-list-text="4.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Opdrachtgever verzoekt om aanvullende werkzaamheden of
            Diensten die buiten de Overeenkomst vallen, zullen Partijen daarover
            in overleg treden en kan Summit een aanvullende offerte opstellen.
            Summit zal het Meerwerk pas uitvoeren na aanvaarding van de offerte
            door Opdrachtgever.
          </p>
        </li>
        <li data-list-text="4.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Voor Meerwerk waarvan Summit kan aantonen dat het redelijkerwijs
            noodzakelijk is voor de levering van de Diensten, of dat
            redelijkerwijs volgt uit de instructies van Opdrachtgever, heeft
            Summit geen toestemming nodig. Dergelijke werkzaamheden worden op
            basis van nacalculatie uitgevoerd tegen het uurtarief van Summit dat
            geldt op het moment van de uitvoering van de werkzaamheden.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 5. Oplevering en aanvaarding
    </h2>
    <ol id="l11">
      <ol id="l12">
        <li data-list-text="5.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Summit Diensten op maat levert aan Opdrachtgever, zal Summit
            het maatwerk opleveren wanneer dit in zijn professionele opinie
            voldoet aan de omschrijving in de Overeenkomst of geschikt is voor
            gebruik. Opdrachtgever zal het opgeleverde binnen zeven dagen na
            oplevering evalueren en goed- of afkeuren conform de in de
            omschrijving in de Overeenkomst genoemde of nader overeengekomen
            opleveringscriteria. Indien Opdrachtgever niet binnen deze periode
            het opgeleverde afkeurt, wordt het opgeleverde geacht te zijn
            aanvaard.
          </p>
        </li>
        <li data-list-text="5.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien de Diensten in fasen worden opgeleverd, dient Opdrachtgever
            na oplevering van elke fase de goed- of afkeuring van het deel van
            de Diensten van die fase te geven op de wijze zoals in het vorige
            lid bepaald. Opdrachtgever kan een goed- of afkeuring in een latere
            fase niet baseren op aspecten die in een eerdere fase goedgekeurd
            zijn.
          </p>
        </li>
        <li data-list-text="5.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Opdrachtgever het opgeleverde geheel of gedeeltelijk afkeurt,
            zal Summit zich inspannen de reden van afkeuring zo snel mogelijk
            weg te nemen. Dit kan Summit doen door het resultaat te reviseren of
            gemotiveerd aan te geven waarom de reden niet opgaat. Opdrachtgever
            heeft vervolgens veertien dagen om de revisie of motivatie goed of
            af te keuren. In geval van afkeuring wordt de overeenkomst beëindigd
            en de reeds gemaakte kosten door Summit in rekening gebracht.
          </p>
        </li>
        <li data-list-text="5.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever mag de Diensten slechts afkeuren op grond van
            substantiële afwijkingen van de functionele specificatie uit de
            Overeenkomst.
          </p>
        </li>
        <li data-list-text="5.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien bezwaren slechts ondergeschikte aspecten betreffen, worden de
            Diensten geacht te zijn aanvaard onder het voorbehoud dat deze
            bezwaren alsnog binnen een redelijke termijn door Summit worden
            opgeheven.
          </p>
        </li>
        <li data-list-text="5.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Voor fouten ontdekt na aanvaarding bestaat geen aansprakelijkheid
            voor Summit, tenzij Summit deze kende of had moeten kennen bij
            oplevering.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 6. Aanpassingen en verbeteren
    </h2>
    <ol id="l13">
      <ol id="l14">
        <li data-list-text="6.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit kan de Diensten op basis van zijn professionele opinie
            aanpassen, bijvoorbeeld om fouten op te lossen, nieuwe
            functionaliteiten toe te voegen of de prestaties te verbeteren.
            Summit kan over dergelijke aanpassingen vooraf overleg voeren met
            Opdrachtgever, maar de eindbeslissing over het al dan niet
            doorvoeren van de aanpassing wordt genomen door Summit.
          </p>
        </li>
        <li data-list-text="6.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien aanpassingen naar het oordeel van Summit tot een wezenlijke
            verandering in de functionaliteit van de Diensten leiden, streeft
            Summit ernaar om de aanpassingen van tevoren per e-mail aan te
            kondigen bij Opdrachtgever.
          </p>
        </li>
        <li data-list-text="6.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit zal zich inspannen om eventuele fouten in de Diensten te
            herstellen, maar is hierbij mede afhankelijk van zijn leveranciers.
            Summit heeft het recht om bepaalde updates of upgrades van
            leveranciers niet te installeren als dit naar zijn oordeel de
            werking van de Diensten niet ten goede komt.
          </p>
        </li>
        <li data-list-text="6.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Als het doorvoeren van aanpassingen en verbeteringen tot een
            beperking van de beschikbaarheid van de Diensten kan leiden, wordt
            dit zoveel mogelijk buiten Kantooruren
          </p>
        </li>
      </ol>
    </ol>
    <p
      style="
        padding-top: 1pt;
        padding-left: 41pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      uitgevoerd. Spoedonderhoud kan op ieder moment worden verricht en wordt
      niet vooraf aangekondigd.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 7. Ondersteuning
    </h2>
    <ol id="l15">
      <ol id="l16">
        <li data-list-text="7.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit spant zich in Opdrachtgever te ondersteunen met betrekking
            tot vragen over het gebruik en beheer van de Diensten, alsmede
            technische problemen die verband houden met de Diensten.
          </p>
        </li>
        <li data-list-text="7.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De ondersteuning zoals omschreven in het voorgaande lid wordt
            aangeboden via een helpdesk die tijdens Kantooruren per e-mail of
            per telefoon bereikbaar is.
          </p>
        </li>
        <li data-list-text="7.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit streeft ernaar om helpdeskverzoeken binnen een redelijke
            termijn af te handelen. De tijd die nodig is voor het oplossen van
            meldingen kan variëren.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Artikel 8. Beschikbaarheid en back-ups
    </h2>
    <ol id="l17">
      <ol id="l18">
        <li data-list-text="8.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit zal zich inspannen om de Diensten zo goed mogelijk te leveren
            en beschikbaar te laten zijn. Opdrachtgever kan enkel aanspraak
            maken op specifieke service levels, zoals een bepaalde
            beschikbaarheid of reactietijd, indien dit schriftelijk is
            overeengekomen. Bijvoorbeeld in een service level agreement.
          </p>
        </li>
        <li data-list-text="8.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Als naar het oordeel van Summit gevaar ontstaat voor het
            functioneren van de computersystemen of het netwerk van Summit of
            derden, bijvoorbeeld in het geval van een (d)dos-aanval of
            activiteiten van malware, heeft Summit het recht om alle maatregelen
            te nemen die hij redelijkerwijs nodig acht om het gevaar af te
            wenden en schade te beperken of te voorkomen. Dit kan resulteren in
            beperkte beschikbaarheid.
          </p>
        </li>
        <li data-list-text="8.3.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit zal periodiek reservekopieën (back-ups) maken van bepaalde
            gegevens van Opdrachtgever op systemen van Summit of derden, en deze
            gegevens tegen een redelijke vergoeding op verzoek aan Opdrachtgever
            beschikbaar stellen. Deze reservekopieën kunnen na beëindiging van
            de Overeenkomst op elk moment vernietigd worden door Summit. Het is
            de verantwoordelijkheid van Opdrachtgever om tijdig te verzoeken om
            een reservekopie bij beëindiging van de Overeenkomst. Tenzij
            partijen nadere afspraken maken over het maken van reservekopieën,
            is Summit volledig vrij in het vaststellen van onder meer: de
            tijdstippen waarop reservekopieën worden gemaakt, de frequentie
            waarmee reservekopieën worden gemaakt, de bewaartermijn van de
            reservekopieën.
          </p>
        </li>
        <li data-list-text="8.4.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Tenzij schriftelijk anders overeengekomen worden eventuele
            reservekopieën gemaakt via een geautomatiseerd proces en worden deze
            reservekopieën steekproefsgewijs getest of ze naar behoren werken.
            Enkel wanneer partijen schriftelijk overeenkomen dat alle of
            bepaalde reservekopieën handmatig door Summit getest worden, kan
            Summit ervoor instaan dat de handmatig geteste reservekopieën naar
            behoren werken en terug gezet kunnen worden.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        line-height: 13pt;
        text-align: justify;
      "
    >
      Artikel 9. Gebruiksregels
    </h2>
    <ol id="l19">
      <ol id="l20">
        <li data-list-text="9.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Het is Opdrachtgever verboden om de Diensten te gebruiken op een
            manier die in strijd is met deze voorwaarden of met toepasselijke
            wet- en regelgeving. Daarnaast is het uitdrukkelijk niet toegestaan
            om de Diensten te gebruiken op een manier die hinder of schade kan
            veroorzaken voor Summit of derden.
          </p>
        </li>
        <li data-list-text="9.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Summit constateert dat Opdrachtgever deze voorwaarden of de
            wet overtreedt, of een klacht hierover ontvangt, mag Summit
            ingrijpen om de overtreding te beëindigen. Summit zal dan de toegang
            tot de betreffende informatie blokkeren.
          </p>
        </li>
        <li data-list-text="9.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien naar het oordeel van Summit hinder, schade of een ander
            gevaar ontstaat voor het functioneren van de computersystemen of het
            netwerk van Summit of derden en/of van de dienstverlening via
            internet, in het bijzonder door overmatig verzenden van e-mail of
            andere gegevens, lekken van persoonsgegevens of activiteiten van
            virussen, trojans en vergelijkbare software, is Summit gerechtigd
            alle maatregelen te nemen die hij redelijkerwijs nodig acht om dit
            gevaar af te wenden dan wel te voorkomen. Deze maatregelen bevatten,
            maar zijn niet beperkt tot, het opschorten van de Diensten en
            beëindiging van de Overeenkomst.
          </p>
        </li>
        <li data-list-text="9.4.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: justify;
            "
          >
            Summit is te allen tijde gerechtigd om aangifte te doen van
            geconstateerde strafbare feiten en zal zijn medewerking verlenen aan
            bevoegd gegeven bevelen. Daarnaast is Summit gerechtigd om
            identificerende gegevens van Opdrachtgever af te geven aan een derde
            die klaagt dat Opdrachtgever inbreuk maakt op diens rechten of deze
            voorwaarden, mits:
          </p>
          <ol id="l21">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                het voldoende aannemelijk is dat de informatie, op zichzelf
                beschouwd, jegens de derde onrechtmatig of schadelijk is;
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                de derde redelijkerwijs een reëel belang heeft bij de
                verkrijging van de gegevens;
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                het aannemelijk is dat er in het concrete geval redelijkerwijs
                geen minder ingrijpende mogelijkheid voorhanden is om de
                gegevens de achterhalen.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="9.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever is verplicht om alle redelijke aanwijzingen van Summit
            te volgen die betrekking hebben op het gebruik van de Diensten.
          </p>
        </li>
        <li data-list-text="9.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit kan de schade als gevolg van overtredingen van de regels uit
            dit artikel op Opdrachtgever verhalen. Opdrachtgever vrijwaart
            Summit van alle aanspraken van derden die betrekking hebben op
            schade als gevolg van een schending van de regels uit dit artikel.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 10. Ticketverkoop
    </h2>
    <ol id="l22">
      <ol id="l23">
        <li data-list-text="10.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Als Opdrachtgever de Diensten gebruikt om een evenement te promoten
            waarvoor zij (betaalde) tickets aanbiedt, dan is Summit geen partij
            bij de (koop) overeenkomst tussen Opdrachtgever en de persoon (of
            juridische entiteit) die de aangeboden tickets koopt. Summit treedt
            alleen op als tussenpersoon bij de totstandkoming van een dergelijke
            overeenkomst.
          </p>
        </li>
        <li data-list-text="10.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Bij het aanbieden van tickets is Opdrachtgever verantwoordelijk voor
            de naleving van de toepasselijke wetgeving, waaronder en niet
            uitsluitend wordt verstaan: de consumenten- en belastingwetgeving
            die van toepassing is op Opdrachtgever en de personen (of juridische
            entiteiten) die de kaartjes kopen.
          </p>
        </li>
        <li data-list-text="10.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit levert geen betaaldiensten. Bij het aanbieden van tickets
            waarvoor een vergoeding verschuldigd is via de Diensten, wordt de
            betaling afgehandeld door een externe betalingsdienstaanbieder.
            Summit is niet verantwoordelijk voor fouten van vertragingen in de
            betaling.
          </p>
        </li>
        <li data-list-text="10.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Bij het aanbieden van tickets via de Diensten, is Opdrachtgever
            verantwoordelijk voor het toepassen en betalen van het juiste bedrag
            aan btw en andere heffingen opgelegd door de overheid of
            toepasselijke wetgeving voor Opdrachtgever of de personen (of
            entiteiten) die de tickets kopen.
          </p>
        </li>
        <li data-list-text="10.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Wanneer een ticket voor een evenement wordt gekocht (of tickets
            worden gekocht) via de Diensten en de persoon (of juridische
            entiteit) die de kaartjes koopt een (deel van het) betaalde bedrag
            terugvordert, dan zal Opdrachtgever Summit dit bedrag en alle daarop
            betrekking hebbende gerelateerde kosten terugbetalen. Summit stuurt
            Opdrachtgever een factuur voor een dergelijke terugbetaling.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 11. Beveiliging en privacy
    </h2>
    <ol id="l24">
      <ol id="l25">
        <li data-list-text="11.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit zal zich inspannen om de Diensten te beveiligen tegen
            misbruik en ongeautoriseerde toegang tot gegevens van Opdrachtgever.
          </p>
        </li>
        <li data-list-text="11.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Bij de uitvoering van de Overeenkomst is het mogelijk dat Summit in
            opdracht van Opdrachtgever persoonsgegevens verwerkt, waarbij
            Opdrachtgever als verwerkingsverantwoordelijke is aan te merken en
            Summit als verwerker. De Verwerkersovereenkomst in Bijlage 1 van
            deze Algemene Voorwaarden is van toepassing van de verwerking van
            persoonsgegevens van Summit voor Opdrachtgever. Genoemde termen
            hebben de betekenis zoals vastgelegd in de toepasselijke
            privacywetgeving.
          </p>
        </li>
        <li data-list-text="11.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever garandeert dat iedere eventuele verstrekking van
            (persoons)gegevens aan Summit rechtmatig is en dat de verwerking van
            deze gegevens conform de Overeenkomst niet in strijd is met enige
            toepasselijke privacywet- en regelgeving.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Artikel 12. Intellectuele Eigendomsrechten
    </h2>
    <ol id="l26">
      <ol id="l27">
        <li data-list-text="12.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Alle Intellectuele Eigendomsrechten op de Diensten en eventuele
            Materialen die op grond van de Overeenkomst door Summit worden
            geleverd berusten uitsluitend bij Summit of diens licentiegevers.
          </p>
        </li>
        <li data-list-text="12.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever krijgt van Summit de niet-exclusieve,
            niet-overdraagbare en niet-sublicentieerbare gebruiksrechten ten
            aanzien van de Diensten en eventuele Materialen voor zover deze
            voortvloeien uit de Overeenkomst of welke anderszins schriftelijk
            zijn toegekend. De gebruiksrechten gelden voor de duur van de
            Overeenkomst.
          </p>
        </li>
        <li data-list-text="12.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever is niet gerechtigd om wijzigingen aan te brengen in de
            Software en heeft geen recht op een kopie van de bronbestanden van
            de Software.
          </p>
        </li>
        <li data-list-text="12.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit kan (technische) maatregelen nemen ter bescherming van de
            Diensten. Indien Summit dergelijke beveiligingsmaatregelen heeft
            genomen, is het Opdrachtgever niet toegestaan om deze beveiliging te
            ontwijken of te verwijderen.
          </p>
        </li>
        <li data-list-text="12.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Het is Opdrachtgever niet toegestaan om enige aanduiding van
            Intellectuele Eigendomsrechten uit de Software te verwijderen of aan
            te passen. Het is tevens niet toegestaan om aanduidingen omtrent het
            vertrouwelijke karakter uit de Software te verwijderen.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 13. Geheimhouding
    </h2>
    <ol id="l28">
      <ol id="l29">
        <li data-list-text="13.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Partijen zullen informatie die zij voor, tijdens of na de uitvoering
            van de Overeenkomst aan elkaar verstrekken of in de Diensten
            verwerken, vertrouwelijk behandelen wanneer deze informatie is
            gemarkeerd als vertrouwelijk of waarvan van tevoren expliciet wordt
            aangegeven dat het vertrouwelijk is. Partijen leggen deze
            verplichting tevens op aan hun werknemers alsmede aan door hen
            ingeschakelde derden ter uitvoering van de Overeenkomst.
          </p>
        </li>
        <li data-list-text="13.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Partijen zullen commerciële afspraken, zoals overeengekomen prijzen,
            in ieder geval vertrouwelijk behandelen.
          </p>
        </li>
        <li data-list-text="13.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De verplichting tot vertrouwelijk behandelen blijft ook bestaan na
            beëindiging van de Overeenkomst om welke reden dan ook, en wel voor
            zolang als de verstrekkende partij redelijkerwijs aanspraak kan
            maken op het vertrouwelijke karakter van de informatie.
          </p>
        </li>
        <li data-list-text="13.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Partijen zullen ten aanzien van ontvangen vertrouwelijke informatie
            redelijke maatregelen in acht nemen voor een veilige bewaring of
            opslag van de vertrouwelijke informatie. Partijen zullen ten aanzien
            van ontvangen vertrouwelijke informatie minimaal hetzelfde niveau
            van beveiliging hanteren als partijen voor hun eigen vertrouwelijke
            informatie hanteren.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Artikel 14. Prijzen en betalingsvoorwaarden
    </h2>
    <ol id="l30">
      <ol id="l31">
        <li data-list-text="14.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Opdrachtgever betaalt Summit een vergoeding voor de Dienst(en) zoals
            overeengekomen of vermeld in het aanbod. Tenzij bij een bedrag
            uitdrukkelijk anders is vermeld zijn alle door Summit genoemde
            prijzen exclusief omzetbelasting (btw) en andere heffingen die van
            overheidswege worden opgelegd.
          </p>
        </li>
        <li data-list-text="14.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Alle prijzen in aanbiedingen, prijslijst(en) en/of overige
            communicatiemiddelen van Summit zijn onder voorbehoud van
            programmeer- en typefouten. Indien een prijs is gebaseerd op door
            Opdrachtgever verstrekte gegevens en deze gegevens blijken onjuist
            te zijn, dan heeft Summit het recht de prijzen hierop aan te passen,
            ook nadat de Overeenkomst reeds tot stand is gekomen.
          </p>
        </li>
        <li data-list-text="14.3.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit is gerechtigd de door hem gehanteerde en/of de overeengekomen
            prijzen telkens bij verlenging van de Overeenkomst en/of éénmaal per
            kalenderjaar, aan te passen op basis van inflatie, loonindexeringen
            op basis van een cao, een stijging van kosten of veranderde
            marktomstandigheden. Indien een toeleverancier van Summit zijn
            prijzen verhoogt, dan is Summit steeds gerechtigd deze verhoging per
            direct door te berekenen aan Opdrachtgever.
          </p>
        </li>
        <li data-list-text="14.4.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit zal voor alle verschuldigde bedragen een factuur sturen en is
            daarbij gerechtigd om vooraf en elektronisch te factureren. Als
            Opdrachtgever bezwaar maakt tegen (de hoogte) van een factuur,
            schort dit de betalingsplicht niet op.
          </p>
        </li>
        <li data-list-text="14.5.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: left;
            "
          >
            Indien er Meerwerk is overeengekomen, worden de verschuldigde
            bedragen na het uitvoeren van het Meerwerk gefactureerd, tenzij
            anders overeengekomen.
          </p>
        </li>
        <li data-list-text="14.6.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Voor alle facturen geldt een betalingstermijn van 30 dagen, tenzij
            de factuur een andere betalingstermijn vermeldt of schriftelijk een
            andere termijn is overeengekomen.
          </p>
        </li>
        <li data-list-text="14.7.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Indien Opdrachtgever na de betalingstermijn nog niet volledig heeft
            betaald, zal Summit Opdrachtgever alsnog de mogelijkheid geven om
            het factuurbedrag binnen veertien (14) dagen te betalen. Indien
            Opdrachtgever ook na deze termijn niet volledig heeft betaald, is
            Opdrachtgever automatisch in verzuim zonder dat hiervoor een
            ingebrekestelling is vereist. Summit heeft in dat geval het recht om
            de levering van de Diensten geheel of gedeeltelijk op te schorten.
            Summit is niet aansprakelijk voor schade die Opdrachtgever hierdoor
            lijdt.
          </p>
        </li>
        <li data-list-text="14.8.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Bij een niet-tijdige betaling is Opdrachtgever, naast het
            verschuldigde bedrag en de daarop verschenen handelsrente, gehouden
            tot een volledige vergoeding van zowel buitengerechtelijke als
            gerechtelijke incassokosten, met inbegrip van de kosten voor
            advocaten, juristen, deurwaarders en incassobureaus.
          </p>
        </li>
        <li data-list-text="14.9.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Alle vorderingen van Summit op Opdrachtgever zijn direct opeisbaar
            indien het faillissement van Opdrachtgever is aangevraagd,
            Opdrachtgever in staat van faillissement verkeert, Opdrachtgever
            surseance van betaling aanvraagt of krijgt verleend, de activiteiten
            van Opdrachtgever worden beëindigd, dan wel wanneer de onderneming
            van Opdrachtgever wordt geliquideerd.
          </p>
        </li>
        <li data-list-text="14.10.">
          <p
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Alle door Summit genoemde prijzen zijn in euro’s en exclusief btw en
            andere heffingen welke
          </p>
        </li>
      </ol>
    </ol>
    <p style="padding-left: 41pt; text-indent: 0pt; text-align: left">
      van overheidswege worden opgelegd.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 15. Aansprakelijkheid
    </h2>
    <ol id="l32">
      <ol id="l33">
        <li data-list-text="15.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De aansprakelijkheid van Summit voor schade als gevolg van een
            tekortkoming in de nakoming van de Overeenkomst, een onrechtmatige
            daad, het schenden van een garantieverplichting of anderszins, is
            per gebeurtenis (waarbij een reeks van opeenvolgende gebeurtenissen
            geldt als één gebeurtenis) beperkt tot vergoeding van directe schade
            met een maximum dat gelijk is aan het bedrag (exclusief btw) dat
            Opdrachtgever op grond van de Overeenkomst heeft betaald aan Summit
            gedurende 6 maanden voorafgaand aan het ontstaan van de schade.
          </p>
        </li>
        <li data-list-text="15.2.">
          <p
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Onder directe schade wordt uitsluitend verstaan alle schade
            bestaande uit:
          </p>
          <ol id="l34">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                schade direct toegebracht aan stoffelijke zaken (“zaakschade”);
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                redelijke en aantoonbare kosten die Opdrachtgever heeft moeten
                maken om Summit er toe te manen de Overeenkomst (weer)
                deugdelijk na te komen, tenzij de gebrekkige prestatie niet aan
                Summit toegerekend kan worden;
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                redelijke kosten ter vaststelling van de oorzaak en de omvang
                van de directe schade;
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                redelijke en aantoonbare kosten die Opdrachtgever heeft gemaakt
                ter voorkoming of beperking van de directe schade, voor zover
                Opdrachtgever aantoont dat deze kosten hebben geleid tot
                beperking van de directe schade;
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style="
                  padding-left: 62pt;
                  text-indent: -14pt;
                  text-align: justify;
                "
              >
                redelijke en aantoonbare kosten voor het laten uitvoeren van de
                Overeenkomst door een derde, indien Summit na aanmaning van
                Opdrachtgever niet binnen de in de aanmaning gestelde redelijke
                termijn (weer) deugdelijk nakomt.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="15.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit is in geen geval aansprakelijk voor vergoeding van indirecte
            schade of gevolgschade, zoals schade wegens gederfde omzet of winst,
            vertragingsschade, schade wegens verlies van gegevens en schade
            wegens gemiste besparingen.
          </p>
        </li>
        <li data-list-text="15.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De voornoemde beperking van aansprakelijkheid geldt niet voor zover
            de schade het gevolg is van opzet of bewuste roekeloosheid van het
            management van Summit, dan wel voor zover de aansprakelijkheid van
            Summit anderszins op grond van de toepasselijke wetgeving niet
            beperkt kan worden.
          </p>
        </li>
        <li data-list-text="15.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De aansprakelijkheid van Summit wegens toerekenbare tekortkoming in
            de nakoming van de Overeenkomst ontstaat slechts indien
            Opdrachtgever Summit onverwijld en deugdelijk schriftelijk in
            gebreke stelt, stellende daarbij een redelijke termijn ter zuivering
            van de tekortkoming, en Summit ook na die termijn toerekenbaar in de
            nakoming van zijn
          </p>
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            verplichtingen tekort blijft schieten. De ingebrekestelling dient
            een zo gedetailleerd mogelijke omschrijving van de tekortkoming te
            bevatten, zodat Summit in staat is adequaat te reageren.
          </p>
        </li>
        <li data-list-text="15.6.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            Voorwaarde voor het ontstaan van enig recht op schadevergoeding is
            dat Opdrachtgever de schade uiterlijk binnen 30 dagen na ontdekking
            schriftelijk bij Summit meldt.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 16. Overmacht
    </h2>
    <ol id="l35">
      <ol id="l36">
        <li data-list-text="16.1.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            Summit kan niet tot nakoming van enige verplichting uit de
            Overeenkomst worden gehouden indien de nakoming is verhinderd als
            gevolg van overmacht. Summit is niet aansprakelijk voor enige schade
            die het gevolg is van overmacht.
          </p>
        </li>
        <li data-list-text="16.2.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            Van overmacht is in ieder geval sprake bij stroomstoringen,
            internetstoringen, storingen in de telecommunicatie-infrastructuur,
            netwerkaanvallen (waaronder (d)dos-aanvallen), aanvallen van malware
            of andere kwaadaardige software, binnenlandse onlusten,
            natuurgeweld, terreur, mobilisatie, oorlog, in- en
            uitvoerbelemmeringen, stakingen, stagnatie in toelevering, brand,
            overstromingen en het geval waarin Summit door zijn toeleveranciers,
            ongeacht de reden daartoe, niet tot nakoming in staat wordt gesteld.
          </p>
        </li>
        <li data-list-text="16.3.">
          <p
            style="padding-left: 40pt; text-indent: -35pt; text-align: justify"
          >
            Indien een overmachtssituatie langer dan 90 dagen heeft geduurd,
            hebben beide Partijen het recht om de Overeenkomst per direct
            schriftelijk op te zeggen. De Diensten die in dat geval door Summit
            zijn geleverd voordat de overmachtssituatie zich voordeed en
            gedurende de overmachtssituatie, worden naar verhouding afgerekend.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 17. Duur en einde
    </h2>
    <ol id="l37">
      <ol id="l38">
        <li data-list-text="17.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De Overeenkomst wordt aangegaan voor de periode die wordt genoemd in
            de offerte of het aanbod van Summit.
          </p>
        </li>
        <li data-list-text="17.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Als de duur van de Overeenkomst niet in de offerte of het aanbod
            wordt genoemd, wordt de Overeenkomst geacht te zijn aangegaan voor
            een periode van 12 maanden, tenzij de Overeenkomst eindigt door een
            vooraf vastgestelde taak.
          </p>
        </li>
        <li data-list-text="17.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien de Overeenkomst een duurovereenkomst is, kan deze tegen het
            einde van de looptijd door beide Partijen worden opgezegd met
            inachtneming van een opzegtermijn van 3 maanden. Bij het uitblijven
            van een dergelijke opzegging wordt de Overeenkomst telkens
            stilzwijgend verlengd met de initiële looptijd van de Overeenkomst.
          </p>
        </li>
        <li data-list-text="17.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit mag de Overeenkomst per direct schriftelijk opschorten of
            opzeggen, zonder dat daarvoor een ingebrekestelling is vereist,
            indien Opdrachtgever in verzuim is met betrekking tot een wezenlijke
            verplichting onder de Overeenkomst.
          </p>
        </li>
        <li data-list-text="17.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit mag de Overeenkomst per direct schriftelijk opschorten of
            opzeggen, zonder dat daarvoor een ingebrekestelling is vereist,
            indien het faillissement van Opdrachtgever is aangevraagd,
            Opdrachtgever in staat van faillissement verkeert, Opdrachtgever
            surseance van betaling aanvraagt of krijgt verleend, de activiteiten
            van Opdrachtgever worden beëindigd, dan wel wanneer de onderneming
            van Opdrachtgever wordt geliquideerd.
          </p>
        </li>
        <li data-list-text="17.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Wanneer Summit de nakoming van de Overeenkomst opschort, behoudt
            Summit zijn aanspraken op grond van de Overeenkomst en de
            toepasselijke wet- en regelgeving.
          </p>
        </li>
        <li data-list-text="17.7.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In het geval dat de Overeenkomst wordt beëindigd zijn de vorderingen
            van Summit op Opdrachtgever onmiddellijk opeisbaar (ongeacht de
            reden voor de beëindiging).
          </p>
        </li>
        <li data-list-text="17.8.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien de Overeenkomst wordt ontbonden, blijft Opdrachtgever de
            reeds gefactureerde bedragen verschuldigd en ontstaan er geen
            verplichtingen tot ongedaanmaking.
          </p>
        </li>
        <li data-list-text="17.9.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever kan uitsluitend het deel van de Overeenkomst ontbinden
            dat nog niet door Summit is uitgevoerd.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 18. Wijziging algemene voorwaarden
    </h2>
    <ol id="l39">
      <ol id="l40">
        <li data-list-text="18.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit heeft het recht om deze Algemene Voorwaarden te wijzigen of
            aan te vullen. Summit zal de wijziging of aanvulling ten minste 30
            dagen vóór inwerkingtreding per e-mail of via de Website aankondigen
            bij Opdrachtgever.
          </p>
        </li>
        <li data-list-text="18.2.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: justify;
            "
          >
            <a name="bookmark0"
              >Als Opdrachtgever de wijzigingen of aanvullingen niet wenst te
              accepteren, kan Opdrachtgever binnen 14 dagen na bekendmaking
              gemotiveerd bezwaar maken, waarna Summit de aanpassing of
              aanvulling zal heroverwegen. Als Summit besluit om vast te houden
              aan de wijziging of aanvulling, kan Opdrachtgever de Overeenkomst
              schriftelijk opzeggen tegen de datum waarop de wijziging in
              werking treedt.</a
            >
          </p>
        </li>
        <li data-list-text="18.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            <a name="bookmark1"
              >Beide Partijen zullen hun volledige medewerking verlenen bij
              wijzigingen of aanvullingen van deze Algemene Voorwaarden die
              noodzakelijk zijn vanwege nieuwe of veranderde wetgeving.
              Dergelijke wijzigingen of aanvullingen zullen in overleg tussen
              Partijen worden doorgevoerd, zonder dat Opdrachtgever de
              mogelijkheid heeft de Overeenkomst op te zeggen.</a
            >
          </p>
        </li>
        <li data-list-text="18.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            <a href="#bookmark0" class="s5">De in artikel </a
            ><a href="#bookmark1" class="s5">18.2. en </a>18.3. beschreven
            procedure geldt niet voor wijzigingen van ondergeschikt belang.
            Dergelijke wijzigingen mogen zonder aankondiging door Summit worden
            doorgevoerd.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 19. Exit
    </h2>
    <ol id="l41">
      <ol id="l42">
        <li data-list-text="19.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Na het einde van de Overeenkomst bewaart Summit alle data en
            gegevens van Opdrachtgever voor een periode van 30 dagen. Indien
            Opdrachtgever aan al zijn betalingsverplichtingen op grond van de
            Overeenkomst heeft voldaan, zal Summit zich inspannen een kopie te
            verstrekken van deze data en gegevens in een door Summit gehanteerd
            standaard bestandsformaat.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 20. Overige bepalingen
    </h2>
    <ol id="l43">
      <ol id="l44">
        <li data-list-text="20.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Op de Overeenkomst is uitsluitend Nederlands recht van toepassing.
          </p>
        </li>
        <li data-list-text="20.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Voor zover door de regels van dwingend recht niet anders wordt
            voorgeschreven, zullen alle geschillen tussen Partijen in verband
            met de Overeenkomst worden voorgelegd aan de bevoegde Nederlandse
            rechter voor het arrondissement waar Summit gevestigd is.
          </p>
        </li>
        <li data-list-text="20.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit is gerechtigd om zijn rechten en plichten die voortvloeien
            uit de Overeenkomst over te dragen aan een derde partij die Summit
            of zijn bedrijfsactiviteiten overneemt.
          </p>
        </li>
        <li data-list-text="20.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien enige bepaling uit de Overeenkomst nietig blijkt te zijn,
            tast dit niet de geldigheid van de gehele Overeenkomst aan. Partijen
            zullen in dat geval ter vervanging (een) nieuwe bepaling(en)
            vaststellen, waarmee zoveel als rechtens mogelijk is aan de
            bedoeling van de oorspronkelijke bepaling gestalte wordt gegeven.
          </p>
        </li>
        <li data-list-text="20.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever dient Summit onverwijld op de hoogte te stellen bij
            een wijziging in naam, (post)adres, e-mailadres, telefoonnummer en
            overige voor de uitvoering van de Overeenkomst relevante informatie
            en gegevens.
          </p>
        </li>
        <li data-list-text="20.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De door Summit opgeslagen logs en communicatie worden geacht juist
            te zijn, tenzij Opdrachtgever tegenbewijs levert dienaangaande.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Module A | verhuur Hardware
    </h1>
    <h2
      style="
        padding-top: 14pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Artikel 1. Verhuur Hardware
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Partijen kunnen overeenkomen dat Opdrachtgever de Hardware van Summit
      huurt. Indien de Overeenkomst (mede) betrekking heeft op de verhuur van de
      Hardware, gelden daarvoor de voorwaarden zoals in deze Module wordt
      omschreven.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Artikel 2. Hardware
    </h2>
    <ol id="l45">
      <ol id="l46">
        <li data-list-text="2.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit verleent hiertoe aan Opdrachtgever voor de duur en onder de
            voorwaarden van de Overeenkomst het recht op gebruik van de Hardware
            zoals vastgelegd in de Overeenkomst.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Opdrachtgever is zelf verantwoordelijk voor het verschaffen van de
            stroomvoorziening ten
          </p>
          <p
            style="
              padding-left: 41pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            behoeve van de (juiste) werking van de Hardware (“elektriciteit”).
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="
              padding-left: 41pt;
              text-indent: -35pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Opdrachtgever is verantwoordelijk voor het verschaffen van een
            aansluitmogelijkheid om de
          </p>
          <p style="padding-left: 41pt; text-indent: 0pt; text-align: left">
            Hardware te koppelen aan het internet en/of een lokaal netwerk
            (“netwerkaansluiting”).
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Enkel Summit is gerechtigd om de Hardware te beheren en om onderhoud
            of enige andere vorm van instandhouding of aanpassing van de
            Hardware uit te voeren.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            In het geval Opdrachtgever zonder voorafgaande expliciete
            toestemming van Summit wijzigingen aanbrengt in de Hardware is
            Opdrachtgever gehouden de door Summit gemaakte kosten te vergoeden
            ten behoeve van het verhelpen van fouten, problemen of anderszins.
          </p>
        </li>
        <li data-list-text="2.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Zonder expliciete toestemming van Summit, is Opdrachtgever niet
            gerechtigd om de Hardware te verhuren, onderverhuren of anderszins
            ter beschikking te stellen aan derden.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Artikel 3. Oplevering en installatie
    </h2>
    <ol id="l47">
      <ol id="l48">
        <li data-list-text="3.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Bij gebreke van een uitdrukkelijk door Partijen overeengekomen
            opleveringsdatum zal Summit de Hardware binnen een redelijke termijn
            beschikbaar maken.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Summit zal de Hardware opleveren wanneer dit in zijn professionele
            opinie voldoet aan de specificaties en/of geschikt is voor het
            overeengekomen gebruik.
          </p>
        </li>
        <li data-list-text="3.3.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Tenzij anders overeengekomen zal de Opdrachtgever de Hardware zelf
            op een door Opdrachtgever te bepalen locatie installeren en
            aansluiten.
          </p>
        </li>
        <li data-list-text="3.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien overeengekomen zal Summit de Hardware op de in de
            Overeenkomst bepaalde locatie installeren en aansluiten. Summit is
            gerechtigd de installatie van de Hardware op een bepaalde locatie te
            weigeren indien de installatie volgens zijn professionele opinie
            niet mogelijk is en/of de juiste werking van de Hardware niet kan
            worden gegarandeerd.
          </p>
        </li>
        <li data-list-text="3.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit heeft het recht derden in te schakelen ten behoeve van de
            installatie van de Hardware zoals bedoeld in het vorige lid.
          </p>
        </li>
        <li data-list-text="3.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever zal Summit of de door Summit ingeschakelde derde(n)
            toegang verschaffen tot de aangewezen locatie en de noodzakelijke
            medewerking verlenen ten behoeve van de installatie van de Hardware.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 4. Werking en garanties
    </h2>
    <ol id="l49">
      <ol id="l50">
        <li data-list-text="4.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever aanvaardt dat de Hardware alleen de functionaliteit en
            overige eigenschappen bevat zoals Opdrachtgever die aantreft in de
            Hardware op het moment van aflevering (“as is”), derhalve met alle
            zichtbare en onzichtbare fouten en gebreken, tenzij schriftelijk
            anders overeengekomen.
          </p>
        </li>
        <li data-list-text="4.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit zal zich inspannen om de Hardware voor Opdrachtgever
            beschikbaar te houden en om eventuele storingen en technische
            problemen zo spoedig mogelijk te verhelpen. Opdrachtgever kan enkel
            aanspraak maken op specifieke service levels, zoals een bepaalde
            beschikbaarheid of reactietijd, indien dit schriftelijk is
            overeengekomen.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Artikel 5. Risico en verzekering
    </h2>
    <ol id="l51">
      <ol id="l52">
        <li data-list-text="5.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Tenietgaan, verlies, slijtage, diefstal of beschadiging van de
            Hardware doet niets af aan de betalingsverplichtingen van
            Opdrachtgever. Verantwoordelijkheid voor eventuele reparaties of
            vervanging kunnen Partijen regelen in een als zodanig aangeduide
            service level agreement.
          </p>
        </li>
        <li data-list-text="5.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever dient er zorg voor te dragen dat de Hardware,
            gedurende de Overeenkomst, voor de nieuwwaarde is verzekerd tegen
            onder andere: tenietgaan, verlies, diefstal van of schade aan de
            Hardware. De met deze verzekering verbonden kosten komen geheel voor
            rekening voor de Opdrachtgever.
          </p>
        </li>
        <li data-list-text="5.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever dient er zorg voor te dragen dat Summit, in het kader
            van de voornoemde verzekering, is opgenomen als derde-begunstigde
            c.q. medeverzekerde. Indien het voornoemde redelijkerwijs niet
            mogelijk is, zal Opdrachtgever haar vordering op de betreffende
            verzekeraar cederen aan Summit.
          </p>
        </li>
        <li data-list-text="5.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Op verzoek van Summit zal Opdrachtgever kopieën van de betreffende
            verzekeringspolis verstrekken en/of bewijs leveren van volledige en
            tijdige premiebetaling.
          </p>
        </li>
        <li data-list-text="5.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit is in geen geval aansprakelijk voor schade ontstaan als het
            gevolg van het niet (juist) functioneren van de Hardware, tenzij er
            sprake is van opzet of grove schuld van de bedrijfsleiding van
            Summit.
          </p>
        </li>
        <li data-list-text="5.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever vrijwaart Summit voor alle aanspraken van derden
            (waaronder klanten van Opdrachtgever), ter zake van vergoeding van
            schade, kosten of rente, verband houdende met de Hardware,
            Overeenkomst en/of de Diensten.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 6. Eigendom van de Hardware
    </h2>
    <ol id="l53">
      <ol id="l54">
        <li data-list-text="6.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De Hardware is en blijft eigendom van Summit. Niets in de
            Overeenkomst is bedoeld om het eigendom op de Hardware over te
            dragen aan Opdrachtgever.
          </p>
        </li>
        <li data-list-text="6.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit zorgt ervoor dat de Hardware te allen tijde herkenbaar is als
            eigendom van Summit (bijvoorbeeld door het gebruik van labels). Deze
            markeringen en/of labels mogen niet door Opdrachtgever worden
            verwijderd.
          </p>
        </li>
        <li data-list-text="6.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien derden aanspraak maken op de Hardware of daar beslag op
            leggen, zal Opdrachtgever deze derden wijzen op het eigendomsrecht
            van Summit en Summit zo snel mogelijk (zowel schriftelijk als
            telefonisch) informeren over de aanspraak. In dit geval zal
            Opdrachtgever Summit in staat stellen om de Hardware onverwijld bij
            Opdrachtgever op te halen.
          </p>
        </li>
        <li data-list-text="6.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De voornoemde (informatie)plicht geldt ook indien Opdrachtgever het
            vermoeden heeft of redelijkerwijs moet vermoeden dat derden
            aanspraak zullen maken op de Hardware of daar beslag op zullen
            leggen. Een dergelijk vermoeden bestaat in ieder geval indien
            Opdrachtgever haar crediteuren (waaronder mede verstaan de
            Belastingdienst) niet meer kan voldoen of weet dat zij op korte
            termijn haar crediteuren niet meer zal kunnen voldoen.
          </p>
        </li>
        <li data-list-text="6.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien beslag is gelegd op de Hardware door crediteuren van
            Opdrachtgever of vanwege een geschil waarbij Opdrachtgever betrokken
            is, dan ontslaat dit Opdrachtgever niet van haar
            betalingsverplichtingen.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 7. Retourneren
    </h2>
    <ol id="l55">
      <ol id="l56">
        <li data-list-text="7.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien de Overeenkomst wordt beëindigd of ontbonden, zal
            Opdrachtgever de door Summit geleverde Hardware zo spoedig mogelijk
            conform instructies van Summit en binnen vijf (5) werkdagen
            retourneren aan Summit.
          </p>
        </li>
        <li data-list-text="7.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De kosten voor retournering van de Hardware om welke redenen dan ook
            komen voor rekening van Opdrachtgever.
          </p>
        </li>
        <li data-list-text="7.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever draagt zorg voor de juiste verpakking van de Hardware
            en verzending middels verzekerd transport.
          </p>
        </li>
        <li data-list-text="7.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien bij ontvangst van de Hardware door Summit blijkt dat de
            Hardware in verminderde staat verkeerd, is Summit gerechtigd de
            kosten voor reparatie in rekening te brengen bij Opdrachtgever.
          </p>
        </li>
      </ol>
    </ol>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      Artikel 8. Uitsluiting
    </h2>
    <ol id="l57">
      <ol id="l58">
        <li data-list-text="8.1.">
          <p style="padding-left: 41pt; text-indent: -35pt; text-align: left">
            Voor zover wettelijk is toegestaan, komen Partijen overeen dat de
            artikelen 7:203 tot en met 7:211 van het Burgerlijk Wetboek zijn
            uitgesloten.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Module B | verkoop Hardware
    </h1>
    <h2
      style="
        padding-top: 13pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Artikel 1. Verkoop Hardware
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Partijen kunnen overeenkomen dat Opdrachtgever Hardware koopt van Summit.
      Indien de Overeenkomst (mede) betrekking heeft op de verkoop van Hardware,
      gelden daarvoor de voorwaarden zoals in deze Module wordt omschreven.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 2. Levering van Hardware
    </h2>
    <ol id="l59">
      <ol id="l60">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Tenzij anders overeengekomen, wordt Hardware door Summit af fabriek
            geleverd. Het risico op verlies of beschadiging van Hardware gaat
            over op Opdrachtgever op het moment van juridische en/of feitelijke
            levering.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever zal geleverde Hardware zo spoedig mogelijk, maar in
            ieder geval binnen 7 kalenderdagen, controleren op eventuele
            beschadigingen of andere gebreken. Indien Opdrachtgever
            beschadigingen of andere gebreken vaststelt, zal Opdrachtgever
            bewijs van de beschadigingen of andere gebreken vastleggen en Summit
            hier direct over informeren. Een melding van beschadigingen of
            andere gebreken schort de betalingsverplichting van Opdrachtgever
            niet op.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Opdrachtgever binnen de voornoemde termijn geen
            beschadigingen of andere gebreken kenbaar maakt aan Summit, wordt de
            Hardware geacht in goede staat te zijn ontvangen.
          </p>
        </li>
        <li data-list-text="2.4.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Op de door Opdrachtgever bij Summit gekochte Hardware rust
            fabrieksgarantie. Informatie over wat fabrieksgarantie inhoudt,
            staat op de website dan wel de bijgeleverde documentatie van de
            fabrikant.
          </p>
        </li>
        <li data-list-text="2.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Summit zal haar wettelijke verplichting in het kader van
            conformiteit van geleverde Hardware nakomen, maar verleent
            Opdrachtgever geen (extra) garantie op door haar geleverde Hardware.
          </p>
        </li>
        <li data-list-text="2.6.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            De (wettelijke) garantie geldt niet:
          </p>
          <ol id="l61">
            <li data-list-text="(1)">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                indien de fouten het gevolg zijn van onoordeelkundig gebruik of
                van andere oorzaken dan ondeugdelijkheid van materiaal of
                fabricage;
              </p>
            </li>
            <li data-list-text="(2)">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  line-height: 13pt;
                  text-align: justify;
                "
              >
                indien gegeven instructies, voor het gebruik van de Hardware,
                niet zijn nagekomen;
              </p>
            </li>
            <li data-list-text="(3)">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                voor de toestand van de Hardware, ontstaan ten gevolge van
                ondeskundig opslaan, of klimatologische of andere inwerkingen.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2.7.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Het retourneren van Hardware is enkel mogelijk na voorafgaande
            toestemming van Summit en met inachtneming van de procedures en
            instructies van Summit. Indien Opdrachtgever Hardware wil
            retourneren aan Summit, dient Opdrachtgever de Hardware zoveel
            mogelijk in de oorspronkelijke staat van levering aan Summit te
            retourneren. Indien er kosten verbonden zijn aan het retourneren van
            Hardware, dan zijn die voor rekening van Opdrachtgever.
          </p>
        </li>
        <li data-list-text="2.8.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Indien Summit gehouden is om reparaties of vervangende leveringen
            uit te voeren, dan wordt Summit altijd een redelijke termijn gegund
            om dergelijke reparaties of vervangende leveringen uit te voeren.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Artikel 3. Eigendomsvoorbehoud
    </h2>
    <ol id="l62">
      <ol id="l63">
        <li data-list-text="3.1.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Alle Hardware die Summit aan Opdrachtgever levert, blijft eigendom
            van Summit, tot het moment waarop Opdrachtgever al haar
            betaalverplichtingen jegens Summit deugdelijk is nagekomen.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Alle door Summit geleverde Hardware, die onder het
            eigendomsvoorbehoud uit het vorige lid van dit artikel vallen, mogen
            door Opdrachtgever niet worden doorverkocht, als betaalmiddel worden
            gebruikt, worden verpand of op enige andere wijze bezwaard.
          </p>
        </li>
        <li data-list-text="3.3.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever dient al hetgeen te doen dat redelijkerwijs van haar
            verwacht mag worden om de eigendomsrechten van Summit veilig te
            stellen.
          </p>
        </li>
        <li data-list-text="3.4.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 41pt;
              text-indent: -35pt;
              text-align: justify;
            "
          >
            Indien een derde partij beslag wil (laten) leggen op Hardware die
            onder eigendomsvoorbehoud is geleverd, dan wel daarop rechten wil
            vestigen of laten gelden, dan stelt Opdrachtgever Summit daarvan
            direct op de hoogte.
          </p>
        </li>
        <li data-list-text="3.5.">
          <p
            style="padding-left: 41pt; text-indent: -35pt; text-align: justify"
          >
            Opdrachtgever geeft bij voorbaat uitdrukkelijke en onvoorwaardelijke
            toestemming aan Summit en door Summit aan te wijzen derden om de
            plaatsen, waar de eigendommen van Summit zich bevinden, te betreden
            en de Hardware terug te nemen indien Opdrachtgever haar
            betalingsverplichting niet nakomt.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Bijlage 1. Verwerkersovereenkomst
    </h1>
    <p
      style="
        padding-top: 14pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      De Verwerkersovereenkomst in deze Bijlage 1 en de daarin opgenomen
      afspraken hebben betrekking op de verwerking van persoonsgegevens van
      Opdrachtgever door Summit.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 1. DEFINITIES
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      De in deze Verwerkersovereenkomst opgenomen woorden (zoals
      “persoonsgegevens”, “verwerken” en “betrokkene”) hebben, in welke
      vervoeging dan ook, de betekenis zoals bedoeld in de toepasselijke
      (privacy) wet- en regelgeving, waaronder de AVG. Daarnaast hebben de
      onderstaande woorden in deze Verwerkersovereenkomst, steeds aangeduid met
      een hoofdletter, zowel in enkelvoud als in meervoud, de betekenis zoals
      beschreven in dit artikel.
    </p>
    <ol id="l64">
      <ol id="l65">
        <li data-list-text="1.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            AVG: verordening (EU) 2016/679, ook wel de Algemene Verordening
            Gegevensbescherming genoemd.
          </p>
        </li>
        <li data-list-text="1.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Datalek: een inbreuk op de beveiliging die per ongeluk of op
            onrechtmatige wijze leidt tot de vernietiging, het verlies, de
            wijziging of de ongeoorloofde verstrekking van of de ongeoorloofde
            toegang tot doorgezonden, opgeslagen of anderszins verwerkte
            gegevens.
          </p>
        </li>
        <li data-list-text="1.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Dienst(en): de diensten die Verwerker op grond van de Overeenkomst
            levert aan Verantwoordelijke.
          </p>
        </li>
        <li data-list-text="1.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verantwoordelijke: Opdrachtgever.
          </p>
        </li>
        <li data-list-text="1.5.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker: Summit.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        line-height: 13pt;
        text-align: left;
      "
    >
      ARTIKEL 2. DOELEINDEN VAN DE VERWERKING
    </h2>
    <ol id="l66">
      <ol id="l67">
        <li data-list-text="2.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker verbindt zich onder de voorwaarden van deze
            Verwerkersovereenkomst in opdracht van Verantwoordelijke
            persoonsgegevens te verwerken. Verwerking zal uitsluitend
            plaatsvinden in het kader van de Overeenkomst en een goede
            uitvoering van de daarin overeengekomen Diensten ten behoeve van
            Verantwoordelijke, en die doeleinden die daar redelijkerwijs mee
            samenhangen of die met nadere instemming worden bepaald.
          </p>
        </li>
        <li data-list-text="2.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De persoonsgegevens die door Verwerker in het kader van de
            Overeenkomst (zullen) worden verwerkt, en de categorieën betrokkenen
            van wie deze afkomstig zijn, zijn opgenomen in bijlage
          </p>
          <p style="padding-left: 34pt; text-indent: 0pt; text-align: justify">
            2. Verwerker zal de persoonsgegevens niet voor enig ander doel
            verwerken dan zoals door de Verantwoordelijke is vastgesteld. De
            Verantwoordelijke zal Verwerker op de hoogte stellen van de
            verwerkingsdoeleinden voor zover deze niet reeds in deze
            Verwerkersovereenkomst zijn genoemd.
          </p>
        </li>
        <li data-list-text="2.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Eventuele (eigendoms)rechten op de persoonsgegevens die Verwerker
            voor de Verantwoordelijke verwerkt, blijven rusten bij de
            Verantwoordelijke en/of de betreffende betrokkenen.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 3. VERPLICHTINGEN VERWERKER
    </h2>
    <ol id="l68">
      <ol id="l69">
        <li data-list-text="3.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Ten aanzien van de in artikel 2 genoemde verwerkingen zal Verwerker
            zich naar beste kunnen inspannen om de op Verwerker van toepassing
            zijnde wet- en regelgeving op het gebied van de bescherming van
            persoonsgegevens na te leven. Verwerker zal de Verantwoordelijke, op
            diens eerste verzoek daartoe, informeren over de door haar genomen
            maatregelen aangaande haar verplichtingen onder deze
            Verwerkersovereenkomst.
          </p>
        </li>
        <li data-list-text="3.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De verplichtingen van Verwerker die uit deze Verwerkersovereenkomst
            voortvloeien, gelden ook voor degenen die persoonsgegevens verwerken
            onder het gezag van Verwerker, waaronder begrepen maar niet beperkt
            tot werknemers.
          </p>
        </li>
        <li data-list-text="3.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker zal de Verantwoordelijke de noodzakelijke en
            redelijkerwijs mogelijke medewerking verlenen wanneer er in het
            kader van de verwerking een gegevensbeschermingseffectbeoordeling
            (ook wel een ‘Privacy Impact Assessment’ genoemd) noodzakelijk is.
            Tevens zal Verwerker op verzoek van Verantwoordelijke redelijkerwijs
            medewerking verlenen aan het afhandelen van een verzoek van een
            betrokkene zoals bedoeld
          </p>
        </li>
      </ol>
    </ol>
    <p
      style="
        padding-top: 1pt;
        padding-left: 34pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      in artikel 8. Eventuele kosten die Verwerker in dit kader moet maken komen
      redelijkerwijs voor rekening van de Verantwoordelijke.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 4. DOORGIFTE VAN PERSOONSGEGEVENS
    </h2>
    <ol id="l70">
      <ol id="l71">
        <li data-list-text="4.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verantwoordelijke geeft Verwerker toestemming om, in het kader van
            de uitvoering van de Hoofd- of Verwerkersovereenkomst, de
            persoonsgegevens te laten verwerken door derden (ook wel
            onderaannemers of sub-verwerkers genoemd). Onder deze toestemming
            valt in ieder geval de toestemming aan Verwerker om de
            persoonsgegevens te laten verwerken door een geschikte derde die de
            Dienst van Verwerker zal hosten. Op verzoek van de Verantwoordelijke
            zal Verwerker de Verantwoordelijke informeren over de door haar
            ingeschakelde derden.
          </p>
        </li>
        <li data-list-text="4.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker verwerkt de persoonsgegevens uitsluitend binnen de landen
            in de Europese Economische Ruimte (EER), of bij derden die zijn
            gevestigd in landen met een passend beschermingsniveau zoals erkend
            door de Europese Commissie. Op verzoek van Verantwoordelijke, zal
            Verwerker melden om welk land of landen het gaat.
          </p>
        </li>
        <li data-list-text="4.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien Verwerker de intentie heeft om nieuwe derden in te schakelen
            in het kader van het verwerken van persoonsgegevens onder deze
            Verwerkersovereenkomst, dan zal Verwerker de Verantwoordelijke
            hierover informeren op eigen initiatief. Vervolgens heeft de
            Verantwoordelijke twee weken de tijd om bezwaar te maken tegen het
            inschakelen van de nieuwe derde. Dit bezwaar dient schriftelijk en
            door argumenten ondersteund te worden ingediend.
          </p>
        </li>
        <li data-list-text="4.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien de Verantwoordelijke bezwaar maakt zoals hiervoor bedoeld,
            dan zullen Partijen in goed overleg op zoek gaan naar een redelijke
            oplossing. Mocht het voorkomen dat Partijen gezamenlijk niet tot een
            oplossing kunnen komen, dan is Verwerker gerechtigd om de nieuwe
            derden alsnog in te schakelen. In dit geval is de Verantwoordelijke
            gerechtigd om de Hoofd- en Verwerkersovereenkomst op te zeggen tegen
            de datum waarop de betreffende nieuwe derde door Verwerker wordt
            ingeschakeld.
          </p>
        </li>
        <li data-list-text="4.5.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien de Verantwoordelijke geen bezwaar maakt binnen de termijn van
            twee weken zoals bedoeld in artikel 4.3., dan is Verwerker
            gerechtigd om de betreffende nieuwe derde in te schakelen.
          </p>
        </li>
        <li data-list-text="4.6.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien Verwerker een derde inschakelt bij het verwerken van
            persoonsgegevens, zal Verwerker zich inspannen om deze derde voor
            zover relevant wezenlijk dezelfde verplichtingen op te leggen als de
            verplichtingen die voor Verwerker uit deze Verwerkersovereenkomst
            voortvloeien.
          </p>
        </li>
        <li data-list-text="4.7.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker is verantwoordelijk voor de derden die zij op eigen
            initiatief heeft ingeschakeld en is bij fouten van deze derden zelf
            jegens de Verantwoordelijke aansprakelijk voor schade alsof
            Verwerker zelf de fout(en) heeft begaan.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 5. VERDELING VAN VERANTWOORDELIJKHEID
    </h2>
    <ol id="l72">
      <ol id="l73">
        <li data-list-text="5.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Onder de uitdrukkelijke (eind)verantwoordelijkheid van
            Verantwoordelijke en binnen de doeleinden zoals omschreven in
            artikel 2, is Verwerker louter verantwoordelijk voor de verwerking
            van persoonsgegevens via de door haar onder de Overeenkomst
            aangeboden Dienst. Voor de overige verwerkingen van
            persoonsgegevens, waaronder in ieder geval begrepen maar niet
            beperkt tot de verzameling van de persoonsgegevens door de
            Verantwoordelijke en/of derden, verwerkingen voor doeleinden die
            niet door Verantwoordelijke aan Verwerker zijn gemeld, verwerkingen
            door derden en/of voor andere doeleinden, is Verwerker uitdrukkelijk
            niet verantwoordelijk.
          </p>
        </li>
        <li data-list-text="5.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De verantwoordelijkheid voor de persoonsgegevens die met
            gebruikmaking van een door Verwerker verleende Dienst worden
            verwerkt, ligt bij Verantwoordelijke. Het is aan de
            Verantwoordelijke om te bepalen of zij de persoonsgegevens onder de
            overeengekomen voorwaarden door Verwerker mag laten verwerken. De
            Verantwoordelijke garandeert dat de inhoud, het gebruik en de
            opdracht tot de verwerkingen van de persoonsgegevens zoals bedoeld
            in de Hoofd- en Verwerkersovereenkomst, niet onrechtmatig zijn en
            geen inbreuk maken op enig recht van derden. De Verantwoordelijke
            vrijwaart Verwerker voor alle aanspraken van
          </p>
        </li>
      </ol>
    </ol>
    <p
      style="
        padding-top: 1pt;
        padding-left: 34pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      derden die voortvloeien uit het niet voldoen aan de voornoemde garantie
      door de Verantwoordelijke.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 6. BEVEILIGING
    </h2>
    <ol id="l74">
      <ol id="l75">
        <li data-list-text="6.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker zal zich inspannen passende technische en organisatorische
            maatregelen te nemen met betrekking tot de te verrichten
            verwerkingen van persoonsgegevens, teneinde de persoonsgegevens te
            beschermen tegen verlies of tegen enige vorm van onrechtmatige
            verwerking (zoals onbevoegde kennisname, aantasting, wijziging of
            verstrekking van de persoonsgegevens).
          </p>
        </li>
        <li data-list-text="6.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Partijen erkennen dat het onmogelijk is om beveiligingsmaatregelen
            te treffen die onder alle omstandigheden doeltreffend zijn.
            Verwerker zal zich er naar beste kunnen voor inspannen dat de
            beveiligingsmaatregelen voldoen aan een niveau dat, gelet op de
            stand van de techniek, de gevoeligheid van de persoonsgegevens en de
            aan het treffen van de beveiligingsmaatregelen verbonden kosten,
            passend is.
          </p>
        </li>
        <li data-list-text="6.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker zal in bijlage 2 beschrijven welke (groepen) medewerkers
            toegang tot de persoonsgegevens hebben en welke verwerkingen door de
            betreffende medewerkers zijn toegestaan.
          </p>
        </li>
        <li data-list-text="6.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verantwoordelijke beslist welke persoonsgegevens precies door
            Verwerker verwerkt worden en stelt enkel persoonsgegevens aan
            Verwerker ter beschikking voor verwerking, indien Verantwoordelijke
            zich ervan heeft verzekerd dat de volgens Verantwoordelijke vereiste
            beveiligingsmaatregelen zijn getroffen.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 7. MELDPLICHT
    </h2>
    <ol id="l76">
      <ol id="l77">
        <li data-list-text="7.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien Verwerker een Datalek heeft geconstateerd, dan zal Verwerker
            de Verantwoordelijke daarover onverwijld, maar in ieder geval binnen
            48 uur, informeren, naar aanleiding waarvan de Verantwoordelijke
            beoordeelt of zij de betrokkene(n) en/of de relevante
            toezichthouder(s) zal informeren of niet.
          </p>
        </li>
        <li data-list-text="7.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien Verwerker een Datalek heeft geconstateerd, dan zal Verwerker
            alle redelijke maatregelen treffen om (verdere) onrechtmatige
            verwerking te voorkomen of te beperken.
          </p>
        </li>
        <li data-list-text="7.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De kennisgeving van Verwerker aan de Verantwoordelijke omvat het
            feit dat er een Datalek is geweest. Op verzoek van Verantwoordelijke
            verstrekt Verwerker de procedure die Verwerker hanteert ten aanzien
            van de omgang met een Datalek alsmede de gegevens die Verwerker zal
            verschaffen.
          </p>
        </li>
        <li data-list-text="7.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Op verzoek van Verantwoordelijke en indien nodig zal Verwerker
            redelijkerwijs meewerken aan het informeren van de betrokkene(n)
            en/of de relevante toezichthouder(s).
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 8. AFHANDELING VERZOEKEN VAN BETROKKENEN
    </h2>
    <ol id="l78">
      <ol id="l79">
        <li data-list-text="8.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            In het geval dat een betrokkene een verzoek tot inzage, verbetering,
            aanvulling, wijziging of afscherming van zijn persoonsgegevens richt
            aan Verwerker, zal Verwerker het verzoek doorsturen aan
            Verantwoordelijke, en zal Verantwoordelijke het verzoek verder
            afhandelen. Verwerker mag de betrokkene hiervan op de hoogte
            stellen.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 9. GHEIMHOUDING EN VERTROUWELIJKHEID
    </h2>
    <ol id="l80">
      <ol id="l81">
        <li data-list-text="9.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Op alle persoonsgegevens die Verwerker in opdracht van
            Verantwoordelijke verwerkt rust een geheimhoudingsplicht jegens
            derden.
          </p>
        </li>
        <li data-list-text="9.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Deze geheimhoudingsplicht is niet van toepassing: (i) voor zover de
            Verantwoordelijke uitdrukkelijke toestemming heeft gegeven om de
            persoonsgegevens aan derden te verschaffen,
          </p>
          <p style="padding-left: 34pt; text-indent: 0pt; text-align: justify">
            (ii) indien het verstrekken van de persoonsgegevens aan derden
            logischerwijs noodzakelijk is gezien de aard van de verstrekte
            opdracht en de uitvoering van de Hoofd – en Verwerkersovereenkomst,
            (iii) indien persoonsgegevens worden verstrekt aan derden in hun
            hoedanigheid als onderaannemer / sub-verwerker, of (iv) indien er
            een wettelijke verplichting bestaat om de persoonsgegevens aan een
            derde te verstrekken.
          </p>
        </li>
        <li data-list-text="9.3.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 34pt;
              text-indent: -28pt;
              text-align: justify;
            "
          >
            Indien Verwerker op grond van een wettelijke verplichting gehouden
            is om persoonsgegevens aan een derde te verstrekken, zal zij de
            Verantwoordelijke hiervan vooraf op de hoogte stellen en de
            Verantwoordelijke redelijkerwijs de tijd gunnen om rechtsmiddelen in
            te zetten teneinde het verstrekken van persoonsgegevens tegen te
            gaan, tenzij dit verboden is onder de betreffende wetgeving.
          </p>
        </li>
        <li data-list-text="9.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien Verwerker van de diensten van derden gebruik maakt, zorgt
            Verwerker ervoor dat deze derden ook een geheimhoudingsplicht
            overeenkomen met Verwerker.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 10. AUDIT
    </h2>
    <ol id="l82">
      <ol id="l83">
        <li data-list-text="10.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verantwoordelijke heeft het recht om audits te laten uitvoeren door
            een onafhankelijke ICT-deskundige ter controle van naleving van alle
            punten uit deze Verwerkersovereenkomst.
          </p>
        </li>
        <li data-list-text="10.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Deze audit mag eens per twee jaar plaatsvinden. Minimaal twee weken
            voorafgaande aan de voorgenomen audit, zal de Verantwoordelijke
            Verwerker hierover informeren.
          </p>
        </li>
        <li data-list-text="10.3.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De onafhankelijke ICT-deskundige die de audit zal uitvoeren dient
            zich te conformeren aan de beveiligingsprocedures zoals die bij
            Verwerker van kracht zijn. Voorts moet een adequate geheimhouding
            met deze ICT-deskundige overeengekomen te worden.
          </p>
        </li>
        <li data-list-text="10.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verwerker zal aan de audit meewerken en alle voor de audit
            redelijkerwijs relevante informatie, inclusief ondersteunende
            gegevens zoals systeemlogs, en medewerkers zo tijdig mogelijk ter
            beschikking stellen.
          </p>
        </li>
        <li data-list-text="10.5.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De bevindingen naar aanleiding van de uitgevoerde audit zullen door
            Partijen in onderling overleg worden beoordeeld en, naar aanleiding
            daarvan, al dan niet worden doorgevoerd door één van de Partijen of
            door beide Partijen gezamenlijk.
          </p>
        </li>
        <li data-list-text="10.6.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Alle kosten in verband met een audit worden door Verantwoordelijke
            gedragen.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 11. WIJZIGING
    </h2>
    <ol id="l84">
      <ol id="l85">
        <li data-list-text="11.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Indien een wijziging in de te verwerken persoonsgegevens of een
            risicoanalyse van de verwerking van persoonsgegevens daartoe
            aanleiding geeft, treden Partijen op eerste verzoek van
            Verantwoordelijke in overleg over het aanpassen van de gemaakte
            afspraken binnen deze Verwerkersovereenkomst.
          </p>
        </li>
        <li data-list-text="11.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Deze Verwerkersovereenkomst kan enkel schriftelijk en met instemming
            van beide Partijen gewijzigd worden. Wanneer Partijen nieuwe
            afspraken zijn overeengekomen, dan zullen Partijen deze nieuwe
            afspraken toevoegen aan deze Verwerkersovereenkomst.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 12. DUUR EN BEËINDIGING
    </h2>
    <ol id="l86">
      <ol id="l87">
        <li data-list-text="12.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Deze Verwerkersovereenkomst komt tot stand door ondertekening van de
            Overeenkomst door Partijen en op de datum van de laatste
            ondertekening.
          </p>
        </li>
        <li data-list-text="12.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            De duur van de Verwerkersovereenkomst is gelijk aan de duur van de
            Overeenkomst. De Verwerkersovereenkomst is niet los van de
            Overeenkomst te beëindigen. Wanneer de Overeenkomst om welke reden
            dan ook ten einde komt, dan komt deze Verwerkersovereenkomst ook
            automatisch ten einde.
          </p>
        </li>
        <li data-list-text="12.3.">
          <p
            style="
              padding-left: 34pt;
              text-indent: -28pt;
              line-height: 13pt;
              text-align: justify;
            "
          >
            Bij beëindiging van de Verwerkersovereenkomst om welke reden ook zal
            Verwerker op verzoek:
          </p>
          <ol id="l88">
            <li data-list-text="a.">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                de persoonsgegevens, die Verwerker in opdracht van
                Verantwoordelijke heeft verwerkt en waarover Verwerker nog
                beschikt, in originele of kopievorm ter beschikking stellen aan
                de Verantwoordelijke in het bestandsformaat waarin het bij
                Verwerker was opgeslagen; of
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style="
                  padding-left: 77pt;
                  text-indent: -18pt;
                  text-align: justify;
                "
              >
                de persoonsgegevens, die Verwerker in opdracht van
                Verantwoordelijke heeft verwerkt en waarover Verwerker nog
                beschikt, vernietigen.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="12.4.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Partijen zullen zich inspannen om, voor zover redelijkerwijs
            mogelijk, voorafgaand aan de werkzaamheden zoals genoemd in artikel
            12.3. een redelijke vergoeding voor de betreffende werkzaamheden
            overeen te komen. Wanneer Partijen geen vergoeding zijn
            overeengekomen, is Verwerker gerechtigd om een marktconforme
            vergoeding in rekening te brengen bij Verantwoordelijke.
          </p>
        </li>
        <li data-list-text="12.5.">
          <p
            style="
              padding-top: 1pt;
              padding-left: 34pt;
              text-indent: -28pt;
              text-align: justify;
            "
          >
            Indien Verantwoordelijke geen verzoek indient bij Verwerker om één
            van de werkzaamheden zoals genoemd in artikel 12.3. uit te voeren,
            dan is Verwerker vanaf één maand na het ten einde komen van de
            Verwerkersovereenkomst gerechtigd om de betreffende persoonsgegevens
            van haar systemen te verwijderen.
          </p>
        </li>
        <li data-list-text="12.6.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Verplichtingen welke naar hun aard bestemd zijn om ook na het ten
            einde komen van deze Verwerkersovereenkomst voort te duren, blijven
            na het ten einde komen van deze Verwerkersovereenkomst gelden. Tot
            deze bepalingen behoren onder meer die welke voortvloeien uit de
            bepalingen betreffende geheimhouding en toepasselijk recht.
          </p>
        </li>
      </ol>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      ARTIKEL 13. OVERIGE
    </h2>
    <ol id="l89">
      <ol id="l90">
        <li data-list-text="13.1.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Deze Verwerkersovereenkomst maakt integraal onderdeel uit van en
            wordt aangevuld door de Overeenkomst. Dit betekent onder meer dat de
            beperking van aansprakelijkheid, zoals overeengekomen in de
            Overeenkomst en de bijbehorende algemene voorwaarden van Verwerker,
            mede van toepassing is op deze Verwerkersovereenkomst.
          </p>
        </li>
        <li data-list-text="13.2.">
          <p
            style="padding-left: 34pt; text-indent: -28pt; text-align: justify"
          >
            Logs en metingen gedaan door Verwerker gelden als dwingend bewijs,
            behoudens tegenbewijs te leveren door Verantwoordelijke.
          </p>
        </li>
      </ol>
    </ol>
    <h1 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Bijlage 2. Specificatie persoonsgegevens
    </h1>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      De te verwerken (categorieën) persoonsgegevens
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      De volgende (categorieën) persoonsgegevens verwerkt Verwerker in opdracht
      van Verantwoordelijke.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <table
      style="border-collapse: collapse; margin-left: 6.074pt"
      cellspacing="0"
    >
      <tr style="height: 27pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Functie(groep)
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            (Categorie) Persoonsgegevens
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Type verwerking
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            NAW-gegevens
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Naam, adres en woonplaats
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Vastleggen, bewaren en
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              line-height: 14pt;
              text-align: left;
            "
          >
            gebruiken voor analyse en follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Contactgegevens
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Telefoonnummer, e-mailadres
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Vastleggen, bewaren en gebruiken voor analyse en
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Profiel-gegevens
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Bijvoorbeeld: functie, vooropleiding
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Vastleggen, bewaren en gebruiken voor analyse en
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Interesse
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Bijvoorbeeld: sessiekeuze, opleidingsinteresse
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left"
          >
            Vastleggen, bewaren en gebruiken voor analyse en
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            follow-up
          </p>
        </td>
      </tr>
      <tr style="height: 41pt">
        <td
          style="
            width: 107pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Overige
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 14pt;
              text-align: left;
            "
          >
            identificerende gegevens
          </p>
        </td>
        <td
          style="
            width: 180pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Geslacht, geboortedatum
          </p>
        </td>
        <td
          style="
            width: 140pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Vastleggen, bewaren en
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              line-height: 14pt;
              text-align: left;
            "
          >
            gebruiken voor analyse en follow-up
          </p>
        </td>
      </tr>
    </table>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Voor alle hierboven gemelde gegevens geldt dat de Verantwoordelijke per
      registratie van een activiteit, bijeenkomst of evenement bepaalt of deze
      gegevens worden gevraagd en, indien zij worden gevraagd, deze al dan niet
      optioneel beantwoord hoeven te worden door de gebruiker.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Er worden geen bijzondere persoonsgegevens (zoals persoonsgegevens met
      betrekking tot religie, seksuele geaardheid, gezondheid of financiën)
      verwerkt.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Persoonsgegevens worden door Verwerker niet langer bewaard dan
      noodzakelijk is voor de uitvoering van de Hoofd- en Verwerkersovereenkomst
      of om een op hem rustende wettelijke verplichting na te komen. Voor
      persoonsgegevens die worden verwerkt ten behoeve van de juiste werking van
      de Dienst (logging, back-up voorzieningen etc.) gelden de volgende
      bewaartermijnen:
    </p>
    <ul id="l91">
      <li data-list-text="">
        <p style="padding-left: 41pt; text-indent: -18pt; text-align: left">
          1 jaar, tenzij Partijen anders overeenkomen.
        </p>
        <p style="text-indent: 0pt; text-align: left"><br /></p>
        <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          Categorieën betrokkenen
        </h2>
        <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          De eerder genoemde persoonsgegevens worden verwerkt van de volgende
          categorieën betrokkenen:
        </p>
        <ul id="l92">
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              medewerkers van Verantwoordelijke die de Dienst gebruiken;
            </p>
          </li>
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              bezoekers van een evenement georganiseerd door Verantwoordelijke;
            </p>
          </li>
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              personen die geïnteresseerd zijn in of zich ingeschreven hebben
              voor het evenement van Verantwoordelijke.
            </p>
          </li>
          <li data-list-text="-">
            <p style="padding-left: 59pt; text-indent: -18pt; text-align: left">
              Personen (studiekiezers) die geïnteresseerd zijn in het volgen van
              een opleiding bij Verantwoordelijke
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      "
    >
      Functierollen/ functiegroepen en hun verwerkingen
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      In de volgende tabel staan de functierollen en/of functiegroepen van de
      medewerkers van Verwerker en Verantwoordelijke vermeld die toegang hebben
      tot bepaalde persoonsgegevens. Tevens staat vermeld welke verwerkingen zij
      ten aanzien van de persoonsgegevens mogen uitvoeren.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <table
      style="border-collapse: collapse; margin-left: 6.074pt"
      cellspacing="0"
    >
      <tr style="height: 27pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          bgcolor="#A6A6A6"
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Functie(groep)
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          bgcolor="#A6A6A6"
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            (Categorie)
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            Persoonsgegevens
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          bgcolor="#A6A6A6"
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Type verwerking
          </p>
        </td>
      </tr>
      <tr style="height: 81pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 63pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Afdeling Project Management
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Alle persoonsgegevens, zoals vermeld in bijlage A
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            PM-medewerkers (Project Management) van Verwerker hebben toegang tot
            de persoonsgegevens ten behoeve van de advisering van de medewerkers
            van de Verantwoordelijke bij inhoudelijke vragen en hulp bij de
            analyse van gegevens in opdracht van
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: left;
            "
          >
            Verantwoordelijke.
          </p>
        </td>
      </tr>
      <tr style="height: 108pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Afdeling IT
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Alle persoonsgegevens, zoals vermeld in bijlage A
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            IT-medewerkers van Verwerker ten behoeve van het ontwikkelen van
            software, updaten, testen en de admin-functie allen gericht op
            continuïteit en optimalisatie van de systemen. Daarnaast zijn zij
            beschikbaar als technische helpdesk en verantwoordelijk voor de
            totstandkoming en het beheer van eventuele koppelingen.
          </p>
        </td>
      </tr>
      <tr style="height: 162pt">
        <td
          style="
            width: 142pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 8pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Geautoriseerde medewerkers Verantwoordelijke
          </p>
        </td>
        <td
          style="
            width: 112pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Alle persoonsgegevens, zoals vermeld in bijlage A
          </p>
        </td>
        <td
          style="
            width: 248pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-left: 5pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            Vanzelfsprekend hebben ook medewerkers van de Verantwoordelijke zelf
            toegang tot de persoonsgegevens. Gebruikers van de Dienst hebben
            toegang tot het dashboard. Hier worden alle gegevens waarvoor zij
            geautoriseerd zijn voor hen ontsloten. Gebruikers zijn altijd
            gekoppeld aan de Verantwoordelijke en kunnen geen toegang krijgen
            tot het dashboard van andere opdrachtgevers van Verwerker. De door
            de Verantwoordelijke aangewezen beheerders van Verwerker hebben de
            machtigingen om te bepalen welke gegevens via het
          </p>
          <p
            class="s6"
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 12pt;
              text-align: justify;
            "
          >
            dashboard worden ontsloten.
          </p>
        </td>
      </tr>
    </table>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2
      style="
        padding-left: 5pt;
        text-indent: 0pt;
        line-height: 13pt;
        text-align: justify;
      "
    >
      Getroffen beveiligingsmaatregelingen
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Verwerker zal passende beveiligingsmaatregelen nemen en de
      Verantwoordelijke hier, op verzoek, een overzicht van toesturen.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      In het geval u beveiligingsrisico’s constateert, dan verzoeken wij u
      direct contact op te nemen met
    </p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Verwerker (Summit) via 030-2307940.
    </p>
    <h2
      style="
        padding-top: 1pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      "
    >
      Sub-verwerkers
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Verwerker heeft toestemming van Verantwoordelijke om derden in te zetten
      bij het verwerken van persoonsgegevens. Verwerker schakelt de volgende
      derde(n) in:
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <ul id="l93">
      <li data-list-text="-">
        <p
          style="
            padding-left: 41pt;
            text-indent: -18pt;
            line-height: 14pt;
            text-align: justify;
          "
        >
          Naam organisatie: Leaseweb.
        </p>
      </li>
      <li data-list-text="-">
        <p
          style="
            padding-left: 41pt;
            text-indent: -18pt;
            line-height: 14pt;
            text-align: justify;
          "
        >
          Korte omschrijving dienstverlening: hostingprovider voor de database
          servers van Verwerker.
        </p>
      </li>
      <li data-list-text="-">
        <p style="padding-left: 41pt; text-indent: -18pt; text-align: justify">
          Mate van verwerking Persoonsgegevens: Leaseweb is enkel
          verantwoordelijk voor de hosting van de servers van Verwerker en is
          verder in op geen enkele manier verder betrokken bij de verwerking van
          persoonsgegevens.
        </p>
      </li>
      <li data-list-text="-">
        <p
          style="
            padding-left: 41pt;
            text-indent: -18pt;
            line-height: 14pt;
            text-align: justify;
          "
        >
          Plaats/Land van verwerking gegevens: Amsterdam, Nederland.
        </p>
      </li>
    </ul>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
      Contactgegevens
    </h2>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
      Verantwoordelijke zal voor het sluiten van de Overeenkomst of zo spoedig
      mogelijk na het sluiten van de Overeenkomst een intern contactpersoon
      aanwijzen waar Verwerker in het kader van privacy gerelateerde zaken en
      deze Verwerkersovereenkomst contact mee op kan nemen. De contactgegevens
      van deze contactpersoon (de naam, functie, het e-mailadres en het
      telefoonnummer) zullen zo spoedig mogelijk schriftelijk aan Verwerker
      verstrekt worden.
    </p>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

h1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 18pt;
}

.s1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 18pt;
}

.p,
p {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

h2 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

a {
  color: #0462c1;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

.s3 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

.s4 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s5 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s6 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
  counter-reset: c2 1;
}

#l2 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l2 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}

li {
  display: block;
}

#l3 {
  padding-left: 0pt;
  counter-reset: d1 2;
}

#l3 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l3 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}

#l4 {
  padding-left: 0pt;
  counter-reset: d2 1;
}

#l4 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d1, decimal) "." counter(d2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l4 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}

#l5 {
  padding-left: 0pt;
  counter-reset: d3 1;
}

#l5 > li > *:first-child:before {
  counter-increment: d3;
  content: counter(d3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l5 > li:first-child > *:first-child:before {
  counter-increment: d3 0;
}

li {
  display: block;
}

#l6 {
  padding-left: 0pt;
  counter-reset: e1 3;
}

#l6 > li > *:first-child:before {
  counter-increment: e1;
  content: counter(e1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l6 > li:first-child > *:first-child:before {
  counter-increment: e1 0;
}

#l7 {
  padding-left: 0pt;
  counter-reset: e2 1;
}

#l7 > li > *:first-child:before {
  counter-increment: e2;
  content: counter(e1, decimal) "." counter(e2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l7 > li:first-child > *:first-child:before {
  counter-increment: e2 0;
}

#l8 {
  padding-left: 0pt;
  counter-reset: e3 1;
}

#l8 > li > *:first-child:before {
  counter-increment: e3;
  content: counter(e3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l8 > li:first-child > *:first-child:before {
  counter-increment: e3 0;
}

li {
  display: block;
}

#l9 {
  padding-left: 0pt;
  counter-reset: f1 4;
}

#l9 > li > *:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l9 > li:first-child > *:first-child:before {
  counter-increment: f1 0;
}

#l10 {
  padding-left: 0pt;
  counter-reset: f2 1;
}

#l10 > li > *:first-child:before {
  counter-increment: f2;
  content: counter(f1, decimal) "." counter(f2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l10 > li:first-child > *:first-child:before {
  counter-increment: f2 0;
}

li {
  display: block;
}

#l11 {
  padding-left: 0pt;
  counter-reset: g1 5;
}

#l11 > li > *:first-child:before {
  counter-increment: g1;
  content: counter(g1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l11 > li:first-child > *:first-child:before {
  counter-increment: g1 0;
}

#l12 {
  padding-left: 0pt;
  counter-reset: g2 1;
}

#l12 > li > *:first-child:before {
  counter-increment: g2;
  content: counter(g1, decimal) "." counter(g2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l12 > li:first-child > *:first-child:before {
  counter-increment: g2 0;
}

li {
  display: block;
}

#l13 {
  padding-left: 0pt;
  counter-reset: h1 6;
}

#l13 > li > *:first-child:before {
  counter-increment: h1;
  content: counter(h1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l13 > li:first-child > *:first-child:before {
  counter-increment: h1 0;
}

#l14 {
  padding-left: 0pt;
  counter-reset: h2 1;
}

#l14 > li > *:first-child:before {
  counter-increment: h2;
  content: counter(h1, decimal) "." counter(h2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l14 > li:first-child > *:first-child:before {
  counter-increment: h2 0;
}

li {
  display: block;
}

#l15 {
  padding-left: 0pt;
  counter-reset: i1 7;
}

#l15 > li > *:first-child:before {
  counter-increment: i1;
  content: counter(i1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l15 > li:first-child > *:first-child:before {
  counter-increment: i1 0;
}

#l16 {
  padding-left: 0pt;
  counter-reset: i2 1;
}

#l16 > li > *:first-child:before {
  counter-increment: i2;
  content: counter(i1, decimal) "." counter(i2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l16 > li:first-child > *:first-child:before {
  counter-increment: i2 0;
}

li {
  display: block;
}

#l17 {
  padding-left: 0pt;
  counter-reset: j1 8;
}

#l17 > li > *:first-child:before {
  counter-increment: j1;
  content: counter(j1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l17 > li:first-child > *:first-child:before {
  counter-increment: j1 0;
}

#l18 {
  padding-left: 0pt;
  counter-reset: j2 1;
}

#l18 > li > *:first-child:before {
  counter-increment: j2;
  content: counter(j1, decimal) "." counter(j2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l18 > li:first-child > *:first-child:before {
  counter-increment: j2 0;
}

li {
  display: block;
}

#l19 {
  padding-left: 0pt;
  counter-reset: k1 9;
}

#l19 > li > *:first-child:before {
  counter-increment: k1;
  content: counter(k1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l19 > li:first-child > *:first-child:before {
  counter-increment: k1 0;
}

#l20 {
  padding-left: 0pt;
  counter-reset: k2 1;
}

#l20 > li > *:first-child:before {
  counter-increment: k2;
  content: counter(k1, decimal) "." counter(k2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l20 > li:first-child > *:first-child:before {
  counter-increment: k2 0;
}

#l21 {
  padding-left: 0pt;
  counter-reset: k3 1;
}

#l21 > li > *:first-child:before {
  counter-increment: k3;
  content: counter(k3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l21 > li:first-child > *:first-child:before {
  counter-increment: k3 0;
}

li {
  display: block;
}

#l22 {
  padding-left: 0pt;
  counter-reset: l1 10;
}

#l22 > li > *:first-child:before {
  counter-increment: l1;
  content: counter(l1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l22 > li:first-child > *:first-child:before {
  counter-increment: l1 0;
}

#l23 {
  padding-left: 0pt;
  counter-reset: l2 1;
}

#l23 > li > *:first-child:before {
  counter-increment: l2;
  content: counter(l1, decimal) "." counter(l2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l23 > li:first-child > *:first-child:before {
  counter-increment: l2 0;
}

li {
  display: block;
}

#l24 {
  padding-left: 0pt;
  counter-reset: m1 11;
}

#l24 > li > *:first-child:before {
  counter-increment: m1;
  content: counter(m1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l24 > li:first-child > *:first-child:before {
  counter-increment: m1 0;
}

#l25 {
  padding-left: 0pt;
  counter-reset: m2 1;
}

#l25 > li > *:first-child:before {
  counter-increment: m2;
  content: counter(m1, decimal) "." counter(m2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l25 > li:first-child > *:first-child:before {
  counter-increment: m2 0;
}

li {
  display: block;
}

#l26 {
  padding-left: 0pt;
  counter-reset: n1 12;
}

#l26 > li > *:first-child:before {
  counter-increment: n1;
  content: counter(n1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l26 > li:first-child > *:first-child:before {
  counter-increment: n1 0;
}

#l27 {
  padding-left: 0pt;
  counter-reset: n2 1;
}

#l27 > li > *:first-child:before {
  counter-increment: n2;
  content: counter(n1, decimal) "." counter(n2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l27 > li:first-child > *:first-child:before {
  counter-increment: n2 0;
}

li {
  display: block;
}

#l28 {
  padding-left: 0pt;
  counter-reset: o1 13;
}

#l28 > li > *:first-child:before {
  counter-increment: o1;
  content: counter(o1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l28 > li:first-child > *:first-child:before {
  counter-increment: o1 0;
}

#l29 {
  padding-left: 0pt;
  counter-reset: o2 1;
}

#l29 > li > *:first-child:before {
  counter-increment: o2;
  content: counter(o1, decimal) "." counter(o2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l29 > li:first-child > *:first-child:before {
  counter-increment: o2 0;
}

li {
  display: block;
}

#l30 {
  padding-left: 0pt;
  counter-reset: p1 14;
}

#l30 > li > *:first-child:before {
  counter-increment: p1;
  content: counter(p1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l30 > li:first-child > *:first-child:before {
  counter-increment: p1 0;
}

#l31 {
  padding-left: 0pt;
  counter-reset: p2 1;
}

#l31 > li > *:first-child:before {
  counter-increment: p2;
  content: counter(p1, decimal) "." counter(p2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l31 > li:first-child > *:first-child:before {
  counter-increment: p2 0;
}

li {
  display: block;
}

#l32 {
  padding-left: 0pt;
  counter-reset: q1 15;
}

#l32 > li > *:first-child:before {
  counter-increment: q1;
  content: counter(q1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l32 > li:first-child > *:first-child:before {
  counter-increment: q1 0;
}

#l33 {
  padding-left: 0pt;
  counter-reset: q2 1;
}

#l33 > li > *:first-child:before {
  counter-increment: q2;
  content: counter(q1, decimal) "." counter(q2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l33 > li:first-child > *:first-child:before {
  counter-increment: q2 0;
}

#l34 {
  padding-left: 0pt;
  counter-reset: q3 1;
}

#l34 > li > *:first-child:before {
  counter-increment: q3;
  content: counter(q3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l34 > li:first-child > *:first-child:before {
  counter-increment: q3 0;
}

li {
  display: block;
}

#l35 {
  padding-left: 0pt;
  counter-reset: r1 16;
}

#l35 > li > *:first-child:before {
  counter-increment: r1;
  content: counter(r1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l35 > li:first-child > *:first-child:before {
  counter-increment: r1 0;
}

#l36 {
  padding-left: 0pt;
  counter-reset: r2 1;
}

#l36 > li > *:first-child:before {
  counter-increment: r2;
  content: counter(r1, decimal) "." counter(r2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l36 > li:first-child > *:first-child:before {
  counter-increment: r2 0;
}

li {
  display: block;
}

#l37 {
  padding-left: 0pt;
  counter-reset: s1 17;
}

#l37 > li > *:first-child:before {
  counter-increment: s1;
  content: counter(s1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l37 > li:first-child > *:first-child:before {
  counter-increment: s1 0;
}

#l38 {
  padding-left: 0pt;
  counter-reset: s2 1;
}

#l38 > li > *:first-child:before {
  counter-increment: s2;
  content: counter(s1, decimal) "." counter(s2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l38 > li:first-child > *:first-child:before {
  counter-increment: s2 0;
}

li {
  display: block;
}

#l39 {
  padding-left: 0pt;
  counter-reset: t1 18;
}

#l39 > li > *:first-child:before {
  counter-increment: t1;
  content: counter(t1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l39 > li:first-child > *:first-child:before {
  counter-increment: t1 0;
}

#l40 {
  padding-left: 0pt;
  counter-reset: t2 1;
}

#l40 > li > *:first-child:before {
  counter-increment: t2;
  content: counter(t1, decimal) "." counter(t2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l40 > li:first-child > *:first-child:before {
  counter-increment: t2 0;
}

li {
  display: block;
}

#l41 {
  padding-left: 0pt;
  counter-reset: u1 19;
}

#l41 > li > *:first-child:before {
  counter-increment: u1;
  content: counter(u1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l41 > li:first-child > *:first-child:before {
  counter-increment: u1 0;
}

#l42 {
  padding-left: 0pt;
  counter-reset: u2 1;
}

#l42 > li > *:first-child:before {
  counter-increment: u2;
  content: counter(u1, decimal) "." counter(u2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l42 > li:first-child > *:first-child:before {
  counter-increment: u2 0;
}

li {
  display: block;
}

#l43 {
  padding-left: 0pt;
  counter-reset: v1 20;
}

#l43 > li > *:first-child:before {
  counter-increment: v1;
  content: counter(v1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l43 > li:first-child > *:first-child:before {
  counter-increment: v1 0;
}

#l44 {
  padding-left: 0pt;
  counter-reset: v2 1;
}

#l44 > li > *:first-child:before {
  counter-increment: v2;
  content: counter(v1, decimal) "." counter(v2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l44 > li:first-child > *:first-child:before {
  counter-increment: v2 0;
}

li {
  display: block;
}

#l45 {
  padding-left: 0pt;
  counter-reset: w1 2;
}

#l45 > li > *:first-child:before {
  counter-increment: w1;
  content: counter(w1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l45 > li:first-child > *:first-child:before {
  counter-increment: w1 0;
}

#l46 {
  padding-left: 0pt;
  counter-reset: w2 1;
}

#l46 > li > *:first-child:before {
  counter-increment: w2;
  content: counter(w1, decimal) "." counter(w2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l46 > li:first-child > *:first-child:before {
  counter-increment: w2 0;
}

li {
  display: block;
}

#l47 {
  padding-left: 0pt;
  counter-reset: x1 3;
}

#l47 > li > *:first-child:before {
  counter-increment: x1;
  content: counter(x1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l47 > li:first-child > *:first-child:before {
  counter-increment: x1 0;
}

#l48 {
  padding-left: 0pt;
  counter-reset: x2 1;
}

#l48 > li > *:first-child:before {
  counter-increment: x2;
  content: counter(x1, decimal) "." counter(x2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l48 > li:first-child > *:first-child:before {
  counter-increment: x2 0;
}

li {
  display: block;
}

#l49 {
  padding-left: 0pt;
  counter-reset: y1 4;
}

#l49 > li > *:first-child:before {
  counter-increment: y1;
  content: counter(y1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l49 > li:first-child > *:first-child:before {
  counter-increment: y1 0;
}

#l50 {
  padding-left: 0pt;
  counter-reset: y2 1;
}

#l50 > li > *:first-child:before {
  counter-increment: y2;
  content: counter(y1, decimal) "." counter(y2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l50 > li:first-child > *:first-child:before {
  counter-increment: y2 0;
}

li {
  display: block;
}

#l51 {
  padding-left: 0pt;
  counter-reset: z1 5;
}

#l51 > li > *:first-child:before {
  counter-increment: z1;
  content: counter(z1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l51 > li:first-child > *:first-child:before {
  counter-increment: z1 0;
}

#l52 {
  padding-left: 0pt;
  counter-reset: z2 1;
}

#l52 > li > *:first-child:before {
  counter-increment: z2;
  content: counter(z1, decimal) "." counter(z2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l52 > li:first-child > *:first-child:before {
  counter-increment: z2 0;
}

li {
  display: block;
}

#l53 {
  padding-left: 0pt;
  counter-reset: c1 6;
}

#l53 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l53 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l54 {
  padding-left: 0pt;
  counter-reset: c2 1;
}

#l54 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l54 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}

li {
  display: block;
}

#l55 {
  padding-left: 0pt;
  counter-reset: d1 7;
}

#l55 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l55 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}

#l56 {
  padding-left: 0pt;
  counter-reset: d2 1;
}

#l56 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d1, decimal) "." counter(d2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l56 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}

li {
  display: block;
}

#l57 {
  padding-left: 0pt;
  counter-reset: e1 8;
}

#l57 > li > *:first-child:before {
  counter-increment: e1;
  content: counter(e1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l57 > li:first-child > *:first-child:before {
  counter-increment: e1 0;
}

#l58 {
  padding-left: 0pt;
  counter-reset: e2 1;
}

#l58 > li > *:first-child:before {
  counter-increment: e2;
  content: counter(e1, decimal) "." counter(e2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l58 > li:first-child > *:first-child:before {
  counter-increment: e2 0;
}

li {
  display: block;
}

#l59 {
  padding-left: 0pt;
  counter-reset: f1 2;
}

#l59 > li > *:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l59 > li:first-child > *:first-child:before {
  counter-increment: f1 0;
}

#l60 {
  padding-left: 0pt;
  counter-reset: f2 1;
}

#l60 > li > *:first-child:before {
  counter-increment: f2;
  content: counter(f1, decimal) "." counter(f2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l60 > li:first-child > *:first-child:before {
  counter-increment: f2 0;
}

#l61 {
  padding-left: 0pt;
  counter-reset: f3 1;
}

#l61 > li > *:first-child:before {
  counter-increment: f3;
  content: "(" counter(f3, decimal) ") ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l61 > li:first-child > *:first-child:before {
  counter-increment: f3 0;
}

li {
  display: block;
}

#l62 {
  padding-left: 0pt;
  counter-reset: g1 3;
}

#l62 > li > *:first-child:before {
  counter-increment: g1;
  content: counter(g1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l62 > li:first-child > *:first-child:before {
  counter-increment: g1 0;
}

#l63 {
  padding-left: 0pt;
  counter-reset: g2 1;
}

#l63 > li > *:first-child:before {
  counter-increment: g2;
  content: counter(g1, decimal) "." counter(g2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l63 > li:first-child > *:first-child:before {
  counter-increment: g2 0;
}

li {
  display: block;
}

#l64 {
  padding-left: 0pt;
  counter-reset: h1 1;
}

#l64 > li > *:first-child:before {
  counter-increment: h1;
  content: counter(h1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l64 > li:first-child > *:first-child:before {
  counter-increment: h1 0;
}

#l65 {
  padding-left: 0pt;
  counter-reset: h2 1;
}

#l65 > li > *:first-child:before {
  counter-increment: h2;
  content: counter(h1, decimal) "." counter(h2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l65 > li:first-child > *:first-child:before {
  counter-increment: h2 0;
}

li {
  display: block;
}

#l66 {
  padding-left: 0pt;
  counter-reset: i1 2;
}

#l66 > li > *:first-child:before {
  counter-increment: i1;
  content: counter(i1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l66 > li:first-child > *:first-child:before {
  counter-increment: i1 0;
}

#l67 {
  padding-left: 0pt;
  counter-reset: i2 1;
}

#l67 > li > *:first-child:before {
  counter-increment: i2;
  content: counter(i1, decimal) "." counter(i2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l67 > li:first-child > *:first-child:before {
  counter-increment: i2 0;
}

li {
  display: block;
}

#l68 {
  padding-left: 0pt;
  counter-reset: j1 3;
}

#l68 > li > *:first-child:before {
  counter-increment: j1;
  content: counter(j1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l68 > li:first-child > *:first-child:before {
  counter-increment: j1 0;
}

#l69 {
  padding-left: 0pt;
  counter-reset: j2 1;
}

#l69 > li > *:first-child:before {
  counter-increment: j2;
  content: counter(j1, decimal) "." counter(j2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l69 > li:first-child > *:first-child:before {
  counter-increment: j2 0;
}

li {
  display: block;
}

#l70 {
  padding-left: 0pt;
  counter-reset: k1 4;
}

#l70 > li > *:first-child:before {
  counter-increment: k1;
  content: counter(k1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l70 > li:first-child > *:first-child:before {
  counter-increment: k1 0;
}

#l71 {
  padding-left: 0pt;
  counter-reset: k2 1;
}

#l71 > li > *:first-child:before {
  counter-increment: k2;
  content: counter(k1, decimal) "." counter(k2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l71 > li:first-child > *:first-child:before {
  counter-increment: k2 0;
}

li {
  display: block;
}

#l72 {
  padding-left: 0pt;
  counter-reset: l1 5;
}

#l72 > li > *:first-child:before {
  counter-increment: l1;
  content: counter(l1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l72 > li:first-child > *:first-child:before {
  counter-increment: l1 0;
}

#l73 {
  padding-left: 0pt;
  counter-reset: l2 1;
}

#l73 > li > *:first-child:before {
  counter-increment: l2;
  content: counter(l1, decimal) "." counter(l2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l73 > li:first-child > *:first-child:before {
  counter-increment: l2 0;
}

li {
  display: block;
}

#l74 {
  padding-left: 0pt;
  counter-reset: m1 6;
}

#l74 > li > *:first-child:before {
  counter-increment: m1;
  content: counter(m1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l74 > li:first-child > *:first-child:before {
  counter-increment: m1 0;
}

#l75 {
  padding-left: 0pt;
  counter-reset: m2 1;
}

#l75 > li > *:first-child:before {
  counter-increment: m2;
  content: counter(m1, decimal) "." counter(m2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l75 > li:first-child > *:first-child:before {
  counter-increment: m2 0;
}

li {
  display: block;
}

#l76 {
  padding-left: 0pt;
  counter-reset: n1 7;
}

#l76 > li > *:first-child:before {
  counter-increment: n1;
  content: counter(n1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l76 > li:first-child > *:first-child:before {
  counter-increment: n1 0;
}

#l77 {
  padding-left: 0pt;
  counter-reset: n2 1;
}

#l77 > li > *:first-child:before {
  counter-increment: n2;
  content: counter(n1, decimal) "." counter(n2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l77 > li:first-child > *:first-child:before {
  counter-increment: n2 0;
}

li {
  display: block;
}

#l78 {
  padding-left: 0pt;
  counter-reset: o1 8;
}

#l78 > li > *:first-child:before {
  counter-increment: o1;
  content: counter(o1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l78 > li:first-child > *:first-child:before {
  counter-increment: o1 0;
}

#l79 {
  padding-left: 0pt;
  counter-reset: o2 1;
}

#l79 > li > *:first-child:before {
  counter-increment: o2;
  content: counter(o1, decimal) "." counter(o2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l79 > li:first-child > *:first-child:before {
  counter-increment: o2 0;
}

li {
  display: block;
}

#l80 {
  padding-left: 0pt;
  counter-reset: p1 9;
}

#l80 > li > *:first-child:before {
  counter-increment: p1;
  content: counter(p1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l80 > li:first-child > *:first-child:before {
  counter-increment: p1 0;
}

#l81 {
  padding-left: 0pt;
  counter-reset: p2 1;
}

#l81 > li > *:first-child:before {
  counter-increment: p2;
  content: counter(p1, decimal) "." counter(p2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l81 > li:first-child > *:first-child:before {
  counter-increment: p2 0;
}

li {
  display: block;
}

#l82 {
  padding-left: 0pt;
  counter-reset: q1 10;
}

#l82 > li > *:first-child:before {
  counter-increment: q1;
  content: counter(q1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l82 > li:first-child > *:first-child:before {
  counter-increment: q1 0;
}

#l83 {
  padding-left: 0pt;
  counter-reset: q2 1;
}

#l83 > li > *:first-child:before {
  counter-increment: q2;
  content: counter(q1, decimal) "." counter(q2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l83 > li:first-child > *:first-child:before {
  counter-increment: q2 0;
}

li {
  display: block;
}

#l84 {
  padding-left: 0pt;
  counter-reset: r1 11;
}

#l84 > li > *:first-child:before {
  counter-increment: r1;
  content: counter(r1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l84 > li:first-child > *:first-child:before {
  counter-increment: r1 0;
}

#l85 {
  padding-left: 0pt;
  counter-reset: r2 1;
}

#l85 > li > *:first-child:before {
  counter-increment: r2;
  content: counter(r1, decimal) "." counter(r2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l85 > li:first-child > *:first-child:before {
  counter-increment: r2 0;
}

li {
  display: block;
}

#l86 {
  padding-left: 0pt;
  counter-reset: s1 12;
}

#l86 > li > *:first-child:before {
  counter-increment: s1;
  content: counter(s1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l86 > li:first-child > *:first-child:before {
  counter-increment: s1 0;
}

#l87 {
  padding-left: 0pt;
  counter-reset: s2 1;
}

#l87 > li > *:first-child:before {
  counter-increment: s2;
  content: counter(s1, decimal) "." counter(s2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

#l87 > li:first-child > *:first-child:before {
  counter-increment: s2 0;
}

#l88 {
  padding-left: 0pt;
  counter-reset: s3 1;
}

#l88 > li > *:first-child:before {
  counter-increment: s3;
  content: counter(s3, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l88 > li:first-child > *:first-child:before {
  counter-increment: s3 0;
}

li {
  display: block;
}

#l89 {
  padding-left: 0pt;
  counter-reset: t1 13;
}

#l89 > li > *:first-child:before {
  counter-increment: t1;
  content: counter(t1, decimal) " ";
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

#l89 > li:first-child > *:first-child:before {
  counter-increment: t1 0;
}

#l90 {
  padding-left: 0pt;
  counter-reset: t2 1;
}

#l90 > li > *:first-child:before {
  counter-increment: t2;
  content: counter(t1, decimal) "." counter(t2, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l90 > li:first-child > *:first-child:before {
  counter-increment: t2 0;
}

li {
  display: block;
}

#l91 {
  padding-left: 0pt;
}

#l91 > li > *:first-child:before {
  content: " ";
  color: black;
  font-family: Symbol, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

#l92 {
  padding-left: 0pt;
}

#l92 > li > *:first-child:before {
  content: "- ";
  color: black;
  font-family: "Calibri Light", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

li {
  display: block;
}

#l93 {
  padding-left: 0pt;
}

#l93 > li > *:first-child:before {
  content: "- ";
  color: black;
  font-family: Cambria, serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

table,
tbody {
  width: 100%;
  vertical-align: top;
  overflow: visible;
}
</style>
