import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 gap-1 sm:grid-cols-3 sm:items-center sm:gap-x-4" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-red-500 sm:col-start-2",
  "data-testid": "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateTime = _resolveComponent("DateTime")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.formFieldId,
      class: "block text-sm font-medium text-gray-700"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createVNode(_component_DateTime, {
      id: _ctx.formFieldId,
      name: _ctx.formFieldId,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      valid: !_ctx.errorMessage,
      disabled: _ctx.disabled,
      class: _normalizeClass([{ 'col-span-2': _ctx.fullWidth }]),
      min: _ctx.min,
      max: _ctx.max
    }, null, 8, ["id", "name", "modelValue", "valid", "disabled", "class", "min", "max"]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}