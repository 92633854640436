import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex rounded-md shadow-sm" }
const _hoisted_2 = { class: "relative flex flex-grow items-stretch focus-within:z-10" }
const _hoisted_3 = {
  key: 0,
  class: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
}
const _hoisted_4 = ["id", "name", "type", "placeholder", "disabled", "readonly", "min", "max"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
              "aria-hidden": "true",
              class: "h-5 w-5 text-gray-400"
            }))
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.id,
        name: _ctx.name,
        type: _ctx.type,
        class: _normalizeClass(_ctx.inputClass),
        placeholder: _ctx.disabled ? '' : _ctx.placeholder,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        min: _ctx.min,
        max: _ctx.max,
        onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValueInternal) = $event))
      }, null, 42, _hoisted_4), [
        [_vModelDynamic, _ctx.modelValueInternal]
      ])
    ]),
    (_ctx.button)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-darkgreen-500 focus:outline-none focus:ring-1 focus:ring-darkgreen-500",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onButtonClick && _ctx.onButtonClick(...args)))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.button.icon), {
            "aria-hidden": "true",
            class: "h-5 w-5 text-gray-400"
          })),
          (_ctx.button.text)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.button.text), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}