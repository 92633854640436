
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { computed, defineComponent } from "vue";

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    subTitle: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const visibleInternal = computed({
      get: () => props.visible,
      set: (value) => emit("update:visible", value),
    });

    return {
      visibleInternal,
    };
  },
});
