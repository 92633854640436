import { Component } from "vue";

interface TextInputButtonProps {
  text?: string;
  icon?: Component;
}
interface TextInputProps {
  id?: string;
  name?: string;
  modelValue?: string;
  type?: TextInputType;
  placeholder?: string;
  valid?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  icon?: Component;
  button?: TextInputButtonProps;
  min?: number | string;
  max?: number | string;
}

enum TextInputType {
  TEXT = "text",
  EMAIL = "email",
  SEARCH = "search",
  NUMBER = "number",
  DATE = "date",
  DATE_TIME = "datetime-local",
  FILE = "file",
}

export { TextInputButtonProps, TextInputProps, TextInputType };
